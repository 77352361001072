/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useFormik } from "formik";
import * as Yup from "yup";

import Avatar from "@/components/core/avatar";
import { useEffect, useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { clearAuth, setMe, useCurrentUser } from "@/apollo/cache/auth";
import { useMutation, useQuery } from "@apollo/client";
import {
  MeQuery,
  MeQueryVariables,
  ResetPasswordAuthenticatedMutation,
  ResetPasswordAuthenticatedMutationVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from "@/apollo/graphql/generated/types";
import { UPDATE_USER } from "@/apollo/graphql/mutations/user";
import toast from "react-hot-toast";
import ButtonLoader from "@/components/loaders/button";
import { GET_USER } from "@/apollo/graphql/queries/user";
import IndustryList from "./select";
import { useNavigate } from "react-location";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import { CHANGE_PASSWORD } from "@/apollo/graphql/mutations/auth";

export default function Account() {
  const user = useCurrentUser();
  const [showEdit, setShowEdit] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const [hideOldPassword, setHideOldPassword] = useState(true);
  const [hideNewPassword, setHideNewPassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);

  const navigate = useNavigate();

  // console.log(user);

  const [updateUser, { loading }] = useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UPDATE_USER);

  const {
    data,
    loading: loadingMe,
    refetch,
  } = useQuery<MeQuery, MeQueryVariables>(GET_USER);

  useEffect(() => {
    if (data?.me) {
      setMe(data?.me);
    }
  }, [user, data, refetch]);

  const [selectedIndustry, setSelectedIndustry] = useState<string>("");

  const handleIndustrySelect = (industry: string) => {
    setSelectedIndustry(industry);
    form.setFieldValue("category", industry);
  };

  const { handleSubmit, ...form }: any = useFormik({
    initialValues: {
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      phoneNumber: user?.phone ?? "",
      category: user?.category ?? "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      phoneNumber: Yup.string().required("Phone Number is required"),
    }),
    onSubmit: async (values) => {
      await updateUser({
        variables: {
          input: {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phoneNumber,
            category: values.category,
          },
        },
      }).then(({ data }) => {
        if (data?.updateUser) {
          refetch();
          toast(
            JSON.stringify({
              type: "success",
              title: `Profile Updated`,
            })
          );
          setShowEdit(false);
          navigate({
            replace: true,
            to: `/main-introduction/${
              values.category === "PRE-SCHOOL" ? "pre-school" : "grade-school"
            }`,
          });
        }
      });
    },
  });

  interface FormValues {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
  }

  const [changePassword, { loading: changingPassword }] = useMutation<
    ResetPasswordAuthenticatedMutation,
    ResetPasswordAuthenticatedMutationVariables
  >(CHANGE_PASSWORD);

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Required"),
      newPassword: Yup.string()
        .min(8, "Must be 8 characters or more")
        .required("Required"),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), undefined], "Passwords must match")
        .required("Required"),
    }),
    onSubmit: async (values: FormValues) => {
      await changePassword({
        variables: {
          input: {
           
            confirmPassword: values.confirmNewPassword,
            oldPassword: values.oldPassword,
            password: values.newPassword,
          },
        },
      }).then(({ data }) => {
        if (data?.resetPasswordAuthenticated) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Password updated successfully",
            })
          );
          clearAuth();
          formik.resetForm();
          navigate({
            to: "/login",
          });
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Password update failed",
            })
          );
        }
      });
    },
  });

  useEffect(() => {
    if (data?.me) {
      setSelectedIndustry(data?.me?.category ?? "");
    }
  }, [data]);

  return (
    <section className="font-poppins pt-5">
      {showEdit ? (
        <>
          <div className="w-full ">
            <div
              onClick={() => setShowEdit(false)}
              className="flex items-center gap-1 absolute top-16 left-6 cursor-pointer"
            >
              <IoIosArrowRoundBack className="w-6 h-6 " aria-hidden="true" />
              <span>Back</span>
            </div>
            <div className="w-full max-w-96 mx-auto">
              <Avatar
                src={""}
                alt={`${user?.firstName} ${user?.lastName}`}
                size="lg"
              />

              <form onSubmit={handleSubmit}>
                <div className="mt-4">
                  <label htmlFor="firstName" className="text-sm text-[#666666]">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    {...form.getFieldProps("firstName")}
                    className="w-full border border-[#66666659] rounded-lg p-3 mt-1 text-sm text-[#111111] font-poppins"
                  />
                  {form.touched.firstName && form.errors.firstName ? (
                    <p className="text-red-500 text-xs mt-1">
                      {form.errors.firstName}
                    </p>
                  ) : null}
                </div>
                <div className="mt-4">
                  <label htmlFor="lastName" className="text-sm text-[#666666]">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    {...form.getFieldProps("lastName")}
                    className="w-full border border-[#66666659] rounded-lg p-3 mt-1 text-sm text-[#111111 font-poppins"
                  />
                  {form.touched.lastName && form.errors.lastName ? (
                    <p className="text-red-500 text-xs mt-1">
                      {form.errors.lastName}
                    </p>
                  ) : null}
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="phoneNumber"
                    className="text-sm text-[#666666]"
                  >
                    Phone Number
                  </label>

                  <PhoneInput
                    country={"us"}
                    placeholder={"Mobile Number"}
                    value={form.values.phoneNumber}
                    onChange={(phone) =>
                      form.setFieldValue("phoneNumber", phone)
                    }
                    countryCodeEditable={false}
                    enableSearch={true}
                    inputProps={{
                      required: true,
                      title: "Enter your phone number here",
                      className: "input-base text-sm border-[#66666659]",
                    }}
                    inputStyle={{
                      width: "82%",
                      marginLeft: "18%",
                      borderTopRightRadius: "0.5rem",
                      borderBottomRightRadius: "0.5rem",
                    }}
                    buttonStyle={{
                      // width: "18%",
                      borderRight: "none",
                      // borderRadius: "0.5rem",
                      borderTopLeftRadius: "0.5rem",
                      borderBottomLeftRadius: "0.5rem",
                      borderColor: "#66666659",
                      paddingInline: "1rem",
                    }}
                    dropdownStyle={{
                      borderRadius: "0.5rem",
                      borderColor: "rgb(156 163 175)",
                      paddingInline: "1rem",
                    }}
                    searchStyle={{
                      width: "90%",
                      borderRadius: "0.5rem",
                      borderColor: "rgb(156 163 175)",
                      paddingInline: "1rem",
                    }}
                  />
                  {form.touched.phoneNumber && form.errors.phoneNumber ? (
                    <p className="text-red-500 text-xs mt-1">
                      {form.errors.phoneNumber}
                    </p>
                  ) : null}
                </div>

                <div className="mt-4">
                  <label htmlFor="lastName" className="text-sm text-[#666666]">
                    Category
                  </label>
                  <div className="h-12 px-2 flex items-center w-full max-w-96 border border-gray-300 rounded-lg mt-1">
                    <IndustryList
                      data={["PRE-SCHOOL", "GRADE-SCHOOL"]}
                      selectedIndustry={selectedIndustry}
                      onIndustrySelect={handleIndustrySelect}
                    />
                  </div>
                </div>
                <button
                  disabled={loading}
                  type="submit"
                  className="absolute bottom-5 right-5 w-44 flex justify-center items-center py-2 px-5 bg-[#1567EE] rounded-lg text-white hover:shadow-md transition-all duration-200 ease-in-out"
                >
                  {loading || loadingMe ? (
                    <ButtonLoader title="" />
                  ) : (
                    "Save Changes"
                  )}
                </button>
              </form>
            </div>
          </div>
        </>
      ) : showChangePassword ? (
        <>
          <div className="font-poppins w-full">
            <div
              onClick={() => setShowChangePassword(false)}
              className="flex items-center gap-1 absolute top-16 left-6 cursor-pointer"
            >
              <IoIosArrowRoundBack className="w-6 h-6 " aria-hidden="true" />
              <span>Back</span>
            </div>

            <div className="w-full flex flex-col items-center pt-10">
              <h1 className="font-medium text-3xl text-[#333333] ">
                Set new password
              </h1>
              <p className="text-lg text-[#808099] text-center mt-2">
                Kindly enter your old password and <br /> provide a new password
              </p>

              <div className="w-full max-w-xl mt-4">
                <div className="flex justify-between items-center border border-[#66666659] rounded-lg p-3 mt-1 focus:outline-none focus:border-[#666666]">
                  <input
                    type={hideOldPassword ? "password" : "text"}
                    placeholder="Enter your old password"
                    id="password"
                    name="password"
                    onChange={(e) =>
                      formik.setFieldValue("oldPassword", e.target.value)
                    }
                    value={formik.values.oldPassword}
                    className="w-full outline-none focus:outline-none border-none focus:border-none"
                  />
                  <div className="flex gap-2">
                    {!hideOldPassword ? (
                      <button
                        type="button"
                        className="flex gap-1"
                        onClick={() => setHideOldPassword(true)}
                      >
                        <BiSolidHide
                          className="h-6 w-6 text-[#666666CC]"
                          aria-hidden="true"
                        />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="flex gap-1"
                        onClick={() => setHideOldPassword(false)}
                      >
                        <BiSolidShow
                          className="h-6 w-6 text-[#666666CC]"
                          aria-hidden="true"
                        />
                      </button>
                    )}
                  </div>
                </div>
                {formik.touched.oldPassword && formik.errors.oldPassword && (
                  <p className="text-xs mt-2 text-red-500">
                    {formik.errors.oldPassword}
                  </p>
                )}
              </div>

              <div className="w-full max-w-xl mt-4">
                <div className="flex justify-between items-center border border-[#66666659] rounded-lg p-3 mt-1 focus:outline-none focus:border-[#666666]">
                  <input
                    type={hideNewPassword ? "password" : "text"}
                    placeholder="Enter your new password"
                    id="newPassword"
                    name="newPassword"
                    onChange={(e) =>
                      formik.setFieldValue("newPassword", e.target.value)
                    }
                    value={formik.values.newPassword}
                    className="w-full outline-none focus:outline-none border-none focus:border-none"
                  />
                  <div className="flex gap-2">
                    {!hideNewPassword ? (
                      <button
                        type="button"
                        className="flex gap-1"
                        onClick={() => setHideNewPassword(true)}
                      >
                        <BiSolidHide
                          className="h-6 w-6 text-[#666666CC]"
                          aria-hidden="true"
                        />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="flex gap-1"
                        onClick={() => setHideNewPassword(false)}
                      >
                        <BiSolidShow
                          className="h-6 w-6 text-[#666666CC]"
                          aria-hidden="true"
                        />
                      </button>
                    )}
                  </div>
                </div>
                {formik.touched.newPassword && formik.errors.newPassword && (
                  <p className="text-xs mt-2 text-red-500">
                    {formik.errors.newPassword}
                  </p>
                )}
              </div>
              <div className="w-full max-w-xl my-4">
                <div className="flex justify-between items-center border border-[#66666659] rounded-lg p-3 mt-1 focus:outline-none focus:border-[#666666]">
                  <input
                    type={hideConfirmPassword ? "password" : "text"}
                    placeholder="Confirm your new password"
                    id="confirmPassword"
                    name="confirmPassword"
                    onChange={(e) =>
                      formik.setFieldValue("confirmNewPassword", e.target.value)
                    }
                    value={formik.values.confirmNewPassword}
                    className="w-full outline-none focus:outline-none border-none focus:border-none"
                  />
                  <div className="flex gap-2">
                    {!hideConfirmPassword ? (
                      <button
                        type="button"
                        className="flex gap-1"
                        onClick={() => setHideConfirmPassword(true)}
                      >
                        <BiSolidHide
                          className="h-6 w-6 text-[#666666CC]"
                          aria-hidden="true"
                        />
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="flex gap-1"
                        onClick={() => setHideConfirmPassword(false)}
                      >
                        <BiSolidShow
                          className="h-6 w-6 text-[#666666CC]"
                          aria-hidden="true"
                        />
                      </button>
                    )}
                  </div>
                </div>
                {formik.touched.confirmNewPassword &&
                  formik.errors.confirmNewPassword && (
                    <p className="text-xs mt-2 text-red-500">
                      {formik.errors.confirmNewPassword}
                    </p>
                  )}
              </div>

              <button
                onClick={() => formik.handleSubmit()}
                className="w-full max-w-xl h-12 bg-[#1567EE] rounded-xl flex justify-center items-center font-medium text-lg text-white"
              >
                {changingPassword ? (
                  <ButtonLoader title="" />
                ) : (
                  "Change Password"
                )}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-full flex gap-6">
            <Avatar
              src={""}
              alt={`${user?.firstName} ${user?.lastName}`}
              size="lg"
            />
            <div className="">
              <div className="">
                <p className="text-xs text-[#989595] mb-1">Name</p>
                <p className="text-sm text-black">
                  {user?.firstName ?? "firstName"}{" "}
                  {user?.lastName ?? "lastName"}
                </p>
              </div>
              <div className="mt-5">
                <p className="text-xs text-[#989595] mb-1">Email</p>
                <p className="text-sm text-black">
                  {user?.email ?? "example@gmail.com"}
                </p>
              </div>
              <div className="mt-5">
                <p className="text-xs text-[#989595] mb-1">Phone Number</p>
                <p className="text-sm text-black">
                  {user?.phone ?? "+233 202020202"}
                </p>
              </div>
              <div className="mt-5">
                <p className="text-xs text-[#989595] mb-1">Category</p>
                <p className="text-sm text-black !capitalize">
                  {user?.category ?? "Academy Category"}
                </p>
              </div>
            </div>
          </div>

          {user?.userMeta.authType === "EMAIL" && (
            <button
              onClick={() => setShowChangePassword(true)}
              className="absolute bottom-5 left-28 border border-[#1567EE]  py-2 px-5 bg-[#1567EE] rounded-lg text-white text-sm hover:shadow-md
transition-all duration-200 ease-in-out"
            >
              Change password
            </button>
          )}

          <button
            onClick={() => setShowEdit(true)}
            className="absolute bottom-5 right-5 border border-black  py-2 px-5 bg-white rounded-lg text-black text-sm hover:shadow-md
       transition-all duration-200 ease-in-out"
          >
            Edit details
          </button>
        </>
      )}
    </section>
  );
}
