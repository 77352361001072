export default function SpotTheDiff2() {
  return (
    <section className="pt-16 pb-10 text-white">
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "0",
          paddingTop: "56.25%",
          paddingBottom: "0",
          boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
          marginTop: "1.6em",
          marginBottom: "0.9em",
          overflow: "hidden",
          borderRadius: "8px",
          willChange: "transform",
        }}
      >
        <iframe
          loading="lazy"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            border: "none",
            padding: "0",
            margin: "0",
          }}
          src="https://www.canva.com/design/DAGQ-bR0SKM/R0CWn7_0F68dc0NzFEtYMg/view?embed"
          allowFullScreen
          allow="fullscreen"
        ></iframe>
      </div>
      <a
        href="https://www.canva.com/design/DAGQ-bR0SKM/R0CWn7_0F68dc0NzFEtYMg/view?utm_content=DAGQ-bR0SKM&utm_campaign=designshare&utm_medium=embeds&utm_source=link"
        target="_blank"
        rel="noopener"
      >
        Name That Brother (Game)
      </a>{" "}
      by Tory Waver
    </section>
  );
}
