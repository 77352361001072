/* eslint-disable @typescript-eslint/no-explicit-any */
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import { useState } from "react";
import { LOGIN, MEMBERSHIP } from "@/constants/path";
import { Link, useNavigate } from "react-location";
import { useMutation } from "@apollo/client";

import toast from "react-hot-toast";
import { setAuth } from "@/apollo/cache/auth";
import ButtonLoader from "@/components/loaders/button";
import { SIGN_UP_USER } from "@/apollo/graphql/mutations/auth";
import {
  RegisterUserMutation,
  RegisterUserMutationVariables,
} from "@/apollo/graphql/generated/types";

export default function SignUp() {
  const [hidePassword, setHidePassword] = useState(true);
  const [termsChecked, setTermsChecked] = useState(false);
  const [receiveNotifications, setReceiveNotifications] = useState(false);

  const navigate = useNavigate();

  const [signup, { loading }] = useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(SIGN_UP_USER);

  const { handleSubmit, ...form }: any = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      termsChecked: false,
      receiveNotifications: false,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Email is invalid")
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be 8 characters or more")
        .required("Password is required"),
      phoneNumber: Yup.string().required("Phone Number is required"),
      termsChecked: Yup.bool().oneOf(
        [true],
        "You must accept the terms and conditions"
      ),
    }),
    onSubmit: async (values) => {
      await signup({
        variables: {
          input: {
            email: values.email,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phoneNumber,
            acceptedTermsAndConditions: values.termsChecked,
            receivePromotionalNotifications: values.receiveNotifications,
          },
        },
      })
        .then(({ data }) => {
          if (data?.registerUser?.token) {
            toast(
              JSON.stringify({
                type: "success",
                title: "Account created successfully",
              })
            );
            setAuth({
              token: data?.registerUser?.token,
              user: data?.registerUser?.user,
            });

            navigate({ replace: true, to: MEMBERSHIP });
          }
        })
        .catch((error) => {
          toast(
            JSON.stringify({
              type: "error",
              title: error?.message || "Error while updating Profile",
            })
          );
        });
    },
  });
  return (
    <section className="bg-white border border-[#66666680] rounded-3xl py-10 mobile:px-5 px-10 w-full max-w-[43.25rem]">
      <h1 className="font-medium text-3xl mobile:text-center text-[#333333]">
        Sign up now
      </h1>

      <div className="mt-5">
        <div className="w-full flex gap-x-5 mobile:flex-col justify-between items-center">
          <div className="mobile:w-full mobile:mb-3">
            <label htmlFor="firstName" className="text-[#666666CC]">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              onChange={(e) => form.setFieldValue("firstName", e.target.value)}
              value={form.values.firstName}
              className="w-full border border-gray-400 rounded-lg p-3 mt-2 focus:outline-none focus:border-[#666666]"
            />
            {form.touched.firstName && form.errors.firstName ? (
              <p className="text-xs mt-2 text-red-500">
                {form.errors.firstName}
              </p>
            ) : null}
          </div>
          <div className="mobile:w-full">
            <label htmlFor="lastName" className="text-[#666666CC]">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              onChange={(e) => form.setFieldValue("lastName", e.target.value)}
              value={form.values.lastName}
              className="w-full border border-gray-400 rounded-lg p-3 mt-2 focus:outline-none focus:border-[#666666]"
            />
            {form.touched.lastName && form.errors.lastName && (
              <p className="text-xs mt-2 text-red-500">
                {form.errors.lastName}
              </p>
            )}
          </div>
        </div>
        <div className="mt-3">
          <label htmlFor="email" className="text-[#666666CC]">
            Email Address
          </label>
          <input
            type="email"
            id="email"
            name="email"
            onChange={(e) => form.setFieldValue("email", e.target.value)}
            value={form.values.email}
            className="w-full border border-gray-400 rounded-lg p-3 mt-2 focus:outline-none focus:border-[#666666]"
          />
          {form.touched.email && form.errors.email && (
            <p className="text-xs mt-2 text-red-500">{form.errors.email}</p>
          )}
        </div>

        <div className="mt-3">
          <label htmlFor="phone" className="text-[#666666CC]">
            Phone Number
          </label>
          <PhoneInput
            country={"us"}
            placeholder={"Mobile Number"}
            value={form.values.phoneNumber}
            onChange={(value: string) =>
              form.setFieldValue("phoneNumber", value)
            }
            countryCodeEditable={false}
            enableSearch={true}
            inputProps={{
              required: true,
              title: "Enter your phone number here",
              className: "input-base text-base ",
            }}
            inputStyle={{
              width: "86%",
              marginLeft: "14%",
              borderTopRightRadius: "0.5rem",
              borderBottomRightRadius: "0.5rem",
            }}
            buttonStyle={{
              // width: "18%",
              borderRight: "none",
              // borderRadius: "0.5rem",
              borderTopLeftRadius: "0.5rem",
              borderBottomLeftRadius: "0.5rem",
              borderColor: "rgb(156 163 175)",
              paddingInline: "1rem",
            }}
            dropdownStyle={{
              borderRadius: "0.5rem",
              borderColor: "rgb(156 163 175)",
              paddingInline: "1rem",
            }}
            searchStyle={{
              width: "90%",
              borderRadius: "0.5rem",
              borderColor: "rgb(156 163 175)",
              paddingInline: "1rem",
            }}
          />
          {form.touched.phoneNumber && form.errors.phoneNumber && (
            <p className="text-xs mt-2 text-red-500">
              {form.errors.phoneNumber}
            </p>
          )}
        </div>
        <div className="mt-4">
          <div className="flex justify-between items-center text-[#666666CC]">
            <label htmlFor="password">Password</label>
            <div className="flex gap-2">
              {!hidePassword ? (
                <button
                  type="button"
                  className="flex gap-1"
                  onClick={() => setHidePassword(true)}
                >
                  <BiSolidHide className="h-6 w-6" aria-hidden="true" />
                  <span>Hide</span>
                </button>
              ) : (
                <button
                  type="button"
                  className="flex gap-1"
                  onClick={() => setHidePassword(false)}
                >
                  <BiSolidShow className="h-6 w-6" aria-hidden="true" />
                  <span>Show</span>
                </button>
              )}
            </div>
          </div>
          <input
            type={hidePassword ? "password" : "text"}
            id="password"
            name="password"
            onChange={(e) => form.setFieldValue("password", e.target.value)}
            value={form.values.password}
            className="w-full border border-gray-400 rounded-lg p-3 mt-1 focus:outline-none focus:border-[#666666]"
          />
          <p className="text-sm text-[#666666] mt-0.5">
            Use 8 or more characters with a mix of letters, numbers & symbols
          </p>
          {form.touched.password && form.errors.password && (
            <p className="text-xs mt-2 text-red-500">{form.errors.password}</p>
          )}
        </div>
        <div className="mt-5 ">
          <div className="flex items-start gap-2">
            <input
              type="checkbox"
              name="accept"
              id="accept"
              className="w-4 h-4 mt-0.5"
              onChange={() => {
                form.setFieldValue("termsChecked", !form.values.termsChecked);
                setTermsChecked(!termsChecked);
              }}
              checked={form.values.termsChecked}
            />{" "}
            <p className="flex-1 text-sm text-[#111111]">
              By creating an account, I agree to our{" "}
              <a href="#" className="underline">
                Terms of use
              </a>{" "}
              and{" "}
              <a href="" className="underline">
                Privacy Policy
              </a>{" "}
            </p>
          </div>
          {form.touched.termsChecked && form.errors.termsChecked && (
            <p className="text-xs mt-2 text-red-500">
              {form.errors.termsChecked}
            </p>
          )}
        </div>
        <div className="mt-5 flex items-start gap-2">
          <input
            type="checkbox"
            name="message"
            id="accept"
            className="w-4 h-4 mt-1"
            onChange={() => {
              form.setFieldValue(
                "receiveNotifications",
                !form.values.receiveNotifications
              );
              setReceiveNotifications(!receiveNotifications);
            }}
            checked={form.values.receiveNotifications}
          />{" "}
          <p className="flex-1 text-sm text-[#333333]">
            By creating an account, I am also consenting to receive SMS messages
            and emails, including product new feature updates, events, and
            marketing promotions.
          </p>
        </div>

        <div className="flex mobile:flex-col items-center gap-5 mt-4">
          <button
            disabled={loading}
            onClick={handleSubmit}
            className="mobile:w-full w-40 mobile:h-12 h-14 flex justify-center items-center rounded-xl bg-gradient-5 text-white
          font-medium mobile:text-sm text-xl "
          >
            {loading ? <ButtonLoader title="" /> : "Sign up"}
          </button>
          <p className="text-sm text-[#333333]">
            Already have an account?{" "}
            <Link to={LOGIN} className="underline">
              Log in{" "}
            </Link>{" "}
          </p>
        </div>
      </div>
    </section>
  );
}
