/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { MdKeyboardCommandKey } from "react-icons/md";
import { LuSearch } from "react-icons/lu";
import moment from "moment";

interface NoteListProps {
  notes: any;
  onSelectNote: (note: any) => void;
}

export const NoteList: React.FC<NoteListProps> = ({ notes, onSelectNote }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredNotes = notes.filter((note: any) =>
    note.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <div className="font-inter flex justify-between items-center border border-[#DEE0E2] px-2 py-1 w-full rounded-lg mb-4">
        <div className="flex items-center flex-1">
          <LuSearch className="w-4 h-4 text-[#1C1C1C33]" aria-hidden="true" />
          <input
            type="search"
            placeholder="Search notes"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="flex-1 border-none outline-none ml-2 focus:outline-none focus:border-none text-sm"
          />
        </div>
        <div className="flex items-center text-[#1C1C1C33]">
          <MdKeyboardCommandKey className="w-4 h-4" aria-hidden="true" />
          <span>/</span>
        </div>
      </div>

      <div>
        {filteredNotes.map((note: any) => (
          <div
            key={note._id}
            onClick={() => onSelectNote(note)}
            className="cursor-pointer mb-2 p-2 hover:rounded-xl hover:bg-gray-200 border-b border-gray-300 text-sm"
          >
            <p className="font-semibold text-black mb-1">{note.title}</p>
            <div className="flex items-center gap-3">
              <p className="text-gray-400">
                {moment(note.createdAt).format("L")}
              </p>
              <div className="w-[6px] h-[6px] rounded-full bg-[#9CA3AF]" />
              <p className="text-sm text-[#1567EE]">
                {note?.createdBy?.firstName ?? "n/a"}{" "}
                {note?.createdBy?.lastName}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
