/* eslint-disable @typescript-eslint/no-explicit-any */
// src/components/CountryList.tsx

import React, { useState } from "react";
import { Combobox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { IoMdArrowDropdown } from "react-icons/io";

interface IndustryListProps {
  selectedIndustry: string;
  onIndustrySelect: (country: string) => void;
  data: any;
}

const IndustryList: React.FC<IndustryListProps> = ({
  selectedIndustry,
  onIndustrySelect,
  data,
}) => {
  const [query, setQuery] = useState("");

  const filteredIndustries =
    query === ""
      ? data
      : data.filter((industry: any) => {
          return industry.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <div className="font-inter relative h-full w-full flex items-center">
      <Combobox
        value={selectedIndustry}
        onChange={(value) => onIndustrySelect(value || "")}
      >
        <div className="w-full flex justify-between items-center">
          <Combobox.Input
            disabled
            placeholder="Select"
            className={clsx(
              "w-full border-none outline-none text-sm text-[#111111]",
              "focus:!outline-none focus:!ring-0 focus:!border-none "
            )}
            displayValue={(industry: string) => industry}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setQuery(e.target.value)
            }
          />
          <Combobox.Button className="group px-2.5">
            <IoMdArrowDropdown className="size-4 fill-primary group-data-[hover]:fill-black" />
          </Combobox.Button>
        </div>

        <Combobox.Options
          className={clsx(
            "w-48 rounded-xl border border-white/5 absolute top-12 right-0 !z-20 shadow-md bg-white max-h-52 overflow-y-scroll p-1 px-2 [--anchor-gap:var(--spacing-1)] empty:invisible",
            "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
          )}
        >
          {filteredIndustries.map((industry: any, index: number) => (
            <Combobox.Option
              key={index}
              value={industry}
              className={`w-full group flex justify-between cursor-default ${
                (index !== filteredIndustries.length - 1) && "border-b"
              } items-center gap-2 px-1 py-1.5 select-none data-[focus]:bg-gray-200`}
            >
              <div className="text-xs/6 text-[#767781] cursor-pointer">
                {industry}
              </div>
              <CheckIcon className="invisible size-4 fill-primary text-[#1567EE] group-data-[selected]:visible" />
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox>
    </div>
  );
};

export default IndustryList;
