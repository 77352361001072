import { Link, useLocation, useSearch } from "react-location";
import { HiOutlineHome } from "react-icons/hi2";
import { LocationGenerics } from "@/router/location";

export default function Breadcrumbs() {
  const { current } = useLocation();
  const searchParams = useSearch<LocationGenerics>();

  let currentLink = "";

  const crumbs =
    searchParams.sort ||
    ""
      .split("/")
      .filter((crumb) => crumb !== "")
      .map((crumb, index) => {
        currentLink += `/${crumb}`;
        return (
          <div key={index} className="font-poppins flex items-center gap-4">
            <Link
              to={currentLink}
              className="font-normal text-base text-white flex items-center"
            >
              {crumb === "vision" ? "Our Vision & Mission" : crumb}
              {crumb === "cartoons" ? "Cartoons Episode" : crumb}
            </Link>
            {index < current.pathname.split("/").length - 2 && (
              <span className="text-2xl text-[#292D32]">&gt;</span>
            )}
          </div>
        );
      });

  return (
    <div className="flex text-white capitalize items-center gap-4">
      <Link
        to={"/main-introduction"}
        className="text-sm text-white flex items-center"
      >
        <HiOutlineHome className="w-5 h-5" aria-hidden="true" />
      </Link>
      <span className="text-2xl text-white">&gt;</span>
      <Link
        to={`/main-introduction/${
          searchParams?.search === "pre" ? "pre-school" : "grade-school"
        }`}
        className="text-base text-white flex items-center"
      >
        Lessons
      </Link>
      <span className="text-2xl text-white">&gt;</span>
      {crumbs}
    </div>
  );
}
