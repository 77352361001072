import { Link, useNavigate } from "react-location";
import VideoPlayer from "./components/video";
import { IoChevronBackSharp } from "react-icons/io5";
import { scrollToTop } from "@/utils/scroll-to-top";
import { useEffect } from "react";
import InviteEmailModal from "./components/email-modal";

export default function Introduction() {
  useEffect(() => {
    scrollToTop();
  }, []);
  const navigate = useNavigate();

  return (
    <section className="max-w-5xl">
      <VideoPlayer />

      <div className="py-12">
        <h1 className="font-poppins text-3xl text-black mb-8">
          Welcome to Dreamland Academy
        </h1>

        <p className="font-poppins text-2xl text-black">
          Dreamland Animation LLC is a startup animation production company
          focused on creating innovative and engaging cartoons inspired by
          stories from the Bible. <br />
          <br /> Established in 2022, Dreamland Animation was started by
          Samantha Quaatey, who, driven by God's guidance, envisioned a platform
          that would bring the gospel to life, catering to young minds and
          spreading the message of Christianity in a fun way. From there, God
          brought to her a team of passionate individuals who wanted to combine
          their Christian faith with their love for animation and storytelling.{" "}
          <br />
          <br /> Today, Dreamland Animation is a small but mighty team of
          incredibly gifted individuals, including writers, animators,
          background artists, illustrators, compositors, sound engineers,
          musicians and voice actors.
        </p>
      </div>

      {/* footer */}

      <div className="fixed bottom-0 inset-x-0 w-full h-16 shadow-lg bg-white">
        <div className="w-full h-full max-w-[75%] ml-auto flex justify-between items-center px-10">
          <button
            onClick={() => navigate({  })}
            className="w-32 h-12 flex justify-center space-x-2 items-center bg-white border border-white rounded-lg
          text-[#111111]"
          >
            <IoChevronBackSharp className="" aria-hidden="true" />
            <span>Previous</span>
          </button>
          <Link
            to={"/academy/vision"}
            className="w-32 h-12 flex justify-center items-center bg-[#EE1D52] border border-[#EE1D52] rounded-lg
          text-white font-poppins"
          >
            Next
          </Link>
        </div>
      </div>
      <InviteEmailModal open={false} setOpen={() => {}} />
    </section>
  );
}
