/* eslint-disable @typescript-eslint/no-explicit-any */

// import toast from "react-hot-toast";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";

interface ModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function InviteEmailModal({ open, setOpen }: ModalProps) {
  const [emails, setEmails] = useState([""]);
  const [errors, setErrors] = useState<string[]>([]);

  const handleInputChange = (index: number, value: string) => {
    const values = [...emails];
    values[index] = value;
    setEmails(values);
    const newErrors = [...errors];
    newErrors[index] = "";
    setErrors(newErrors); // Clear error for this input
  };

  const handleAddInput = () => {
    if (emails.length === 0 || emails[emails.length - 1].trim() !== "") {
      setEmails([...emails, ""]);
      setErrors([...errors, ""]); // Add a new error field
    } else {
      const newErrors = [...errors];
      newErrors[emails.length - 1] =
        "Please fill the previous input before adding a new one.";
      setErrors(newErrors);
    }
  };

  const handleRemoveInput = (index: number) => {
    const values = [...emails];
    values.splice(index, 1);
    setEmails(values);

    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors); // Adjust errors array
  };

  const handleSubmit = () => {
    console.log(emails); 
    // setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={setOpen}
      className="font-poppins relative z-50"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-[#111111D9] bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-2xl bg-white px-4 pb-6 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="">
              <div className="mt-3 text-center flex justify-between items-start gap-10 sm:mt-0 sm:text-left">
                <div className="pr-12">
                  <DialogTitle
                    as="h3"
                    className="text-2xl font-medium text-black"
                  >
                    Add members to seats
                  </DialogTitle>
                  <p className="text-gray-400 text-sm">
                    Enter the emails of members you want to add and send invites
                  </p>
                </div>

                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className="text-base text-gray-400 hover:text-gray-500 "
                >
                  <span className="sr-only">Close</span>
                  Skip
                </button>
              </div>

              <form className="w-full mt-5">
                <label htmlFor="email" className="text-gray-400 ">
                  Email address
                </label>
                {emails.map((email: string, index: number) => (
                  <>
                    <div key={index} className="flex items-center gap-5 mt-2">
                      <input
                        type="email"
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleInputChange(index, e.target.value)
                        }
                        className="w-full h-12 px-4 rounded-xl border border-[#66666659] focus:outline-none
           focus:border-[#FFD700] focus:ring-1 focus:ring-[#FFD700] focus:ring-opacity-50
           transition-all duration-150 ease-in-out focus:shadow-sm placeholder:text-[#272727]"
                      />
                      <button
                        type="button"
                        onClick={() => handleRemoveInput(index)}
                        className=" text-red-500 hover:text-red-700"
                      >
                        <RiDeleteBin6Line className="w-5 h-5" />
                      </button>
                    </div>
                    {errors[index] && (
                      <p className="font-medium text-red-600 text-xs mt-1">
                        {errors[index]}
                      </p>
                    )}
                  </>
                ))}

                <div className="w-full flex justify-end mt-4">
                  <div className="flex items-end gap-3">
                    <p className="text-black">5/6</p>{" "}
                    <button
                      className=" w-[7.688rem] h-[2.5rem] flex justify-center items-center text-sm text-black 
              rounded-lg border border-black"
                      type="button"
                      onClick={handleAddInput}
                    >
                      Add another
                    </button>
                  </div>
                </div>

                <div className="w-full flex justify-end mt-6">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="w-full h-12 flex justify-center items-center bg-[#EE1D52] rounded-lg text-base text-white font-medium
        transition-all duration-150 ease-in-out hover:shadow-xl"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
