import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
};

export type Admin = {
  __typename?: 'Admin';
  _id: Scalars['ID']['output'];
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  password: Scalars['String']['output'];
  profilePicture: Scalars['String']['output'];
  role: Role;
};

export type AdminFilter = {
  email?: InputMaybe<StringOperator>;
  fullName?: InputMaybe<StringOperator>;
  role?: InputMaybe<RoleFilter>;
};

export type AdminQuery = {
  __typename?: 'AdminQuery';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<Admin>>>;
};

export type AdminSignUpInput = {
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type Application = {
  __typename?: 'Application';
  _id?: Maybe<Scalars['ID']['output']>;
  principleDescription?: Maybe<Scalars['String']['output']>;
  principleTitle?: Maybe<Scalars['String']['output']>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token: Scalars['String']['output'];
  user: Admin;
};

export enum AuthType {
  Email = 'EMAIL',
  Google = 'GOOGLE'
}

export type AuthenticatedResetPasswordInput = {
  confirmPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type BooleanOperator = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  notEq?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum Condition {
  And = 'and',
  Or = 'or'
}

export type ContactTechnicalSupportInput = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  message: Scalars['String']['input'];
};

export type DateOperator = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  notEq?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type Discussion = {
  __typename?: 'Discussion';
  _id: Scalars['ID']['output'];
  objectives?: Maybe<Scalars['String']['output']>;
  quizzes?: Maybe<Array<Maybe<Quiz>>>;
};

export type Episode = {
  __typename?: 'Episode';
  _id: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type FindUserByEmailResult = {
  __typename?: 'FindUserByEmailResult';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type FloatOperator = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  notEq?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type IdOperator = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type IntOperator = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  notEq?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type InviteUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type Lesson = {
  __typename?: 'Lesson';
  _id?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  keyTakeaways?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  printableActivity?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  topics?: Maybe<Array<Maybe<Topic>>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type LessonFilter = {
  _id?: InputMaybe<IdOperator>;
  description?: InputMaybe<StringOperator>;
  keyTakeaways?: InputMaybe<StringOperator>;
  printableActivity?: InputMaybe<StringOperator>;
  thumbnailUrl?: InputMaybe<StringOperator>;
  title?: InputMaybe<StringOperator>;
};

export type LessonSort = {
  _id?: InputMaybe<Sort>;
  description?: InputMaybe<Sort>;
  keyTakeaways?: InputMaybe<Sort>;
  printableActivity?: InputMaybe<Sort>;
  thumbnailUrl?: InputMaybe<Sort>;
  title?: InputMaybe<Sort>;
};

export type LessonSummary = {
  __typename?: 'LessonSummary';
  _id: Scalars['ID']['output'];
  episodeTitle?: Maybe<Scalars['String']['output']>;
  focus?: Maybe<Scalars['String']['output']>;
  relevantScriptures?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  summary?: Maybe<Scalars['String']['output']>;
};

export type LoginMeta = {
  __typename?: 'LoginMeta';
  lastLoginAt?: Maybe<Scalars['Date']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  contactTechnicalSupport?: Maybe<Scalars['Boolean']['output']>;
  createApplication?: Maybe<Scalars['Boolean']['output']>;
  createDiscussion?: Maybe<Scalars['Boolean']['output']>;
  createEpisode?: Maybe<Scalars['Boolean']['output']>;
  createFocus?: Maybe<Scalars['Boolean']['output']>;
  createLesson?: Maybe<Lesson>;
  createNote?: Maybe<Note>;
  createPackage?: Maybe<Scalars['Boolean']['output']>;
  createQuiz?: Maybe<Scalars['Boolean']['output']>;
  createSeries?: Maybe<Series>;
  createSubscription?: Maybe<Subscription>;
  createTopic?: Maybe<Scalars['Boolean']['output']>;
  deleteAdmin?: Maybe<Scalars['Boolean']['output']>;
  deleteUser?: Maybe<Scalars['Boolean']['output']>;
  deleteUserAddedByAdmin?: Maybe<Scalars['Boolean']['output']>;
  findUserByEmail?: Maybe<FindUserByEmailResult>;
  inviteUser?: Maybe<Scalars['Boolean']['output']>;
  loginAdmin: AuthPayload;
  loginUser?: Maybe<UserPayload>;
  registerAdmin: AuthPayload;
  registerUser?: Maybe<UserPayload>;
  resetPasswordAfterVerification?: Maybe<Scalars['Boolean']['output']>;
  resetPasswordAuthenticated?: Maybe<Scalars['Boolean']['output']>;
  socialAuthLogin?: Maybe<UserPayload>;
  updateAdmin?: Maybe<Scalars['Boolean']['output']>;
  updateApplication?: Maybe<Scalars['Boolean']['output']>;
  updateDiscussion?: Maybe<Scalars['Boolean']['output']>;
  updateEpisode?: Maybe<Scalars['Boolean']['output']>;
  updateFocus?: Maybe<Scalars['Boolean']['output']>;
  updateLesson?: Maybe<Lesson>;
  updateNote?: Maybe<Note>;
  updateQuiz?: Maybe<Scalars['Boolean']['output']>;
  updateSeries?: Maybe<Series>;
  updateTopic?: Maybe<Scalars['Boolean']['output']>;
  updateUser?: Maybe<Scalars['Boolean']['output']>;
  verifyCode?: Maybe<VerifyCodeResult>;
};


export type MutationContactTechnicalSupportArgs = {
  input: ContactTechnicalSupportInput;
};


export type MutationCreateApplicationArgs = {
  input?: InputMaybe<CreateApplicationInput>;
};


export type MutationCreateDiscussionArgs = {
  input?: InputMaybe<CreateDiscussionInput>;
};


export type MutationCreateEpisodeArgs = {
  input?: InputMaybe<CreateEpisodeInput>;
};


export type MutationCreateFocusArgs = {
  input?: InputMaybe<CreateFocusInput>;
};


export type MutationCreateLessonArgs = {
  input?: InputMaybe<CreateLessonInput>;
};


export type MutationCreateNoteArgs = {
  input?: InputMaybe<CreateNoteInput>;
};


export type MutationCreatePackageArgs = {
  input?: InputMaybe<CreatePackageInput>;
};


export type MutationCreateQuizArgs = {
  input?: InputMaybe<CreateQuizInput>;
};


export type MutationCreateSeriesArgs = {
  input?: InputMaybe<CreateSeriesInput>;
};


export type MutationCreateSubscriptionArgs = {
  packageId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};


export type MutationCreateTopicArgs = {
  input?: InputMaybe<CreateTopicInput>;
};


export type MutationDeleteAdminArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteUserAddedByAdminArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationFindUserByEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationInviteUserArgs = {
  input: Array<InviteUserInput>;
};


export type MutationLoginAdminArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationLoginUserArgs = {
  input?: InputMaybe<LoginUserInput>;
};


export type MutationRegisterAdminArgs = {
  input?: InputMaybe<AdminSignUpInput>;
};


export type MutationRegisterUserArgs = {
  input?: InputMaybe<CreateUserInput>;
};


export type MutationResetPasswordAfterVerificationArgs = {
  confirmedPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationResetPasswordAuthenticatedArgs = {
  input: AuthenticatedResetPasswordInput;
};


export type MutationSocialAuthLoginArgs = {
  input: SocialAuthInput;
};


export type MutationUpdateAdminArgs = {
  input?: InputMaybe<UpdateAdminInput>;
};


export type MutationUpdateApplicationArgs = {
  input?: InputMaybe<UpdateApplicationInput>;
};


export type MutationUpdateDiscussionArgs = {
  input?: InputMaybe<UpdateDiscussionInput>;
};


export type MutationUpdateEpisodeArgs = {
  input?: InputMaybe<UpdateEpisodeInput>;
};


export type MutationUpdateFocusArgs = {
  input?: InputMaybe<UpdateFocusInput>;
};


export type MutationUpdateLessonArgs = {
  input?: InputMaybe<UpdateLessonInput>;
};


export type MutationUpdateNoteArgs = {
  input?: InputMaybe<UpdateNoteInput>;
};


export type MutationUpdateQuizArgs = {
  input?: InputMaybe<UpdateQuizInput>;
};


export type MutationUpdateSeriesArgs = {
  input?: InputMaybe<UpdateSeriesInput>;
};


export type MutationUpdateTopicArgs = {
  input?: InputMaybe<UpdateTopicInput>;
};


export type MutationUpdateUserArgs = {
  input?: InputMaybe<UpdateUserInput>;
};


export type MutationVerifyCodeArgs = {
  code: Scalars['String']['input'];
  user: Scalars['ID']['input'];
};

export type Note = {
  __typename?: 'Note';
  _id?: Maybe<Scalars['ID']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<User>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<User>;
};

export type NoteFilter = {
  _id?: InputMaybe<IdOperator>;
  content?: InputMaybe<StringOperator>;
  title?: InputMaybe<StringOperator>;
  user?: InputMaybe<IdOperator>;
};

export type NoteSort = {
  _id?: InputMaybe<Sort>;
  content?: InputMaybe<Sort>;
  title?: InputMaybe<Sort>;
};

export type Package = {
  __typename?: 'Package';
  _id?: Maybe<Scalars['ID']['output']>;
  billingInterval?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  features?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfSeats?: Maybe<Scalars['Int']['output']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  price?: Maybe<Scalars['Float']['output']>;
  stripePriceId?: Maybe<Scalars['String']['output']>;
  stripeProductId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PackageFilter = {
  _id?: InputMaybe<IdOperator>;
  billingInterval?: InputMaybe<StringOperator>;
  currency?: InputMaybe<StringOperator>;
  description?: InputMaybe<StringOperator>;
  name?: InputMaybe<StringOperator>;
  price?: InputMaybe<FloatOperator>;
  unitPrice?: InputMaybe<IntOperator>;
};

export type PackageQueryResults = {
  __typename?: 'PackageQueryResults';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<Package>>>;
};

export type PackageSort = {
  createdAt?: InputMaybe<Sort>;
  id?: InputMaybe<Sort>;
  name?: InputMaybe<Sort>;
};

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type Query = {
  __typename?: 'Query';
  admim?: Maybe<Admin>;
  admins?: Maybe<AdminQuery>;
  getAllLessons?: Maybe<Array<Maybe<Lesson>>>;
  getAllSeries?: Maybe<Array<Maybe<Series>>>;
  getAllTopics?: Maybe<Array<Maybe<Topic>>>;
  getLesson?: Maybe<Lesson>;
  getLessonLength?: Maybe<Scalars['Int']['output']>;
  getNote?: Maybe<Note>;
  getNoteLength?: Maybe<Scalars['Int']['output']>;
  getNotes?: Maybe<Array<Maybe<Note>>>;
  getPackage?: Maybe<Package>;
  getPackages?: Maybe<PackageQueryResults>;
  getSeries?: Maybe<Series>;
  getSeriesLength?: Maybe<Scalars['Int']['output']>;
  getSubscription?: Maybe<Subscription>;
  getSubscriptions?: Maybe<Array<Maybe<Subscription>>>;
  getTopic?: Maybe<Topic>;
  getTopicsLength?: Maybe<Scalars['Int']['output']>;
  getUsersAddedByAdmin?: Maybe<Array<Maybe<User>>>;
  me?: Maybe<User>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryAdmimArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAdminsArgs = {
  filter?: InputMaybe<AdminFilter>;
  pagination: Pagination;
  populate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  search?: InputMaybe<SearchOperator>;
};


export type QueryGetAllLessonsArgs = {
  filter?: InputMaybe<LessonFilter>;
  pagination?: InputMaybe<Pagination>;
  populate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  search?: InputMaybe<SearchOperator>;
  sort?: InputMaybe<LessonSort>;
};


export type QueryGetAllSeriesArgs = {
  filter?: InputMaybe<SeriesFilter>;
  pagination?: InputMaybe<Pagination>;
  populate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  search?: InputMaybe<SearchOperator>;
  sort?: InputMaybe<SeriesSort>;
};


export type QueryGetAllTopicsArgs = {
  filter?: InputMaybe<TopicFilter>;
  pagination?: InputMaybe<Pagination>;
  populate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  search?: InputMaybe<SearchOperator>;
  sort?: InputMaybe<TopicSort>;
};


export type QueryGetLessonArgs = {
  _id: Scalars['ID']['input'];
  populate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGetLessonLengthArgs = {
  filter?: InputMaybe<LessonFilter>;
  search?: InputMaybe<SearchOperator>;
};


export type QueryGetNoteArgs = {
  _id: Scalars['ID']['input'];
  populate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGetNoteLengthArgs = {
  filter?: InputMaybe<NoteFilter>;
  search?: InputMaybe<SearchOperator>;
};


export type QueryGetNotesArgs = {
  filter?: InputMaybe<NoteFilter>;
  pagination?: InputMaybe<Pagination>;
  populate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  search?: InputMaybe<SearchOperator>;
  sort?: InputMaybe<NoteSort>;
};


export type QueryGetPackageArgs = {
  filter?: InputMaybe<PackageFilter>;
  populate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGetPackagesArgs = {
  filter?: InputMaybe<PackageFilter>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<SearchOperator>;
  sort?: InputMaybe<PackageSort>;
};


export type QueryGetSeriesArgs = {
  _id: Scalars['ID']['input'];
  populate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGetSeriesLengthArgs = {
  filter?: InputMaybe<SeriesFilter>;
  search?: InputMaybe<SearchOperator>;
};


export type QueryGetSubscriptionArgs = {
  _id?: InputMaybe<Scalars['ID']['input']>;
  populate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGetSubscriptionsArgs = {
  filter?: InputMaybe<SubscriptionFilter>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<SearchOperator>;
  sort?: InputMaybe<SubscriptionSort>;
};


export type QueryGetTopicArgs = {
  _id: Scalars['ID']['input'];
  populate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGetTopicsLengthArgs = {
  filter?: InputMaybe<TopicFilter>;
  search?: InputMaybe<SearchOperator>;
};


export type QueryGetUsersAddedByAdminArgs = {
  adminId: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  pagination: Pagination;
  populate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  search?: InputMaybe<SearchOperator>;
};

export type Quiz = {
  __typename?: 'Quiz';
  _id: Scalars['ID']['output'];
  answer: Scalars['String']['output'];
  options: Array<Scalars['String']['output']>;
  question: Scalars['String']['output'];
};

export type Role = {
  __typename?: 'Role';
  _id: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Maybe<Scalars['String']['output']>>;
  updatedAt: Scalars['String']['output'];
};

export type RoleFilter = {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SearchOperator = {
  /** Fields to search text from */
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Modifiers for text search */
  options?: InputMaybe<Array<InputMaybe<SearchOperatorOption>>>;
  /** text to search for */
  query?: InputMaybe<Scalars['String']['input']>;
};

export enum SearchOperatorOption {
  I = 'i',
  M = 'm'
}

export type Series = {
  __typename?: 'Series';
  _id?: Maybe<Scalars['ID']['output']>;
  briefOverview?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  latest?: Maybe<Scalars['Boolean']['output']>;
  learningObjectives?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  subtitle?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SeriesFilter = {
  _id?: InputMaybe<IdOperator>;
  briefOverview?: InputMaybe<StringOperator>;
  description?: InputMaybe<StringOperator>;
  latest?: InputMaybe<BooleanOperator>;
  subtitle?: InputMaybe<StringOperator>;
  title?: InputMaybe<StringOperator>;
};

export type SeriesSort = {
  _id?: InputMaybe<Sort>;
  briefOverview?: InputMaybe<Sort>;
  description?: InputMaybe<Sort>;
  latest?: InputMaybe<Sort>;
  subtitle?: InputMaybe<Sort>;
  title?: InputMaybe<Sort>;
};

export type SocialAuthInput = {
  acceptedTermsAndConditions: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  profilePicture: Scalars['String']['input'];
};

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperator = {
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notEq?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  regex?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _id?: Maybe<Scalars['ID']['output']>;
  canceledAt?: Maybe<Scalars['Date']['output']>;
  canceledAtPeriodEnd?: Maybe<Scalars['Date']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  currentPeriodEnd?: Maybe<Scalars['Date']['output']>;
  currentPeriodStart?: Maybe<Scalars['Date']['output']>;
  packageId?: Maybe<Package>;
  status?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type SubscriptionFilter = {
  _id?: InputMaybe<IdOperator>;
  currentPeriodEnd?: InputMaybe<DateOperator>;
  status?: InputMaybe<StringOperator>;
};

export type SubscriptionQueryResults = {
  __typename?: 'SubscriptionQueryResults';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<Subscription>>>;
};

export type SubscriptionSort = {
  createdAt?: InputMaybe<Sort>;
  id?: InputMaybe<Sort>;
  name?: InputMaybe<Sort>;
};

export type Topic = {
  __typename?: 'Topic';
  _id?: Maybe<Scalars['ID']['output']>;
  application?: Maybe<Application>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  discussion?: Maybe<Discussion>;
  duration?: Maybe<Scalars['String']['output']>;
  episode?: Maybe<Episode>;
  focus?: Maybe<LessonSummary>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type TopicFilter = {
  _id?: InputMaybe<IdOperator>;
  title?: InputMaybe<StringOperator>;
  type?: InputMaybe<StringOperator>;
};

export type TopicSort = {
  _id?: InputMaybe<Sort>;
  title?: InputMaybe<Sort>;
  type?: InputMaybe<Sort>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID']['output'];
  category?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  isPremiumUser?: Maybe<Scalars['Boolean']['output']>;
  isSubscribed?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  loginMeta?: Maybe<LoginMeta>;
  phone?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['Int']['output']>;
  subscription?: Maybe<Subscription>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  userMeta?: Maybe<UserMeta>;
};

export type UserFilter = {
  email?: InputMaybe<StringOperator>;
  fullName?: InputMaybe<StringOperator>;
};

export type UserMeta = {
  __typename?: 'UserMeta';
  acceptedTermsAndConditions?: Maybe<Scalars['Boolean']['output']>;
  authType?: Maybe<AuthType>;
  invitedBy?: Maybe<Scalars['ID']['output']>;
  isFirstTimeUser?: Maybe<Scalars['Boolean']['output']>;
  receivePromotionalNotifications?: Maybe<Scalars['Boolean']['output']>;
};

export type UserPayload = {
  __typename?: 'UserPayload';
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type CreateApplicationInput = {
  principleDescription: Scalars['String']['input'];
  principleTitle: Scalars['String']['input'];
};

export type CreateDiscussionInput = {
  objectives: Scalars['String']['input'];
  quizzes: Array<Scalars['String']['input']>;
};

export type CreateEpisodeInput = {
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
  videoUrl: Scalars['String']['input'];
};

export type CreateFocusInput = {
  episodeTitle: Scalars['String']['input'];
  focus: Scalars['String']['input'];
  relevantScriptures: Array<Scalars['String']['input']>;
  summary: Scalars['String']['input'];
};

export type CreateLessonInput = {
  description: Scalars['String']['input'];
  keyTakeaways: Array<Scalars['String']['input']>;
  printableActivity: Array<Scalars['String']['input']>;
  thumbnailUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateNoteInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  user: Scalars['ID']['input'];
};

export type CreatePackageInput = {
  billingInterval: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  description: Scalars['String']['input'];
  features: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  numberOfSeats: Scalars['Int']['input'];
  permissions: Array<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  unitPrice: Scalars['Int']['input'];
};

export type CreateQuizInput = {
  answers: Array<Scalars['String']['input']>;
  correctAnswer: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export type CreateSeriesInput = {
  briefOverview: Scalars['String']['input'];
  description: Scalars['String']['input'];
  duration: Scalars['String']['input'];
  latest: Scalars['Boolean']['input'];
  learningObjectives: Array<Scalars['String']['input']>;
  subtitle: Scalars['String']['input'];
  thumbnail: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateTopicInput = {
  application: Scalars['ID']['input'];
  discussion: Scalars['ID']['input'];
  duration: Scalars['String']['input'];
  episode: Scalars['ID']['input'];
  focus: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateUserInput = {
  acceptedTermsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  receivePromotionalNotifications?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoginUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAdminInput = {
  email: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
  profilePicture: Scalars['String']['input'];
};

export type UpdateApplicationInput = {
  _id: Scalars['ID']['input'];
  principleDescription: Scalars['String']['input'];
  principleTitle: Scalars['String']['input'];
};

export type UpdateDiscussionInput = {
  _id: Scalars['ID']['input'];
  objectives: Scalars['String']['input'];
  quizzes: Array<Scalars['String']['input']>;
};

export type UpdateEpisodeInput = {
  _id: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
  videoUrl: Scalars['String']['input'];
};

export type UpdateFocusInput = {
  _id: Scalars['ID']['input'];
  episodeTitle: Scalars['String']['input'];
  focus: Scalars['String']['input'];
  relevantScriptures: Array<Scalars['String']['input']>;
  summary: Scalars['String']['input'];
};

export type UpdateLessonInput = {
  _id: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  keyTakeaways: Array<Scalars['String']['input']>;
  printableActivity: Array<Scalars['String']['input']>;
  thumbnailUrl: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type UpdateNoteInput = {
  _id: Scalars['ID']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  user: Scalars['ID']['input'];
};

export type UpdateQuizInput = {
  _id: Scalars['ID']['input'];
  answers: Array<Scalars['String']['input']>;
  correctAnswer: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export type UpdateSeriesInput = {
  _id: Scalars['ID']['input'];
  briefOverview: Scalars['String']['input'];
  description: Scalars['String']['input'];
  duration: Scalars['String']['input'];
  latest: Scalars['Boolean']['input'];
  learningObjectives: Array<Scalars['String']['input']>;
  subtitle: Scalars['String']['input'];
  thumbnail: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type UpdateTopicInput = {
  _id: Scalars['ID']['input'];
  application: Scalars['ID']['input'];
  discussion: Scalars['ID']['input'];
  duration: Scalars['String']['input'];
  episode: Scalars['ID']['input'];
  focus: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type UpdateUserInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
};

export type VerifyCodeResult = {
  __typename?: 'verifyCodeResult';
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type ContactTechnicalSupportMutationVariables = Exact<{
  input: ContactTechnicalSupportInput;
}>;


export type ContactTechnicalSupportMutation = { __typename?: 'Mutation', contactTechnicalSupport?: boolean | null };

export type RegisterUserMutationVariables = Exact<{
  input?: InputMaybe<CreateUserInput>;
}>;


export type RegisterUserMutation = { __typename?: 'Mutation', registerUser?: { __typename?: 'UserPayload', token?: string | null, user?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null, category?: string | null, isPremiumUser?: boolean | null, isSubscribed?: boolean | null, progress?: number | null, profilePicture?: string | null, createdAt?: any | null, updatedAt?: any | null, subscription?: { __typename?: 'Subscription', _id?: string | null, userId?: string | null, stripeSubscriptionId?: string | null, stripeCustomerId?: string | null, status?: string | null, clientSecret?: string | null, currentPeriodStart?: any | null, currentPeriodEnd?: any | null, canceledAtPeriodEnd?: any | null, canceledAt?: any | null, createdAt?: any | null, updatedAt?: any | null, packageId?: { __typename?: 'Package', _id?: string | null, name?: string | null, description?: string | null, stripeProductId?: string | null, stripePriceId?: string | null, numberOfSeats?: number | null, price?: number | null, billingInterval?: string | null, features?: Array<string | null> | null, permissions?: Array<string | null> | null, currency?: string | null, createdAt?: any | null, updatedAt?: any | null } | null } | null, userMeta?: { __typename?: 'UserMeta', authType?: AuthType | null, isFirstTimeUser?: boolean | null, invitedBy?: string | null, acceptedTermsAndConditions?: boolean | null, receivePromotionalNotifications?: boolean | null } | null, loginMeta?: { __typename?: 'LoginMeta', lastLoginAt?: any | null } | null } | null } | null };

export type LoginUserMutationVariables = Exact<{
  input?: InputMaybe<LoginUserInput>;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser?: { __typename?: 'UserPayload', token?: string | null, user?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null, category?: string | null, isPremiumUser?: boolean | null, isSubscribed?: boolean | null, progress?: number | null, profilePicture?: string | null, createdAt?: any | null, updatedAt?: any | null, subscription?: { __typename?: 'Subscription', _id?: string | null, userId?: string | null, stripeSubscriptionId?: string | null, stripeCustomerId?: string | null, status?: string | null, clientSecret?: string | null, currentPeriodStart?: any | null, currentPeriodEnd?: any | null, canceledAtPeriodEnd?: any | null, canceledAt?: any | null, createdAt?: any | null, updatedAt?: any | null, packageId?: { __typename?: 'Package', _id?: string | null, name?: string | null, description?: string | null, stripeProductId?: string | null, stripePriceId?: string | null, numberOfSeats?: number | null, price?: number | null, billingInterval?: string | null, features?: Array<string | null> | null, permissions?: Array<string | null> | null, currency?: string | null, createdAt?: any | null, updatedAt?: any | null } | null } | null, userMeta?: { __typename?: 'UserMeta', authType?: AuthType | null, isFirstTimeUser?: boolean | null, invitedBy?: string | null, acceptedTermsAndConditions?: boolean | null, receivePromotionalNotifications?: boolean | null } | null, loginMeta?: { __typename?: 'LoginMeta', lastLoginAt?: any | null } | null } | null } | null };

export type SocialAuthLoginMutationVariables = Exact<{
  input: SocialAuthInput;
}>;


export type SocialAuthLoginMutation = { __typename?: 'Mutation', socialAuthLogin?: { __typename?: 'UserPayload', token?: string | null, user?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null, category?: string | null, isPremiumUser?: boolean | null, isSubscribed?: boolean | null, progress?: number | null, profilePicture?: string | null, createdAt?: any | null, updatedAt?: any | null, subscription?: { __typename?: 'Subscription', _id?: string | null, userId?: string | null, stripeSubscriptionId?: string | null, stripeCustomerId?: string | null, status?: string | null, clientSecret?: string | null, currentPeriodStart?: any | null, currentPeriodEnd?: any | null, canceledAtPeriodEnd?: any | null, canceledAt?: any | null, createdAt?: any | null, updatedAt?: any | null, packageId?: { __typename?: 'Package', _id?: string | null, name?: string | null, description?: string | null, stripeProductId?: string | null, stripePriceId?: string | null, numberOfSeats?: number | null, price?: number | null, billingInterval?: string | null, features?: Array<string | null> | null, permissions?: Array<string | null> | null, currency?: string | null, createdAt?: any | null, updatedAt?: any | null } | null } | null, userMeta?: { __typename?: 'UserMeta', authType?: AuthType | null, isFirstTimeUser?: boolean | null, invitedBy?: string | null, acceptedTermsAndConditions?: boolean | null, receivePromotionalNotifications?: boolean | null } | null, loginMeta?: { __typename?: 'LoginMeta', lastLoginAt?: any | null } | null } | null } | null };

export type ResetPasswordAuthenticatedMutationVariables = Exact<{
  input: AuthenticatedResetPasswordInput;
}>;


export type ResetPasswordAuthenticatedMutation = { __typename?: 'Mutation', resetPasswordAuthenticated?: boolean | null };

export type VerifyCodeMutationVariables = Exact<{
  code: Scalars['String']['input'];
  user: Scalars['ID']['input'];
}>;


export type VerifyCodeMutation = { __typename?: 'Mutation', verifyCode?: { __typename?: 'verifyCodeResult', status?: string | null, message?: string | null, token?: string | null } | null };

export type FindUserByEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type FindUserByEmailMutation = { __typename?: 'Mutation', findUserByEmail?: { __typename?: 'FindUserByEmailResult', status?: string | null, message?: string | null, user?: { __typename?: 'User', _id: string, email?: string | null } | null } | null };

export type ResetPasswordAfterVerificationMutationVariables = Exact<{
  password: Scalars['String']['input'];
  confirmedPassword: Scalars['String']['input'];
}>;


export type ResetPasswordAfterVerificationMutation = { __typename?: 'Mutation', resetPasswordAfterVerification?: boolean | null };

export type CreateNoteMutationVariables = Exact<{
  input?: InputMaybe<CreateNoteInput>;
}>;


export type CreateNoteMutation = { __typename?: 'Mutation', createNote?: { __typename?: 'Note', _id?: string | null, title?: string | null, content?: string | null, createdAt?: any | null, updatedAt?: any | null, user?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null } | null, createdBy?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null } | null } | null };

export type UpdateNoteMutationVariables = Exact<{
  input?: InputMaybe<UpdateNoteInput>;
}>;


export type UpdateNoteMutation = { __typename?: 'Mutation', updateNote?: { __typename?: 'Note', _id?: string | null, title?: string | null, content?: string | null, createdAt?: any | null, updatedAt?: any | null, user?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null } | null, createdBy?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null } | null } | null };

export type CreateSubscriptionMutationVariables = Exact<{
  packageId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription?: { __typename?: 'Subscription', _id?: string | null, userId?: string | null, stripeSubscriptionId?: string | null, stripeCustomerId?: string | null, status?: string | null, clientSecret?: string | null, currentPeriodStart?: any | null, currentPeriodEnd?: any | null, canceledAtPeriodEnd?: any | null, canceledAt?: any | null, createdAt?: any | null, updatedAt?: any | null, packageId?: { __typename?: 'Package', _id?: string | null, name?: string | null, description?: string | null, stripeProductId?: string | null, stripePriceId?: string | null, numberOfSeats?: number | null, price?: number | null, billingInterval?: string | null, features?: Array<string | null> | null, permissions?: Array<string | null> | null, currency?: string | null, createdAt?: any | null, updatedAt?: any | null } | null } | null };

export type UpdateUserMutationVariables = Exact<{
  input?: InputMaybe<UpdateUserInput>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: boolean | null };

export type InviteUserMutationVariables = Exact<{
  input: Array<InviteUserInput> | InviteUserInput;
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteUser?: boolean | null };

export type DeleteUserAddedByAdminMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type DeleteUserAddedByAdminMutation = { __typename?: 'Mutation', deleteUserAddedByAdmin?: boolean | null };

export type GetNotesQueryVariables = Exact<{
  filter?: InputMaybe<NoteFilter>;
  search?: InputMaybe<SearchOperator>;
  populate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<NoteSort>;
}>;


export type GetNotesQuery = { __typename?: 'Query', getNotes?: Array<{ __typename?: 'Note', _id?: string | null, title?: string | null, content?: string | null, createdAt?: any | null, updatedAt?: any | null, createdBy?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null } | null, user?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null } | null } | null> | null };

export type GetNoteQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  populate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type GetNoteQuery = { __typename?: 'Query', getNote?: { __typename?: 'Note', _id?: string | null, title?: string | null, content?: string | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type GetPackagesQueryVariables = Exact<{
  filter?: InputMaybe<PackageFilter>;
  search?: InputMaybe<SearchOperator>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<PackageSort>;
}>;


export type GetPackagesQuery = { __typename?: 'Query', getPackages?: { __typename?: 'PackageQueryResults', count?: number | null, rows?: Array<{ __typename?: 'Package', _id?: string | null, name?: string | null, description?: string | null, stripeProductId?: string | null, stripePriceId?: string | null, numberOfSeats?: number | null, price?: number | null, billingInterval?: string | null, features?: Array<string | null> | null, permissions?: Array<string | null> | null, currency?: string | null, createdAt?: any | null, updatedAt?: any | null } | null> | null } | null };

export type GetPackageQueryVariables = Exact<{
  filter?: InputMaybe<PackageFilter>;
}>;


export type GetPackageQuery = { __typename?: 'Query', getPackage?: { __typename?: 'Package', _id?: string | null, name?: string | null, description?: string | null, stripeProductId?: string | null, stripePriceId?: string | null, numberOfSeats?: number | null, price?: number | null, billingInterval?: string | null, features?: Array<string | null> | null, permissions?: Array<string | null> | null, currency?: string | null, createdAt?: any | null, updatedAt?: any | null } | null };

export type GetSubscriptionQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
  populate?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type GetSubscriptionQuery = { __typename?: 'Query', getSubscription?: { __typename?: 'Subscription', _id?: string | null, userId?: string | null, stripeSubscriptionId?: string | null, stripeCustomerId?: string | null, status?: string | null, clientSecret?: string | null, currentPeriodStart?: any | null, currentPeriodEnd?: any | null, canceledAtPeriodEnd?: any | null, canceledAt?: any | null, createdAt?: any | null, updatedAt?: any | null, packageId?: { __typename?: 'Package', _id?: string | null, name?: string | null, description?: string | null, stripeProductId?: string | null, stripePriceId?: string | null, numberOfSeats?: number | null, price?: number | null, billingInterval?: string | null, features?: Array<string | null> | null, permissions?: Array<string | null> | null, currency?: string | null, createdAt?: any | null, updatedAt?: any | null } | null } | null };

export type GetSubscriptionsQueryVariables = Exact<{
  filter?: InputMaybe<SubscriptionFilter>;
  search?: InputMaybe<SearchOperator>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<SubscriptionSort>;
}>;


export type GetSubscriptionsQuery = { __typename?: 'Query', getSubscriptions?: Array<{ __typename?: 'Subscription', _id?: string | null, userId?: string | null, stripeSubscriptionId?: string | null, stripeCustomerId?: string | null, status?: string | null, clientSecret?: string | null, currentPeriodStart?: any | null, currentPeriodEnd?: any | null, canceledAtPeriodEnd?: any | null, canceledAt?: any | null, createdAt?: any | null, updatedAt?: any | null, packageId?: { __typename?: 'Package', _id?: string | null, name?: string | null, description?: string | null, stripeProductId?: string | null, stripePriceId?: string | null, numberOfSeats?: number | null, price?: number | null, billingInterval?: string | null, features?: Array<string | null> | null, permissions?: Array<string | null> | null, currency?: string | null, createdAt?: any | null, updatedAt?: any | null } | null } | null> | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null, category?: string | null, isPremiumUser?: boolean | null, isSubscribed?: boolean | null, progress?: number | null, profilePicture?: string | null, createdAt?: any | null, updatedAt?: any | null, subscription?: { __typename?: 'Subscription', _id?: string | null, userId?: string | null, stripeSubscriptionId?: string | null, stripeCustomerId?: string | null, status?: string | null, clientSecret?: string | null, currentPeriodStart?: any | null, currentPeriodEnd?: any | null, canceledAtPeriodEnd?: any | null, canceledAt?: any | null, createdAt?: any | null, updatedAt?: any | null, packageId?: { __typename?: 'Package', _id?: string | null, name?: string | null, description?: string | null, stripeProductId?: string | null, stripePriceId?: string | null, numberOfSeats?: number | null, price?: number | null, billingInterval?: string | null, features?: Array<string | null> | null, permissions?: Array<string | null> | null, currency?: string | null, createdAt?: any | null, updatedAt?: any | null } | null } | null, userMeta?: { __typename?: 'UserMeta', authType?: AuthType | null, isFirstTimeUser?: boolean | null, invitedBy?: string | null, acceptedTermsAndConditions?: boolean | null, receivePromotionalNotifications?: boolean | null } | null, loginMeta?: { __typename?: 'LoginMeta', lastLoginAt?: any | null } | null } | null };

export type GetUsersAddedByAdminQueryVariables = Exact<{
  adminId: Scalars['ID']['input'];
}>;


export type GetUsersAddedByAdminQuery = { __typename?: 'Query', getUsersAddedByAdmin?: Array<{ __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null } | null> | null };


export const ContactTechnicalSupportDocument = gql`
    mutation ContactTechnicalSupport($input: ContactTechnicalSupportInput!) {
  contactTechnicalSupport(input: $input)
}
    `;
export type ContactTechnicalSupportMutationFn = Apollo.MutationFunction<ContactTechnicalSupportMutation, ContactTechnicalSupportMutationVariables>;

/**
 * __useContactTechnicalSupportMutation__
 *
 * To run a mutation, you first call `useContactTechnicalSupportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactTechnicalSupportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactTechnicalSupportMutation, { data, loading, error }] = useContactTechnicalSupportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContactTechnicalSupportMutation(baseOptions?: Apollo.MutationHookOptions<ContactTechnicalSupportMutation, ContactTechnicalSupportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactTechnicalSupportMutation, ContactTechnicalSupportMutationVariables>(ContactTechnicalSupportDocument, options);
      }
export type ContactTechnicalSupportMutationHookResult = ReturnType<typeof useContactTechnicalSupportMutation>;
export type ContactTechnicalSupportMutationResult = Apollo.MutationResult<ContactTechnicalSupportMutation>;
export type ContactTechnicalSupportMutationOptions = Apollo.BaseMutationOptions<ContactTechnicalSupportMutation, ContactTechnicalSupportMutationVariables>;
export const RegisterUserDocument = gql`
    mutation RegisterUser($input: createUserInput) {
  registerUser(input: $input) {
    token
    user {
      _id
      firstName
      lastName
      email
      phone
      category
      subscription {
        _id
        userId
        packageId {
          _id
          name
          description
          stripeProductId
          stripePriceId
          numberOfSeats
          price
          billingInterval
          features
          permissions
          currency
          createdAt
          updatedAt
        }
        stripeSubscriptionId
        stripeCustomerId
        status
        clientSecret
        currentPeriodStart
        currentPeriodEnd
        canceledAtPeriodEnd
        canceledAt
        createdAt
        updatedAt
      }
      isPremiumUser
      isSubscribed
      progress
      profilePicture
      userMeta {
        authType
        isFirstTimeUser
        invitedBy
        acceptedTermsAndConditions
        receivePromotionalNotifications
      }
      loginMeta {
        lastLoginAt
      }
      createdAt
      updatedAt
    }
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($input: loginUserInput) {
  loginUser(input: $input) {
    token
    user {
      _id
      firstName
      lastName
      email
      phone
      category
      subscription {
        _id
        userId
        packageId {
          _id
          name
          description
          stripeProductId
          stripePriceId
          numberOfSeats
          price
          billingInterval
          features
          permissions
          currency
          createdAt
          updatedAt
        }
        stripeSubscriptionId
        stripeCustomerId
        status
        clientSecret
        currentPeriodStart
        currentPeriodEnd
        canceledAtPeriodEnd
        canceledAt
        createdAt
        updatedAt
      }
      isPremiumUser
      isSubscribed
      progress
      profilePicture
      userMeta {
        authType
        isFirstTimeUser
        invitedBy
        acceptedTermsAndConditions
        receivePromotionalNotifications
      }
      loginMeta {
        lastLoginAt
      }
      createdAt
      updatedAt
    }
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const SocialAuthLoginDocument = gql`
    mutation SocialAuthLogin($input: SocialAuthInput!) {
  socialAuthLogin(input: $input) {
    token
    user {
      _id
      firstName
      lastName
      email
      phone
      category
      subscription {
        _id
        userId
        packageId {
          _id
          name
          description
          stripeProductId
          stripePriceId
          numberOfSeats
          price
          billingInterval
          features
          permissions
          currency
          createdAt
          updatedAt
        }
        stripeSubscriptionId
        stripeCustomerId
        status
        clientSecret
        currentPeriodStart
        currentPeriodEnd
        canceledAtPeriodEnd
        canceledAt
        createdAt
        updatedAt
      }
      isPremiumUser
      isSubscribed
      progress
      profilePicture
      userMeta {
        authType
        isFirstTimeUser
        invitedBy
        acceptedTermsAndConditions
        receivePromotionalNotifications
      }
      loginMeta {
        lastLoginAt
      }
      createdAt
      updatedAt
    }
  }
}
    `;
export type SocialAuthLoginMutationFn = Apollo.MutationFunction<SocialAuthLoginMutation, SocialAuthLoginMutationVariables>;

/**
 * __useSocialAuthLoginMutation__
 *
 * To run a mutation, you first call `useSocialAuthLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialAuthLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialAuthLoginMutation, { data, loading, error }] = useSocialAuthLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSocialAuthLoginMutation(baseOptions?: Apollo.MutationHookOptions<SocialAuthLoginMutation, SocialAuthLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SocialAuthLoginMutation, SocialAuthLoginMutationVariables>(SocialAuthLoginDocument, options);
      }
export type SocialAuthLoginMutationHookResult = ReturnType<typeof useSocialAuthLoginMutation>;
export type SocialAuthLoginMutationResult = Apollo.MutationResult<SocialAuthLoginMutation>;
export type SocialAuthLoginMutationOptions = Apollo.BaseMutationOptions<SocialAuthLoginMutation, SocialAuthLoginMutationVariables>;
export const ResetPasswordAuthenticatedDocument = gql`
    mutation ResetPasswordAuthenticated($input: AuthenticatedResetPasswordInput!) {
  resetPasswordAuthenticated(input: $input)
}
    `;
export type ResetPasswordAuthenticatedMutationFn = Apollo.MutationFunction<ResetPasswordAuthenticatedMutation, ResetPasswordAuthenticatedMutationVariables>;

/**
 * __useResetPasswordAuthenticatedMutation__
 *
 * To run a mutation, you first call `useResetPasswordAuthenticatedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordAuthenticatedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordAuthenticatedMutation, { data, loading, error }] = useResetPasswordAuthenticatedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordAuthenticatedMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordAuthenticatedMutation, ResetPasswordAuthenticatedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordAuthenticatedMutation, ResetPasswordAuthenticatedMutationVariables>(ResetPasswordAuthenticatedDocument, options);
      }
export type ResetPasswordAuthenticatedMutationHookResult = ReturnType<typeof useResetPasswordAuthenticatedMutation>;
export type ResetPasswordAuthenticatedMutationResult = Apollo.MutationResult<ResetPasswordAuthenticatedMutation>;
export type ResetPasswordAuthenticatedMutationOptions = Apollo.BaseMutationOptions<ResetPasswordAuthenticatedMutation, ResetPasswordAuthenticatedMutationVariables>;
export const VerifyCodeDocument = gql`
    mutation VerifyCode($code: String!, $user: ID!) {
  verifyCode(code: $code, user: $user) {
    status
    message
    token
  }
}
    `;
export type VerifyCodeMutationFn = Apollo.MutationFunction<VerifyCodeMutation, VerifyCodeMutationVariables>;

/**
 * __useVerifyCodeMutation__
 *
 * To run a mutation, you first call `useVerifyCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCodeMutation, { data, loading, error }] = useVerifyCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useVerifyCodeMutation(baseOptions?: Apollo.MutationHookOptions<VerifyCodeMutation, VerifyCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyCodeMutation, VerifyCodeMutationVariables>(VerifyCodeDocument, options);
      }
export type VerifyCodeMutationHookResult = ReturnType<typeof useVerifyCodeMutation>;
export type VerifyCodeMutationResult = Apollo.MutationResult<VerifyCodeMutation>;
export type VerifyCodeMutationOptions = Apollo.BaseMutationOptions<VerifyCodeMutation, VerifyCodeMutationVariables>;
export const FindUserByEmailDocument = gql`
    mutation FindUserByEmail($email: String!) {
  findUserByEmail(email: $email) {
    status
    message
    user {
      _id
      email
    }
  }
}
    `;
export type FindUserByEmailMutationFn = Apollo.MutationFunction<FindUserByEmailMutation, FindUserByEmailMutationVariables>;

/**
 * __useFindUserByEmailMutation__
 *
 * To run a mutation, you first call `useFindUserByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFindUserByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [findUserByEmailMutation, { data, loading, error }] = useFindUserByEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useFindUserByEmailMutation(baseOptions?: Apollo.MutationHookOptions<FindUserByEmailMutation, FindUserByEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FindUserByEmailMutation, FindUserByEmailMutationVariables>(FindUserByEmailDocument, options);
      }
export type FindUserByEmailMutationHookResult = ReturnType<typeof useFindUserByEmailMutation>;
export type FindUserByEmailMutationResult = Apollo.MutationResult<FindUserByEmailMutation>;
export type FindUserByEmailMutationOptions = Apollo.BaseMutationOptions<FindUserByEmailMutation, FindUserByEmailMutationVariables>;
export const ResetPasswordAfterVerificationDocument = gql`
    mutation ResetPasswordAfterVerification($password: String!, $confirmedPassword: String!) {
  resetPasswordAfterVerification(
    password: $password
    confirmedPassword: $confirmedPassword
  )
}
    `;
export type ResetPasswordAfterVerificationMutationFn = Apollo.MutationFunction<ResetPasswordAfterVerificationMutation, ResetPasswordAfterVerificationMutationVariables>;

/**
 * __useResetPasswordAfterVerificationMutation__
 *
 * To run a mutation, you first call `useResetPasswordAfterVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordAfterVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordAfterVerificationMutation, { data, loading, error }] = useResetPasswordAfterVerificationMutation({
 *   variables: {
 *      password: // value for 'password'
 *      confirmedPassword: // value for 'confirmedPassword'
 *   },
 * });
 */
export function useResetPasswordAfterVerificationMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordAfterVerificationMutation, ResetPasswordAfterVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordAfterVerificationMutation, ResetPasswordAfterVerificationMutationVariables>(ResetPasswordAfterVerificationDocument, options);
      }
export type ResetPasswordAfterVerificationMutationHookResult = ReturnType<typeof useResetPasswordAfterVerificationMutation>;
export type ResetPasswordAfterVerificationMutationResult = Apollo.MutationResult<ResetPasswordAfterVerificationMutation>;
export type ResetPasswordAfterVerificationMutationOptions = Apollo.BaseMutationOptions<ResetPasswordAfterVerificationMutation, ResetPasswordAfterVerificationMutationVariables>;
export const CreateNoteDocument = gql`
    mutation CreateNote($input: createNoteInput) {
  createNote(input: $input) {
    _id
    user {
      _id
      firstName
      lastName
    }
    createdBy {
      _id
      firstName
      lastName
    }
    title
    content
    createdAt
    updatedAt
  }
}
    `;
export type CreateNoteMutationFn = Apollo.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, options);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const UpdateNoteDocument = gql`
    mutation UpdateNote($input: updateNoteInput) {
  updateNote(input: $input) {
    _id
    user {
      _id
      firstName
      lastName
    }
    createdBy {
      _id
      firstName
      lastName
    }
    title
    content
    createdAt
    updatedAt
  }
}
    `;
export type UpdateNoteMutationFn = Apollo.MutationFunction<UpdateNoteMutation, UpdateNoteMutationVariables>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNoteMutation, UpdateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(UpdateNoteDocument, options);
      }
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<UpdateNoteMutation, UpdateNoteMutationVariables>;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($packageId: String!, $quantity: Int!) {
  createSubscription(packageId: $packageId, quantity: $quantity) {
    _id
    userId
    packageId {
      _id
      name
      description
      stripeProductId
      stripePriceId
      numberOfSeats
      price
      billingInterval
      features
      permissions
      currency
      createdAt
      updatedAt
    }
    stripeSubscriptionId
    stripeCustomerId
    status
    clientSecret
    currentPeriodStart
    currentPeriodEnd
    canceledAtPeriodEnd
    canceledAt
    createdAt
    updatedAt
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      packageId: // value for 'packageId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: updateUserInput) {
  updateUser(input: $input)
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($input: [InviteUserInput!]!) {
  inviteUser(input: $input)
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const DeleteUserAddedByAdminDocument = gql`
    mutation DeleteUserAddedByAdmin($userId: ID!) {
  deleteUserAddedByAdmin(userId: $userId)
}
    `;
export type DeleteUserAddedByAdminMutationFn = Apollo.MutationFunction<DeleteUserAddedByAdminMutation, DeleteUserAddedByAdminMutationVariables>;

/**
 * __useDeleteUserAddedByAdminMutation__
 *
 * To run a mutation, you first call `useDeleteUserAddedByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAddedByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAddedByAdminMutation, { data, loading, error }] = useDeleteUserAddedByAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserAddedByAdminMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserAddedByAdminMutation, DeleteUserAddedByAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserAddedByAdminMutation, DeleteUserAddedByAdminMutationVariables>(DeleteUserAddedByAdminDocument, options);
      }
export type DeleteUserAddedByAdminMutationHookResult = ReturnType<typeof useDeleteUserAddedByAdminMutation>;
export type DeleteUserAddedByAdminMutationResult = Apollo.MutationResult<DeleteUserAddedByAdminMutation>;
export type DeleteUserAddedByAdminMutationOptions = Apollo.BaseMutationOptions<DeleteUserAddedByAdminMutation, DeleteUserAddedByAdminMutationVariables>;
export const GetNotesDocument = gql`
    query GetNotes($filter: NoteFilter, $search: SearchOperator, $populate: [String], $pagination: Pagination, $sort: NoteSort) {
  getNotes(
    filter: $filter
    search: $search
    populate: $populate
    pagination: $pagination
    sort: $sort
  ) {
    _id
    title
    content
    createdAt
    updatedAt
    createdBy {
      _id
      firstName
      lastName
    }
    user {
      _id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetNotesQuery__
 *
 * To run a query within a React component, call `useGetNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      search: // value for 'search'
 *      populate: // value for 'populate'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetNotesQuery(baseOptions?: Apollo.QueryHookOptions<GetNotesQuery, GetNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotesQuery, GetNotesQueryVariables>(GetNotesDocument, options);
      }
export function useGetNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotesQuery, GetNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotesQuery, GetNotesQueryVariables>(GetNotesDocument, options);
        }
export function useGetNotesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetNotesQuery, GetNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNotesQuery, GetNotesQueryVariables>(GetNotesDocument, options);
        }
export type GetNotesQueryHookResult = ReturnType<typeof useGetNotesQuery>;
export type GetNotesLazyQueryHookResult = ReturnType<typeof useGetNotesLazyQuery>;
export type GetNotesSuspenseQueryHookResult = ReturnType<typeof useGetNotesSuspenseQuery>;
export type GetNotesQueryResult = Apollo.QueryResult<GetNotesQuery, GetNotesQueryVariables>;
export const GetNoteDocument = gql`
    query GetNote($id: ID!, $populate: [String]) {
  getNote(_id: $id, populate: $populate) {
    _id
    title
    content
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetNoteQuery__
 *
 * To run a query within a React component, call `useGetNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *      populate: // value for 'populate'
 *   },
 * });
 */
export function useGetNoteQuery(baseOptions: Apollo.QueryHookOptions<GetNoteQuery, GetNoteQueryVariables> & ({ variables: GetNoteQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNoteQuery, GetNoteQueryVariables>(GetNoteDocument, options);
      }
export function useGetNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNoteQuery, GetNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNoteQuery, GetNoteQueryVariables>(GetNoteDocument, options);
        }
export function useGetNoteSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetNoteQuery, GetNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNoteQuery, GetNoteQueryVariables>(GetNoteDocument, options);
        }
export type GetNoteQueryHookResult = ReturnType<typeof useGetNoteQuery>;
export type GetNoteLazyQueryHookResult = ReturnType<typeof useGetNoteLazyQuery>;
export type GetNoteSuspenseQueryHookResult = ReturnType<typeof useGetNoteSuspenseQuery>;
export type GetNoteQueryResult = Apollo.QueryResult<GetNoteQuery, GetNoteQueryVariables>;
export const GetPackagesDocument = gql`
    query GetPackages($filter: PackageFilter, $search: SearchOperator, $pagination: Pagination, $sort: PackageSort) {
  getPackages(
    filter: $filter
    search: $search
    pagination: $pagination
    sort: $sort
  ) {
    count
    rows {
      _id
      name
      description
      stripeProductId
      stripePriceId
      numberOfSeats
      price
      billingInterval
      features
      permissions
      currency
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useGetPackagesQuery__
 *
 * To run a query within a React component, call `useGetPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackagesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      search: // value for 'search'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetPackagesQuery(baseOptions?: Apollo.QueryHookOptions<GetPackagesQuery, GetPackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPackagesQuery, GetPackagesQueryVariables>(GetPackagesDocument, options);
      }
export function useGetPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPackagesQuery, GetPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPackagesQuery, GetPackagesQueryVariables>(GetPackagesDocument, options);
        }
export function useGetPackagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPackagesQuery, GetPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPackagesQuery, GetPackagesQueryVariables>(GetPackagesDocument, options);
        }
export type GetPackagesQueryHookResult = ReturnType<typeof useGetPackagesQuery>;
export type GetPackagesLazyQueryHookResult = ReturnType<typeof useGetPackagesLazyQuery>;
export type GetPackagesSuspenseQueryHookResult = ReturnType<typeof useGetPackagesSuspenseQuery>;
export type GetPackagesQueryResult = Apollo.QueryResult<GetPackagesQuery, GetPackagesQueryVariables>;
export const GetPackageDocument = gql`
    query GetPackage($filter: PackageFilter) {
  getPackage(filter: $filter) {
    _id
    name
    description
    stripeProductId
    stripePriceId
    numberOfSeats
    price
    billingInterval
    features
    permissions
    currency
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetPackageQuery__
 *
 * To run a query within a React component, call `useGetPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackageQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPackageQuery(baseOptions?: Apollo.QueryHookOptions<GetPackageQuery, GetPackageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPackageQuery, GetPackageQueryVariables>(GetPackageDocument, options);
      }
export function useGetPackageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPackageQuery, GetPackageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPackageQuery, GetPackageQueryVariables>(GetPackageDocument, options);
        }
export function useGetPackageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPackageQuery, GetPackageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPackageQuery, GetPackageQueryVariables>(GetPackageDocument, options);
        }
export type GetPackageQueryHookResult = ReturnType<typeof useGetPackageQuery>;
export type GetPackageLazyQueryHookResult = ReturnType<typeof useGetPackageLazyQuery>;
export type GetPackageSuspenseQueryHookResult = ReturnType<typeof useGetPackageSuspenseQuery>;
export type GetPackageQueryResult = Apollo.QueryResult<GetPackageQuery, GetPackageQueryVariables>;
export const GetSubscriptionDocument = gql`
    query GetSubscription($id: ID, $populate: [String]) {
  getSubscription(_id: $id, populate: $populate) {
    _id
    userId
    packageId {
      _id
      name
      description
      stripeProductId
      stripePriceId
      numberOfSeats
      price
      billingInterval
      features
      permissions
      currency
      createdAt
      updatedAt
    }
    stripeSubscriptionId
    stripeCustomerId
    status
    clientSecret
    currentPeriodStart
    currentPeriodEnd
    canceledAtPeriodEnd
    canceledAt
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      populate: // value for 'populate'
 *   },
 * });
 */
export function useGetSubscriptionQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriptionQuery, GetSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>(GetSubscriptionDocument, options);
      }
export function useGetSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionQuery, GetSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>(GetSubscriptionDocument, options);
        }
export function useGetSubscriptionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSubscriptionQuery, GetSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubscriptionQuery, GetSubscriptionQueryVariables>(GetSubscriptionDocument, options);
        }
export type GetSubscriptionQueryHookResult = ReturnType<typeof useGetSubscriptionQuery>;
export type GetSubscriptionLazyQueryHookResult = ReturnType<typeof useGetSubscriptionLazyQuery>;
export type GetSubscriptionSuspenseQueryHookResult = ReturnType<typeof useGetSubscriptionSuspenseQuery>;
export type GetSubscriptionQueryResult = Apollo.QueryResult<GetSubscriptionQuery, GetSubscriptionQueryVariables>;
export const GetSubscriptionsDocument = gql`
    query GetSubscriptions($filter: SubscriptionFilter, $search: SearchOperator, $pagination: Pagination, $sort: SubscriptionSort) {
  getSubscriptions(
    filter: $filter
    search: $search
    pagination: $pagination
    sort: $sort
  ) {
    _id
    userId
    packageId {
      _id
      name
      description
      stripeProductId
      stripePriceId
      numberOfSeats
      price
      billingInterval
      features
      permissions
      currency
      createdAt
      updatedAt
    }
    stripeSubscriptionId
    stripeCustomerId
    status
    clientSecret
    currentPeriodStart
    currentPeriodEnd
    canceledAtPeriodEnd
    canceledAt
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      search: // value for 'search'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
      }
export function useGetSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
        }
export function useGetSubscriptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
        }
export type GetSubscriptionsQueryHookResult = ReturnType<typeof useGetSubscriptionsQuery>;
export type GetSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsLazyQuery>;
export type GetSubscriptionsSuspenseQueryHookResult = ReturnType<typeof useGetSubscriptionsSuspenseQuery>;
export type GetSubscriptionsQueryResult = Apollo.QueryResult<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    _id
    firstName
    lastName
    email
    phone
    category
    subscription {
      _id
      userId
      packageId {
        _id
        name
        description
        stripeProductId
        stripePriceId
        numberOfSeats
        price
        billingInterval
        features
        permissions
        currency
        createdAt
        updatedAt
      }
      stripeSubscriptionId
      stripeCustomerId
      status
      clientSecret
      currentPeriodStart
      currentPeriodEnd
      canceledAtPeriodEnd
      canceledAt
      createdAt
      updatedAt
    }
    isPremiumUser
    isSubscribed
    progress
    profilePicture
    userMeta {
      authType
      isFirstTimeUser
      invitedBy
      acceptedTermsAndConditions
      receivePromotionalNotifications
    }
    loginMeta {
      lastLoginAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetUsersAddedByAdminDocument = gql`
    query GetUsersAddedByAdmin($adminId: ID!) {
  getUsersAddedByAdmin(adminId: $adminId) {
    _id
    firstName
    lastName
    email
    phone
  }
}
    `;

/**
 * __useGetUsersAddedByAdminQuery__
 *
 * To run a query within a React component, call `useGetUsersAddedByAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersAddedByAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersAddedByAdminQuery({
 *   variables: {
 *      adminId: // value for 'adminId'
 *   },
 * });
 */
export function useGetUsersAddedByAdminQuery(baseOptions: Apollo.QueryHookOptions<GetUsersAddedByAdminQuery, GetUsersAddedByAdminQueryVariables> & ({ variables: GetUsersAddedByAdminQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersAddedByAdminQuery, GetUsersAddedByAdminQueryVariables>(GetUsersAddedByAdminDocument, options);
      }
export function useGetUsersAddedByAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersAddedByAdminQuery, GetUsersAddedByAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersAddedByAdminQuery, GetUsersAddedByAdminQueryVariables>(GetUsersAddedByAdminDocument, options);
        }
export function useGetUsersAddedByAdminSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUsersAddedByAdminQuery, GetUsersAddedByAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersAddedByAdminQuery, GetUsersAddedByAdminQueryVariables>(GetUsersAddedByAdminDocument, options);
        }
export type GetUsersAddedByAdminQueryHookResult = ReturnType<typeof useGetUsersAddedByAdminQuery>;
export type GetUsersAddedByAdminLazyQueryHookResult = ReturnType<typeof useGetUsersAddedByAdminLazyQuery>;
export type GetUsersAddedByAdminSuspenseQueryHookResult = ReturnType<typeof useGetUsersAddedByAdminSuspenseQuery>;
export type GetUsersAddedByAdminQueryResult = Apollo.QueryResult<GetUsersAddedByAdminQuery, GetUsersAddedByAdminQueryVariables>;