import { LocationGenerics } from "@/router/location";
import { useNavigate, useSearch } from "react-location";
import Cartoon from "./cartoon";
import Discussion from "./discussion";
import Application from "./application";
import Activity from "./activity";
import Takeaways from "./takeaways";
import Focus from "./focus";
import { IoChevronBackSharp } from "react-icons/io5";

export default function Detail() {
  const navigate = useNavigate();
  const searchParams = useSearch<LocationGenerics>();

  const school_type = searchParams.search;

  function Content() {
    switch (searchParams.sort) {
      case "cartoons":
        return <Cartoon />;

      case "discussions":
        return <Discussion />;

      case "application":
        return <Application />;

      case "activity":
        return <Activity />;

      case "takeaways":
        return <Takeaways />;

      default:
        return <Focus />;
    }
  }

  const handleNextClick = () => {
    switch (searchParams.sort) {
      case "cartoons":
        navigate({
          to: `/lesson/?id=${searchParams.id}&search=${school_type}&sort=discussions&page=0`,
        });
        break;

      case "discussions":
        navigate({
          to: `/lesson/?id=${searchParams.id}&search=${school_type}&sort=application`,
        });
        break;

      case "application":
        navigate({
          to: `/lesson/?id=${searchParams.id}&search=${school_type}`,
        });
        break;

      default:
        navigate({
          to: `/lesson/?id=${searchParams.id}&search=${school_type}&sort=cartoons`,
        });
    }
  };
  const handlePrevClick = () => {
    switch (searchParams.sort) {
      case "cartoons":
        navigate({
          to: `/lesson/?id=${searchParams.id}&search=${school_type}`,
        });
        break;

      case "discussions":
        navigate({
          to: `/lesson/?id=${searchParams.id}&search=${school_type}&sort=cartoons`,
        });
        break;

      case "application":
        navigate({
          to: `/lesson/?id=${searchParams.id}&search=${school_type}&sort=discussions&page=0`,
        });
        break;

      default:
        navigate({
          to: `/lesson/?id=${searchParams.id}&search=${school_type}`,
        });
    }
  };

  return (
    <div
      // style={{ backgroundImage: `url(${bg})` }}
      className=""
    >
      {Content()}
      {/* footer */}
      {searchParams.sort !== "discussions" && (
        <div className="fixed bottom-0 inset-x-0 w-full h-16 shadow-lg bg-black">
          <div className="w-full h-full max-w-[75%] ml-auto flex justify-between items-center px-10">
            <button
              onClick={handlePrevClick}
              className="w-32 h-12 flex justify-center space-x-2 items-center bg-black border border-white rounded-lg
          text-white"
            >
              <IoChevronBackSharp className="" aria-hidden="true" />
              <span>Previous</span>
            </button>
            <button
              onClick={handleNextClick}
              className="w-32 h-12 flex justify-center items-center bg-white border border-white rounded-lg
          text-black font-poppins"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
