import { FaRegStar } from "react-icons/fa";
import { GoChevronRight } from "react-icons/go";
// import pic from "@/assets/images/disPic.svg";
import { Link, useSearch } from "react-location";
import { LocationGenerics } from "@/router/location";
import Quiz from "./components/quiz";
import { preQuiz, gradeQuiz } from "./components/data";
import { useEffect } from "react";
import { scrollToTop } from "@/utils/scroll-to-top";

export default function Discussion() {
  const searchParams = useSearch<LocationGenerics>();
  const school_type = searchParams.search;

  const quiz = school_type === "pre" ? preQuiz : gradeQuiz;

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <main className="relative font-poppins w-full max-w-4xl px-4 sm:px-6 lg:px-8 pt-5">
      {searchParams.page === 0 ? (
        <>
          <div
            className={`bg-[#F1F5F8] border-[3px] ${
              school_type === "pre" ? "border-pre" : "border-grade"
            } rounded-3xl pt-10 pb-20 pl-10 pr-24`}
          >
            <h2 className="font-medium text-3xl ">Discussions - Quiz</h2>
            <div className="flex items-center gap-2 mt-4">
              <div
                className={`w-10 h-10 rounded-full ${
                  school_type === "pre" ? "bg-pre" : "bg-grade"
                } flex-center`}
              >
                <FaRegStar className="text-white w-6 h-6" aria-hidden="true" />
              </div>
              <div className="flex flex-col gap-1">
                <h5 className="font-medium text-sm">Questions</h5>
                <p className="text-sm text-[#6B7280]">
                  There are {quiz.length} questions to discuss
                </p>
              </div>
            </div>
            <div
              className={`border ${
                school_type === "pre" ? "border-pre" : "border-grade"
              } border-dashed py-6 pl-6 pr-10 mt-4 rounded-xl`}
            >
              <h3 className="font-semibold text-lg ">Objectives</h3>
              <p className="comic-sans text-base mt-2">
                Review these questions that are based on the storyline of the
                episode to ensure children understand what they've just watched.
              </p>
            </div>
          </div>

          <Link
            to={`/lesson/?id=${searchParams.id}&search=${school_type}&sort=discussions&page=1`}
            className={`w-fit border ${
              school_type === "pre" ? " border-pre" : " border-grade"
            } py-2.5 px-8 rounded-xl bg-white text-2xl flex items-center
      gap-2 mt-8`}
          >
            <span className="comic-sans">Start Quiz</span>{" "}
            <GoChevronRight className="" aria-hidden="true" />
          </Link>

          {/* <div className="absolute top-48 right-0">
            <img src={pic} alt="picture cartoon" className="object-contain" />
          </div> */}
        </>
      ) : (
        <div className="pl-20">
          <Quiz />
        </div>
      )}
    </main>
  );
}
