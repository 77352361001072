import spotImg from "@/assets/images/spot.png";
import { useNavigate } from "react-location";

export default function GamesPage() {
  const navigate = useNavigate();
  return (
    <section className="pt-20 pb-10 text-white">
      <div className="w-full max-w-3xl mx-auto flex flex-col items-center pt-10">
        <h1 className="text-center text-4xl mb-2">
          Dreamland Academy <br />
          Games
        </h1>
        <p className="text-2xl text-[#F1F5F8]">
          Play any of our available games specially made for you.
        </p>

        <div className="w-full flex justify-between items-center mt-5 gap-8">
          <div className="flex-1">
            <div className="rounded-xl border-[3px] border-[#F1F5F8] mb-6">
              <img src={spotImg} alt="thumb" className="object-contain" />
            </div>
            <button
              onClick={() =>
                navigate({ to: "/spot-the-difference1", replace: true })
              }
              className="w-full h-14 rounded-lg bg-[#1567EE] flex justify-center items-center font-medium text-white"
            >
              Play spot the difference
            </button>
          </div>
          <div className="flex-1">
            <div className="rounded-xl border-[3px] border-[#F1F5F8] mb-6">
              <img src={spotImg} alt="thumb" className="object-contain" />
            </div>
            <button
              onClick={() =>
                navigate({ to: "/spot-the-difference2", replace: true })
              }
              className="w-full h-14 rounded-lg bg-[#4BA89A] flex justify-center items-center font-medium text-white"
            >
              Play spot the difference
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
