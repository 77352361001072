import group1 from "@/assets/images/group-img1.png";
import pc1 from "@/assets/images/12.png";

import { IoChevronBackSharp } from "react-icons/io5";
import Modal from "./components/modal";
import { useEffect, useState } from "react";
import { scrollToTop } from "@/utils/scroll-to-top";
import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";
import { Navigate } from "react-location";
import { MEMBERSHIP } from "@/constants/path";

export default function MainIntro() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    scrollToTop();
  }, []);

  const user = useCurrentUser();
  const token = useCurrentToken();

  const isLoggedIn = user && token;

  if (!isLoggedIn) return <Navigate to="/login" replace />;
  if (user?.isPremiumUser === false)
    return <Navigate to={MEMBERSHIP} replace />;

  return (
    <main className="font-poppins w-full px-5 min-h-screen flex justify-between">
      <section className=" flex-[2] h-full border-r border-gray-400 pr-10 pt-8 pb-20">
        <h1 className="font-bold text-3xl text-black mb-5">
          Our Current Series{" "}
        </h1>

        <h4 className="font-bold text-2xl text-black ">
          Joseph Dreamer to Governor
        </h4>
        <p className="font-bold text-base text-[#5A5A5A]">
          An Exciting Animated Journey from Dreams to Leadership
        </p>

        <img src={group1} alt="group image" className="mt-2 object-contain" />

        <div className="mt-5">
          <h2 className="font-bold text-2xl text-black ">Brief Overview</h2>
          <p className="text-base text-[#5A5A5A] mt-2">
            Join Joseph on his extraordinary journey from a young dreamer to the
            powerful governor of Egypt. This animated series brings the classic
            story to life with engaging characters and vibrant animations that
            captivate and educate children.
          </p>
        </div>
        <div className="mt-5">
          <h2 className="font-bold text-2xl text-black ">Story Summary</h2>
          <p className="text-base text-[#5A5A5A] mt-2">
            Joseph Dreamer to Governor is an inspiring animated series that
            tells the story of Joseph, a young boy with a special gift for
            interpreting dreams. Despite facing numerous hardships, including
            betrayal by his own brothers and being sold into slavery, Joseph's
            unwavering faith and resilience lead him to a remarkable destiny.
            His ability to understand dreams eventually earns him the trust of
            Pharaoh, the ruler of Egypt, and he rises to the position of
            governor, saving the nation from famine.
          </p>
        </div>
        <div className="mt-5">
          <h2 className="font-bold text-2xl text-black ">
            Learning Objectives
          </h2>
          <p className="text-base text-[#5A5A5A] mt-2">
            Through Joseph's story, children will learn important values such as
            perseverance, forgiveness, and the power of dreams. The series is
            designed to be both entertaining and educational, providing valuable
            life lessons in each episode.
          </p>
        </div>
      </section>
      <section className="section flex-1 h-full pl-5 pb-20">
        <img src={pc1} alt="" className="h-[35rem] w-auto object-contain" />
        <p className="text-base text-[#6A6A6A]">
          In this adventure-filled cartoon series, discover a young teen named
          Joseph, who has dreams as big as the sky. Despite being favored by his
          father, Joseph is ripped away from his family and sold into slavery by
          his jealous brothers. <br />
          <br />
          Joseph - Dreamer to Governor is a rendition of the famous bible story
          of Joseph. It is an adventure-filled 12 episode cartoon series, about
          a young teen named Joseph, who has dreams as big as the sky.
        </p>
      </section>

      {/* footer */}
      <div className="fixed bottom-0 inset-x-0 w-full h-16 shadow-lg bg-white">
        <div className="w-full h-full max-w-screen-2xl mx-auto flex justify-between items-center px-10">
          <button
            className="w-32 h-12 flex justify-center space-x-2 items-center bg-white border border-[#EE1D52] rounded-lg
          text-[#EE1D52]"
          >
            <IoChevronBackSharp className="" aria-hidden="true" />
            <span>Previous</span>
          </button>
          <button
            onClick={() => setOpen(true)}
            className="w-32 h-12 flex justify-center items-center bg-[#EE1D52] border border-[#EE1D52] rounded-lg
          text-white font-poppins"
          >
            Next
          </button>
        </div>
      </div>
      <Modal open={open} setOpen={setOpen} />
    </main>
  );
}
