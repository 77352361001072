/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef, useState, useEffect } from "react";

import { GrBackTen, GrForwardTen } from "react-icons/gr";
import { IoPlayCircle, IoPauseCircleSharp } from "react-icons/io5";
import { FaPlay } from "react-icons/fa";
import tag from "@/assets/images/new.svg";
import { scrollToTop } from "@/utils/scroll-to-top";

export default function Cartoon() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showControls, setShowControls] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [clickPlay, setClickPlay] = useState(true);
  const [watchedTime, setWatchedTime] = useState(0); // State to store how much of the video has been watched
  const [watchedPercentage, setWatchedPercentage] = useState(0); // State to store the percentage watched

  useEffect(() => {
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleTimeUpdate = () => {
      if (videoRef.current) {
        const currentTime = videoRef.current.currentTime;
        const duration = videoRef.current.duration;
        setWatchedTime(currentTime);

        // Calculate the percentage watched
        if (duration > 0) {
          const percentage = (currentTime / duration) * 100;
          setWatchedPercentage(percentage);
        }
      }
    };

    const video = videoRef.current;
    if (video) {
      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);
      video.addEventListener("timeupdate", handleTimeUpdate); // Track time updates
    }

    return () => {
      if (video) {
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
        video.removeEventListener("timeupdate", handleTimeUpdate); // Cleanup on unmount
      }
    };
  }, []);

  // console.log(watchedPercentage);

  useEffect(() => {
    scrollToTop();
  }, []);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  const handleForward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime += 10;
    }
  };

  const handleBackward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime -= 10;
    }
  };

  return (
    <div className="pb-20">
      <div
        className="relative w-full h-full overflow-hidden"
        onMouseEnter={() => setShowControls(true)}
        onMouseLeave={() => setShowControls(false)}
      >
        <video
          ref={videoRef}
          className="w-full sm:h-[34rem] 2xl:h-[38rem] object-cover"
          src={
            "https://dreamland-test.s3.us-west-1.amazonaws.com/videos/joseph-episode-1/Joseph-fullEp.1.mp4"
          }
          controls={!clickPlay}
        />

        {clickPlay && (
          <div className="absolute inset-0 p-8 flex flex-col justify-between">
            <div className="flex-1">
              <img src={tag} alt="new tag" className="" />
            </div>
            <div className="flex-1">
              <p className="font-bold text-xs text-[#DEDEDE] mb-5">Episode 1</p>

              <h4 className="font-bold text-4xl text-white mb-3">
                The coat of many colors
              </h4>
              <p className="text-xs/6 text-[#D4D4D4] w-full max-w-md mb-3">
                Joseph, the youngest son of Jacob, receives a beautiful,
                colorful coat from his father. This special gift, symbolizing
                Jacob's deep love for Joseph, stirs intense jealousy among his
                older brothers. As they plot against him, Joseph's journey from
                beloved son to a figure of remarkable destiny begins.
              </p>

              <button
                onClick={() => {
                  if (videoRef.current) {
                    videoRef.current.play();
                    setClickPlay(false);
                  }
                }}
                className="w-fit bg-white rounded-full py-2.5 px-6 flex items-center gap-2"
              >
                <FaPlay className="w-6 h-6 text-[#111111]" />
                <span className="text-[#111111] ">Watch Now</span>
              </button>
            </div>
          </div>
        )}

        {!clickPlay && showControls && (
          <div className="absolute top-[50%] left-[50%]  transform -translate-x-1/2 -translate-y-1/2 flex gap-8">
            <button className="text-white mx-2" onClick={handleBackward}>
              <GrBackTen className="w-16 h-16" />
            </button>
            <button className="text-white mx-2" onClick={handlePlayPause}>
              {isPlaying ? (
                <IoPauseCircleSharp className="w-16 h-16" />
              ) : (
                <IoPlayCircle className="w-16 h-16" />
              )}
            </button>
            <button className="text-white mx-2" onClick={handleForward}>
              <GrForwardTen className="w-16 h-16" />
            </button>
          </div>
        )}
      </div>
      <div className="mt-4">
        <p className="text-white hidden">
          Watched: {watchedPercentage.toFixed(2)}% {watchedTime}
        </p>
      </div>
    </div>
  );
}
