import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation UpdateUser($input: updateUserInput) {
    updateUser(input: $input)
  }
`;

export const INVITE_USERS = gql`
  mutation InviteUser($input: [InviteUserInput!]!) {
    inviteUser(input: $input)
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUserAddedByAdmin($userId: ID!) {
    deleteUserAddedByAdmin(userId: $userId)
  }
`;
