/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormik } from "formik";
import * as Yup from "yup";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-location";
import { FcGoogle } from "react-icons/fc";
import { MEMBERSHIP, SIGNUP } from "@/constants/path";
import { SIGN_IN_USER, SOCIAL_LOGIN } from "@/apollo/graphql/mutations/auth";
import { useMutation } from "@apollo/client";
import {
  LoginUserMutation,
  LoginUserMutationVariables,
  SocialAuthLoginMutation,
  SocialAuthLoginMutationVariables,
} from "@/apollo/graphql/generated/types";
import ButtonLoader from "@/components/loaders/button";
import toast from "react-hot-toast";
import { setAuth } from "@/apollo/cache/auth";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

export default function SignIn() {
  const [hidePassword, setHidePassword] = useState(true);
  const [user, setUser] = useState<any>(null);

  const navigate = useNavigate();

  const [signin, { loading }] = useMutation<
    LoginUserMutation,
    LoginUserMutationVariables
  >(SIGN_IN_USER);

  const [oauth, { loading: oauthLoading }] = useMutation<
    SocialAuthLoginMutation,
    SocialAuthLoginMutationVariables
  >(SOCIAL_LOGIN);

  const { handleSubmit, ...form }: any = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Email is invalid")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      await signin({
        variables: {
          input: {
            email: values.email,
            password: values.password,
          },
        },
      })
        .then(({ data }) => {
          if (data?.loginUser?.token) {
            const { user, token } = data.loginUser;
            setAuth({
              token,
              user: user,
            });
            if (user?.isPremiumUser) {
              toast(
                JSON.stringify({
                  type: "success",
                  title: `Welcome Back ${user?.firstName}`,
                })
              );
              if (user?.category) {
                navigate({
                  replace: true,
                  to: `/main-introduction/${
                    user?.category === "PRE-SCHOOL"
                      ? "pre-school"
                      : "grade-school"
                  }`,
                });
              } else {
                navigate({ replace: true, to: "/main-introduction" });
              }
            } else {
              navigate({ replace: true, to: MEMBERSHIP });
            }
          }
        })
        .catch((error) => {
          toast(
            JSON.stringify({
              type: "error",
              title: error?.message || "Error while updating Profile",
            })
          );
        });
    },
  });

  const login = useGoogleLogin({
    onSuccess: (codeResponse: any) => {
      setUser(codeResponse);
    },
    onError: (error) => console.error("Login Failed:", error),
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user?.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then(async (res) => {
          await oauth({
            variables: {
              input: {
                acceptedTermsAndConditions: true,
                email: res.data.email,
                firstName: res.data.given_name,
                lastName: res.data.family_name,
                password: res.data.id,
                profilePicture: res.data.picture,
              },
            },
          })
            .then(({ data }) => {
              if (data?.socialAuthLogin?.token) {
                const { user, token } = data.socialAuthLogin;
                setAuth({
                  token,
                  user: user,
                });
                if (user?.isSubscribed) {
                  toast(
                    JSON.stringify({
                      type: "success",
                      title: `Welcome Back ${user?.firstName}`,
                    })
                  );

                  navigate({ replace: true, to: "/main-introduction" });
                } else {
                  navigate({ replace: true, to: MEMBERSHIP });
                }
              }
            })
            .catch((err) => {
              // setSocialAuthLoading(false);
              toast(
                JSON.stringify({
                  type: "error",
                  title:
                    err?.message ||
                    "An error occurred while signing in with Google",
                })
              );
            });
          // navigate({ replace: true, to: HOME });
        })
        .catch((err) => console.log(err));
    }
  }, [user, oauth, navigate]);

  return (
    <section className="bg-white border border-[#66666680] rounded-3xl py-10 mobile:px-5 px-10 w-full max-w-[43.25rem]">
      <h1 className="font-medium text-3xl text-[#333333] text-center">
        Log in
      </h1>

      <div className="mt-5 ">
        <div className="mt-3 ">
          <label htmlFor="email" className="text-[#666666CC]">
            Email Address
          </label>
          <input
            type="email"
            id="email"
            name="email"
            onChange={(e) => form.setFieldValue("email", e.target.value)}
            value={form.values.email}
            placeholder="example@gmail.com"
            className="w-full border border-[#66666659] rounded-lg p-3 mt-2 focus:outline-none focus:border-[#666666]"
          />
          {form.touched.email && form.errors.email && (
            <p className="text-xs mt-2 text-red-500">{form.errors.email}</p>
          )}
        </div>

        <div className="mt-4">
          <label htmlFor="password " className="text-[#666666CC]">
            Password
          </label>
          <div className="flex justify-between items-center border border-[#66666659] rounded-lg p-3 mt-1 focus:outline-none focus:border-[#666666]">
            <input
              type={hidePassword ? "password" : "text"}
              placeholder="Password"
              id="password"
              name="password"
              onChange={(e) => form.setFieldValue("password", e.target.value)}
              value={form.values.password}
              className="w-full outline-none focus:outline-none border-none focus:border-none"
            />
            <div className="flex gap-2">
              {!hidePassword ? (
                <button
                  type="button"
                  className="flex gap-1"
                  onClick={() => setHidePassword(true)}
                >
                  <BiSolidHide
                    className="h-6 w-6 text-[#666666CC]"
                    aria-hidden="true"
                  />
                </button>
              ) : (
                <button
                  type="button"
                  className="flex gap-1"
                  onClick={() => setHidePassword(false)}
                >
                  <BiSolidShow
                    className="h-6 w-6 text-[#666666CC]"
                    aria-hidden="true"
                  />
                </button>
              )}
            </div>
          </div>
          {form.touched.password && form.errors.password && (
            <p className="text-xs mt-2 text-red-500">{form.errors.password}</p>
          )}
        </div>
        <div className="w-full flex justify-between items-center my-5">
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="accept"
              id="accept"
              className="w-4 h-4"
            />{" "}
            <p className="flex-1 text-sm text-[#111111]">Remember me</p>
          </div>

          <div className="">
            <Link to={"/forgot-password"} className="  underline">
              Forget your password
            </Link>
          </div>
        </div>

        <button
          disabled={loading}
          onClick={handleSubmit}
          className="mobile:w-full w-80 h-14 flex gap-2 justify-center items-center rounded-xl bg-gradient-5 
          font-medium text-lg mx-auto border border-[#dbdbdb] mt-5 text-white"
        >
          {loading ? <ButtonLoader title="" /> : "Log in"}
        </button>

        <div className="w-full flex items-center gap-x-2 my-5">
          <div
            style={{ content: "" }}
            className="w-full h-[1px] bg-[#66666640]"
          />
          <p className="text-[#666666] text-base">OR</p>
          <div
            style={{ content: "" }}
            className="w-full h-[1px] bg-[#66666640]"
          />
        </div>

        <button
          type="button"
          onClick={() => login()}
          className="mobile:w-full w-80 h-14 flex gap-2 justify-center items-center rounded-xl bg-white 
          font-medium text-xl mx-auto border border-[#333333]"
        >
          <FcGoogle className="w-6 h-6 text-center" />
          <span className="text-sm text-[#333333]">
            {oauthLoading ? <ButtonLoader title="" /> : "Continue with Google"}
          </span>
        </button>

        <div className="w-full flex justify-center py-5">
          <p className="text-base text-[#111111] text-center">
            Don't have an account?{" "}
            <Link className="underline" to={SIGNUP}>
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
}
