import printImg from "@/assets/images/printImg.png";
import { saveAs } from "file-saver";

type PrintCardProps = {
  path: string;
  title: string;
};

export default function PrintCard({ path, title }: PrintCardProps) {
  const downloadPdf = () => {
    const pdfUrl = path; // Replace with your actual PDF path or URL
    const pdfName = title; // Name for the downloaded PDF
    saveAs(pdfUrl, pdfName);
  };

  return (
    <div className="w-60 bg-[#F1F5F8] border border-[#D4D4D4] rounded-2xl overflow-hidden">
      <div className="py-2 bg-white">
        <img src={printImg} alt="print image" className="object-contain" />
      </div>

      <div className="border-t border-[#D4D4D4] p-4">
        <p className="text-sm text-black ">{title}</p>
        <button
          onClick={downloadPdf}
          className="w-full h-8 flex justify-center items-center hover:bg-blue-700 transition-all duration-300 ease-in-out
      text-xs/8 text-white bg-[#1567EE] border border-[#1567EE] rounded-md mt-4"
        >
          Download
        </button>
      </div>
    </div>
  );
}
