/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar, useReactiveVar } from "@apollo/client";
import Cookies from "js-cookie";
import config from "@/config";
import { LocationGenerics } from "@/router/location";
import { useMatch, useNavigate, useSearch } from "react-location";
// import { JobSeeker_jobSeeker } from '../queries/__generated__/JobSeeker';

export const isLoggedInVar = makeVar<boolean>(
  !!Cookies.get(`application:auth:token`)
);

export const currentTokenVar = makeVar<string | null>(
  Cookies.get(`application:auth:token`) || null
);
// export const currentUserVar = makeVar<JobSeeker_jobSeeker>(
export const currentUserVar = makeVar<any>(
  JSON.parse(Cookies.get(`application:auth:user`) ?? "{}") ?? null
);

export const useCurrentUser: any = () => useReactiveVar(currentUserVar);
export const useCurrentToken = () => useReactiveVar(currentTokenVar);
export const useIsLoggedIn = () => useReactiveVar(isLoggedInVar);
export const useAppNaviagte = () => useNavigate<LocationGenerics>();
export const useAppSearch = () => useSearch<LocationGenerics>();
export const useAppURL = () => useMatch<LocationGenerics>();

// export const setAuth = ({ user, token }: { user: JobSeeker_jobSeeker; token: string }) => {
export const setAuth = ({ user, token }: { user: any; token: string }) => {
  currentUserVar(user);
  Cookies.set(`application:auth:user`, JSON.stringify(user), {
    ...config.cookies,
    expires: 1,
  });
  isLoggedInVar(true);
  currentTokenVar(token);
  Cookies.set(`application:auth:token`, token, {
    ...config.cookies,
    expires: 1,
  });
};

// export const setMe = (user: JobSeeker_jobSeeker) => {
export const setMe = (user: any) => {
  currentUserVar(user);
  Cookies.set(`application:auth:user`, JSON.stringify(user), {
    ...config.cookies,
    expires: 1,
  });
};

export const setToken = (token: string) => {
  currentTokenVar(token);
  Cookies.set(`application:auth:token`, token, {
    ...config.cookies,
    expires: 1,
  });
};

export const clearAuth =  () => {
  isLoggedInVar(false);
  currentTokenVar(null);
  // TODO: handle extra checks for better user experience
  Object.keys(Cookies.get()).forEach((key) => {
    Cookies.remove(key, { ...config.cookies });
  });
};
