import { useEffect } from "react";
import SchoolLayout from "../introduction/components/school-layout";
import { scrollToTop } from "@/utils/scroll-to-top";
import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";
import { Navigate } from "react-location";
import { MEMBERSHIP } from "@/constants/path";

export default function PreSchool() {
  useEffect(() => {
    scrollToTop();
  }, []);

  const user = useCurrentUser();
  const token = useCurrentToken();

  const isLoggedIn = user && token;

  if (!isLoggedIn) return <Navigate to="/login" replace />;
  if (user?.isPremiumUser === false)
    return <Navigate to={MEMBERSHIP} replace />;
  return (
    <div className="">
      <SchoolLayout name="Pre - School" type="pre" />
    </div>
  );
}
