/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { LocationGenerics } from "@/router/location";
import { useNavigate, useSearch } from "react-location";
import { motion } from "framer-motion";
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import { useEffect, useState } from "react";
import { LuCheck } from "react-icons/lu";
import { HiArrowRight } from "react-icons/hi2";
import { useMutation, useQuery } from "@apollo/client";
import {
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables,
  GetPackageQuery,
  GetPackageQueryVariables,
} from "@/apollo/graphql/generated/types";
import { CREATE_SUB } from "@/apollo/graphql/mutations/packages";
import { scrollToTop } from "@/utils/scroll-to-top";
import { GET_PACKAGE } from "@/apollo/graphql/queries/packages";

export default function MembershipDetail() {
  useEffect(() => {
    scrollToTop();
  }, []);

  const navigate = useNavigate();
  const searchParams = useSearch<LocationGenerics>();
  const [numbOfSeats, setNumbOfSeats] = useState(1);
  const [seatError, setSeatError] = useState("");
  const [total, setTotal] = useState(0);

  const [selectedOption, setSelectedOption] = useState("monthly");

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const { data } = useQuery<GetPackageQuery, GetPackageQueryVariables>(
    GET_PACKAGE,
    {
      variables: {
        filter: {
          _id: {
            eq: searchParams?.id,
          },
        },
      },
    }
  );

  const packageData = data?.getPackage;

  const [createSubscription, { loading: subscribing }] = useMutation<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >(CREATE_SUB);

  const handleSubscription = async (packageId: string) => {
    if (numbOfSeats < 1) {
      setSeatError("Number of seats must be greater than 0");
      return;
    }
    if (numbOfSeats > (packageData?.numberOfSeats ?? 0)) {
      setSeatError(`You have reached the number of seats you can add`);
      return;
    }
    const data = await createSubscription({
      variables: {
        packageId: packageId,
        quantity: numbOfSeats,
      },
    }).then((r) => r.data);

    navigate({
      to: `/membership-payment/?id=${data?.createSubscription?.stripeSubscriptionId}&sort=${data?.createSubscription?.clientSecret}`,
    });
  };

  useEffect(() => {
    if (numbOfSeats < 1) {
      setSeatError("Number of seats must be greater than 0");
    } else if (numbOfSeats > (packageData?.numberOfSeats ?? 0)) {
      setSeatError(`You have reached the number of seats you can add`);
    } else {
      setSeatError("");
    }
  }, [numbOfSeats, packageData]);

  useEffect(() => {
    setTotal((packageData?.price ?? 0) * numbOfSeats);
  }, [packageData, numbOfSeats]);

  return (
    <main className="font-inter pt-20 text-white h-screen">
      <style>
        {`
          /* For Chrome, Safari, Edge, and Opera */
          input[type="number"]::-webkit-outer-spin-button,
          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* For Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }
        `}
      </style>
      <div className="w-full border-b border-[#606060] p-1">
        <div className="w-full max-w-7xl mx-auto flex justify-between items-center">
          <button
            onClick={() => navigate({ to: "/membership" })}
            className="py-5"
          >
            Cancel
          </button>
          {seatError && (
            <motion.div
              initial={{ y: -100, opacity: 0 }} // Slide from above
              animate={{ y: 0, opacity: 1 }} // Final position
              exit={{ opacity: 0, rotateX: 90, scale: 0.5, y: -100 }} // Fold-away effect
              transition={{ duration: 0.5, ease: "easeInOut" }} // Timing for both animations
              className="flex items-center gap-4 text-sm text-white bg-[#FF9500] rounded-full py-2 px-5"
            >
              {seatError}
              {seatError && (
                <button
                  onClick={() => navigate({ to: "/membership" })}
                  className="text-black bg-white py-2 px-4 rounded-full"
                >
                  Upgrade
                </button>
              )}
            </motion.div>
          )}
          <div className=""></div>
        </div>
      </div>

      <div className="w-full  flex justify-between h-full ">
        <div className="w-[45%] border-r border-[#606060] h-full pt-5 pl-20">
          <h3 className="text-2xl ">Number of seats</h3>
          <p className="text-[#606060]">Enter the number of seats</p>

          <div className="flex items-center gap-5 mt-8">
            <div className="w-24 h-11 rounded-md border border-white flex flex-center">
              <input
                type="number"
                value={numbOfSeats}
                onChange={(e) => setNumbOfSeats(parseInt(e.target.value))}
                className="w-full h-full bg-transparent text-center border-none outline-none focus:outline-none focus:border-none"
              />
            </div>
            <div className="space-y-1">
              <button
                onClick={() => setNumbOfSeats(numbOfSeats + 1)}
                className="w-6 h-6 bg-white flex flex-center rounded-md cursor-pointer"
              >
                <MdOutlineKeyboardArrowUp
                  className="w-5 h-5 text-black"
                  aria-hidden="true"
                />
              </button>
              <button
                onClick={() =>
                  setNumbOfSeats(numbOfSeats > 1 ? numbOfSeats - 1 : 1)
                }
                className="w-6 h-6 bg-white flex flex-center rounded-md cursor-pointer"
              >
                <MdOutlineKeyboardArrowDown
                  className="w-5 h-5 text-black"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>

          <div className="mt-8">
            <p className="text-sm mb-4">Features</p>
            <div className="">
              {packageData?.features?.map((feature: any, index: number) => (
                <div key={index} className="flex items-center gap-2 mb-5">
                  <div className="w-6 h-6 flex justify-center items-center bg-[#606060] rounded-full">
                    <LuCheck className="text-white h-4 w-4" />
                  </div>
                  <div className="flex-1 font-poppins text-lg text-white">
                    {feature}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex-1 h-full">
          <div className="w-full py-5 border-b border-[#606060]">
            <div className="w-full flex justify-between items-center pr-20 pl-14">
              <h4 className="text-2xl ">{packageData?.name ?? "N/A"}</h4>
              <p className="">{packageData?.numberOfSeats ?? "0"}</p>
            </div>
          </div>
          <div className="w-full py-5 border-b border-[#606060]">
            <div className="w-full pr-20 pl-14">
              <h4 className="text-2xl mb-4">Billing cycle</h4>
              <div className="flex items-center gap-14">
                <label className="text-base flex items-center">
                  <input
                    type="radio"
                    name="plan"
                    value="monthly"
                    checked={selectedOption === "monthly"}
                    onChange={handleChange}
                    className="mr-2 w-4 h-4 "
                  />
                  Monthly
                </label>

                <label className="text-base flex items-center">
                  <input
                    type="radio"
                    name="plan"
                    value="yearly"
                    checked={selectedOption === "yearly"}
                    onChange={handleChange}
                    className="mr-2 w-4 h-4"
                  />
                  Yearly{" "}
                  <span className="text-[#6557FF] ml-1"> (save up to 40%)</span>
                </label>
              </div>
            </div>
          </div>
          <div className="w-full py-5 border-b border-[#606060]">
            <div className="w-full pr-20 pl-14 ">
              <p className="text-xl mb-2">1 seat</p>
              <p className="text-xl mb-2">$ {packageData?.price ?? "N/A"}</p>
              <p className="text-xl text-[#606060]">
                $ <span>{packageData?.price ?? "N/A"}</span> X{" "}
                <span>{numbOfSeats}</span>
                {numbOfSeats > 1 ? " seats" : " seat"} X 1{" "}
                {packageData?.billingInterval ?? "N/A"}
              </p>
            </div>
          </div>
          <div className="w-full py-5 ">
            <div className="w-full pr-20 pl-14 flex justify-between items-center">
              <h5 className="text-xl ">Subtotal</h5>
              <p className="text-xl mb-2">$ {total}</p>
            </div>
            <div className="w-full pr-20 pl-14 ">
              <button
                disabled={subscribing}
                onClick={() => handleSubscription(packageData?._id ?? "")}
                className="w-full bg-gradient-5 font-poppins font-semibold text-lg text-center text-white flex
                justify-between items-center gap-4 px-5 py-3 rounded-lg my-8 "
              >
                <span>Continue to check out</span>
                <HiArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
