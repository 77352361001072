/* eslint-disable no-constant-condition */
// import { Link, Navigate, Outlet, useLocation } from "react-location";
import { Link, Navigate, Outlet, useLocation, useSearch } from "react-location";

import { useEffect, useState } from "react";
import {
  Dialog,
  // DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Navbar from "../unauth/components/nav";
import { RiCheckLine } from "react-icons/ri";

import { BiMoviePlay } from "react-icons/bi";
import { LuFileSpreadsheet } from "react-icons/lu";

import { LocationGenerics } from "@/router/location";
import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";
import Breadcrumbs from "./components/breadcrumbs";
import preBg from "@/assets/images/preBg.png";
import gradeBg from "@/assets/images/gradeBg.png";
import { scrollToTop } from "@/utils/scroll-to-top";
import { MEMBERSHIP } from "@/constants/path";

export default function LessonLayout() {
  const user = useCurrentUser();
  const token = useCurrentToken();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const searchParams = useSearch<LocationGenerics>();
  const location = useLocation().current.pathname;

  const id = searchParams.id;
  const school_type = user?.category === "PRE-SCHOOL" ? "pre" : "grade";

  const isLoggedIn = user && token;

  useEffect(() => {
    scrollToTop();
  }, []);
  //
  if (!isLoggedIn) return <Navigate to="/login" replace />;
  if (user?.isPremiumUser === false)
    return <Navigate to={MEMBERSHIP} replace />;

  return (
    <>
      <div className="w-full max-w-screen-2xl mx-auto select-none">
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-50 lg:hidden"
        >
          <div
            // transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              // transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                    />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  <img
                    alt="Your Company"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    className="h-8 w-auto"
                  />
                </div>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}

        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-80 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <Navbar />
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-black text-white pl-14 pr-6 pb-4">
            <nav className="flex flex-1 flex-col mt-24">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 flex flex-col gap-y-7">
                    <h2 className="font-poppins font-semibold text-base">
                      Joseph Dreamer to Governor
                    </h2>

                    <h5 className="font-poppins font-semibold text-sm ">
                      Lesson 1
                    </h5>
                    <Link
                      to={`lesson/?id=${id}&search=${school_type}`}
                      className=" flex gap-3 items-start cursor-pointer "
                    >
                      <div
                        className={`w-6 h-6 flex justify-center items-center ${
                          school_type === "pre" ? "bg-pre" : "bg-grade"
                        } rounded-full`}
                      >
                        <RiCheckLine className="text-white h-4 w-4" />
                      </div>
                      <div className="">
                        <div
                          className={`flex items-center gap-1.5 ${
                            school_type === "pre" ? "text-pre" : "text-grade"
                          } mb-1`}
                        >
                          {/* <BiMoviePlay className="w-5 h-6" /> */}
                          <LuFileSpreadsheet className="w-5 h-6" />
                          <p className="text-sm">Focus & Series Summary</p>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-[#989595]">
                          <span>Reading</span>
                          <span className="w-1.5 h-1.5 rounded-full bg-[#989595] "></span>
                          <span>4 mins</span>
                        </div>
                      </div>
                    </Link>
                    <Link
                      to={`lesson/?id=${id}&search=${school_type}&sort=cartoons`}
                      className=" flex gap-3 items-start cursor-pointer "
                    >
                      <div
                        className={`w-6 h-6 flex justify-center items-center ${
                          searchParams.sort === "cartoons" ||
                          searchParams.sort === "discussions" ||
                          searchParams.sort === "application" ||
                          searchParams.sort === "activity" ||
                          searchParams.sort === "takeaways"
                            ? `${
                                school_type === "pre"
                                  ? "bg-pre text-white"
                                  : "bg-grade text-white"
                              }`
                            : " bg-white text-black"
                        }   rounded-full`}
                      >
                        <RiCheckLine className=" h-4 w-4" />
                      </div>
                      <div className="">
                        <div
                          className={`flex items-center gap-1.5  ${
                            searchParams.sort === "cartoons" ||
                            searchParams.sort === "discussions" ||
                            searchParams.sort === "application" ||
                            searchParams.sort === "activity" ||
                            searchParams.sort === "takeaways"
                              ? `${
                                  school_type === "pre"
                                    ? "text-pre"
                                    : "text-grade"
                                }`
                              : "text-white"
                          } mb-1`}
                        >
                          <BiMoviePlay className="w-5 h-6" />

                          <p className="text-sm">Episode 1 - Dreams</p>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-[#989595]">
                          <span>Video</span>
                          <span className="w-1.5 h-1.5 rounded-full bg-[#989595] "></span>
                          <span>4 mins</span>
                        </div>
                      </div>
                    </Link>
                    <Link
                      to={`lesson/?id=${id}&search=${school_type}&sort=discussions&page=0`}
                      className="group flex gap-3 items-start cursor-pointer "
                    >
                      <div
                        className={`w-6 h-6 flex justify-center items-center  ${
                          searchParams.sort === "discussions" ||
                          searchParams.sort === "application" ||
                          searchParams.sort === "activity" ||
                          searchParams.sort === "takeaways"
                            ? `${
                                school_type === "pre"
                                  ? "bg-pre text-white"
                                  : "bg-grade text-white"
                              }`
                            : "text-black bg-white"
                        } rounded-full`}
                      >
                        <RiCheckLine className=" h-4 w-4" />
                      </div>
                      <div className="">
                        <div
                          className={`flex items-center gap-1.5  ${
                            searchParams.sort === "discussions" ||
                            searchParams.sort === "application" ||
                            searchParams.sort === "activity" ||
                            searchParams.sort === "takeaways"
                              ? `${
                                  school_type === "pre"
                                    ? "text-pre"
                                    : "text-grade"
                                }`
                              : "text-white"
                          }  mb-1`}
                        >
                          <LuFileSpreadsheet className="w-5 h-6" />
                          <p className="text-sm">Discussions</p>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-[#989595]">
                          <span>Quiz</span>
                          <span className="w-1.5 h-1.5 rounded-full bg-[#989595] "></span>
                          <span>10 mins</span>
                        </div>
                      </div>
                    </Link>
                    <Link
                      to={`lesson/?id=${id}&search=${school_type}&sort=application`}
                      className=" flex gap-3 items-start cursor-pointer "
                    >
                      <div
                        className={`w-6 h-6 flex justify-center items-center ${
                          searchParams.sort === "application" ||
                          searchParams.sort === "activity" ||
                          searchParams.sort === "takeaways"
                            ? `${
                                school_type === "pre"
                                  ? "bg-pre text-white"
                                  : "bg-grade text-white"
                              }`
                            : "text-black bg-white"
                        } rounded-full`}
                      >
                        <RiCheckLine className=" h-4 w-4" />
                      </div>
                      <div className="">
                        <div
                          className={`flex items-center gap-1.5 ${
                            searchParams.sort === "application" ||
                            searchParams.sort === "activity" ||
                            searchParams.sort === "takeaways"
                              ? `${
                                  school_type === "pre"
                                    ? "text-pre"
                                    : "text-grade"
                                }`
                              : "text-white"
                          } mb-1`}
                        >
                          <BiMoviePlay className="w-5 h-6" />

                          <p className="text-sm">Application</p>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-[#989595]">
                          <span>Video</span>
                          <span className="w-1.5 h-1.5 rounded-full bg-[#989595] "></span>
                          <span>4 mins</span>
                        </div>
                      </div>
                    </Link>
                    {/* <Link
                      to={`lesson/?id=${id}&sort=song-game`}
                      className=" flex gap-3 items-start cursor-pointer "
                    >
                      <div className="w-6 h-6 flex justify-center items-center bg-[#1567EE] rounded-full">
                        <RiCheckLine className="text-white h-4 w-4" />
                      </div>
                      <div className="">
                        <div
                          className={`flex items-center gap-1.5 text-[#1567EE] mb-1`}
                        >
                          <BiMoviePlay className="w-5 h-6" />

                          <p className="text-sm">Song | Game</p>
                        </div>
                        <div className="flex items-center gap-2 text-sm text-[#989595]">
                          <span>Video</span>
                          <span className="w-1.5 h-1.5 rounded-full bg-[#989595] "></span>
                          <span>4 mins</span>
                        </div>
                      </div>
                    </Link> */}
                  </ul>
                </li>

                <li className="w-full mt-auto border-t border-gray-200 flex flex-col gap-y-3 pt-3">
                  <h5 className="font-poppins font-medium text-sm">
                    Downloads | Print
                  </h5>
                  <Link
                    to={`lesson/?id=${id}&search=${school_type}&sort=activity`}
                    className={`flex items-center gap-1.5 cursor-pointer ${
                      searchParams.sort === "activity" ||
                      searchParams.sort === "takeaways"
                        ? `${school_type === "pre" ? "text-pre" : "text-grade"}`
                        : "text-white"
                    }   mb-1`}
                  >
                    <LuFileSpreadsheet className="w-5 h-6" />
                    <p className="text-sm">Printable Activity</p>
                  </Link>
                  <Link
                    to={`lesson/?id=${id}&search=${school_type}&sort=takeaways`}
                    className={`flex items-center gap-1.5 cursor-pointer ${
                      searchParams.sort === "takeaways"
                        ? `${school_type === "pre" ? "text-pre" : "text-grade"}`
                        : "text-white"
                    }  mb-1`}
                  >
                    <LuFileSpreadsheet className="w-5 h-6" />
                    <p className="text-sm">Key Takeaways</p>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div
          className={`lg:pl-80 bg-cover bg-center min-h-screen`}
          style={{
            backgroundImage: `url(${
              school_type === "pre"
                ? preBg
                : school_type === "grade"
                ? gradeBg
                : ""
            })`,
          }}
        >
          {location !== "/notes" && (
            <div
              className={`sticky top-[4.8rem] z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 ${
                school_type === "pre" ? "bg-pre" : "bg-grade"
              } bg-opacity-75  px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8`}
            >
              <div className="flex items-center flex-1 gap-x-4 self-stretch lg:gap-x-6">
                <Breadcrumbs />
              </div>
            </div>
          )}

          <main
            className={`pt-20 min-h-screen bg-opacity-75 ${
              school_type === "pre"
                ? "bg-pre"
                : school_type === "grade"
                ? "bg-grade"
                : ""
            }`}
          >
            <div
              className={`h-full  ${
                school_type === "pre"
                  ? "text-pre"
                  : school_type === "grade"
                  ? "text-grade"
                  : ""
              } `}
            >
              {/* Your content */}
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
