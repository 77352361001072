import { useState } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";

const faqs = [
  {
    number: "01",
    question: "What is Dreamland Animation LLC?",
    answer:
      "Dreamland Animation LLC is a startup animation production company focused on creating innovative and engaging cartoons inspired by stories from the Bible. Founded by Samantha Quaatey in 2022, the company aims to spread the message of Christianity in a fun and relatable way for young audiences.",
  },
  {
    number: "02",
    question: "What kind of content does Dreamland Animation produce?",
    answer:
      "Dreamland Animation creates cartoons that are inspired by stories from the Bible, designed to be both educational and entertaining for children. Our flagship series includes engaging stories like that of young Joseph and his journey in Egypt.",
  },
  {
    number: "03",
    question: "Who is the target audience for Dreamland Animation's cartoons?",
    answer:
      "Our cartoons are designed for children of all ages, both within and outside of the church. They are perfect for parents, churches, and Christian schools looking to provide children with inspiring and faith-based content.",
  },
  {
    number: "04",
    question: "How do your cartoons incorporate Biblical teachings?",
    answer:
      "Our cartoons use storytelling to bring Biblical narratives to life, highlighting key lessons and Christian values. Each episode is crafted to be relatable and engaging while remaining true to scriptural teachings.",
  },
  {
    number: "05",
    question: "What is Dreamland Academy?",
    answer:
      "Dreamland Academy is our educational resource hub that complements our cartoon series. It offers lesson plans and activities designed to help children dive deeper into Biblical teachings and support their spiritual development.",
  },
  {
    number: "06",
    question: "How can Dreamland Academy be used?",
    answer:
      "Dreamland Academy provides resources for parents, churches, and Christian schools to enhance the viewing experience of our cartoons with educational materials that reinforce Christian values and teachings.",
  },
  {
    number: "07",
    question: "Where can I watch Dreamland Animation's cartoons?",
    answer:
      "Our cartoons will be available on our website and through various digital platforms. Please stay tuned for updates on specific release dates and streaming options.",
  },
  {
    number: "08",
    question:
      "How can I stay updated on new releases and content from Dreamland Animation?",
    answer:
      "You can subscribe to our newsletter, follow us on social media, or regularly visit our website for updates on new episodes, resources, and events.",
  },
  {
    number: "09",
    question:
      "Can we partner with Dreamland Animation for educational purposes?",
    answer:
      "Yes, we welcome partnerships with churches, schools, and other educational institutions. Please contact us through our website for more information on collaboration opportunities.",
  },
  {
    number: "10",
    question: "How can I support Dreamland Animation?",
    answer:
      "You can support us by sharing our content with your community, providing feedback, and considering donations to help us continue producing high-quality, faith-based content.",
  },
  {
    number: "11",
    question: "How can I contact Dreamland Animation? ",
    answer:
      "You can reach us via our contact page on the website or email us at support@dreamlandanimation.com for inquiries, feedback, or support.",
  },
];

export default function Faq() {
  const [openIndex, setOpenIndex] = useState<number>(0);

  const handleToggle = (index: number) => {
    setOpenIndex(index === openIndex ? -1 : index);
  };

  return (
    <div className="font-poppins w-full mobile:px-5 mt-10 pb-20 mb-20">
      {faqs.map((faq, index) => (
        <div key={faq.number} className="mt-10">
          <div
            className={`${
              openIndex === index ? "border-2" : "border-none"
            }  border-[#3E3C3C] rounded-lg`}
          >
            <div
              className={`flex justify-between items-start pl-6 gap-6 bg-black rounded-lg border-[#3E3C3C] ${
                openIndex === index ? "border-none" : "border-2"
              }`}
            >
              <div className={``}>
                <h4 className="text-[#F7F7F7] mobile:text-sm text-2xl pt-6">
                  {faq.number}
                </h4>
              </div>
              <div className="flex-1">
                <div className="flex justify-between bg-black">
                  <h4 className="text-[#F7F7F7] mobile:text-base text-xl py-6">
                    {faq.question}
                  </h4>
                  <button
                    onClick={() => handleToggle(index)}
                    className={`w-fit mobile:py-0 mobile:px-6 py-6 px-8 ${
                      openIndex === index
                        ? "bg-[#585858] border-[#3E3C3C]"
                        : "bg-[#111111] border-[#111111]"
                    } border-2`}
                  >
                    {openIndex === index ? (
                      <FiMinus
                        className="mobile:w-3 mobile:h-3 h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    ) : (
                      <FiPlus
                        className="mobile:w-3 mobile:h-3 h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
                <div
                  className={`transition-all duration-300 overflow-hidden ${
                    openIndex === index
                      ? "max-h-screen opacity-100"
                      : "max-h-0 opacity-0"
                  }`}
                >
                  <div className="pr-6 pb-6 pt-4">
                    <p className="font-light text-[#F7F7F7] mobile:text-sm text-xl">
                      {faq.answer}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
