/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
// import PrintCard from "./components/print-card";
import { scrollToTop } from "@/utils/scroll-to-top";
import { useSearch } from "react-location";
import { LocationGenerics } from "@/router/location";
import pFile1 from "@/assets/Pre-school files/Lesson 1 Key Takeaways (Preschool).pdf";
import PrintCard from "./components/print-card";

const gradeData: any = [];
const preData = [
  {
    path: pFile1,
    title: "Lesson 1 Key Takeaways (Preschool).pdf",
  },
];

export default function Takeaways() {
  const searchParams = useSearch<LocationGenerics>();

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <main className="font-poppins px-4 sm:px-6 lg:px-8 pt-5">
      <h1 className="font-medium text-2xl text-white border-b border-gray-200 pb-2.5">
        Key Takeaways
      </h1>

      <div className="flex items-center gap-5 mt-6">
        {searchParams.search === "pre"
          ? preData.map((data, index) => (
              <PrintCard key={index} path={data.path} title={data.title} />
            ))
          : gradeData.map((data: any, index: number) => (
              <PrintCard key={index} path={data.path} title={data.title} />
            ))}
      </div>
    </main>
  );
}
