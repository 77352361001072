import { gql } from "@apollo/client";

export const SIGN_UP_USER = gql`
  mutation RegisterUser($input: createUserInput) {
    registerUser(input: $input) {
      token
      user {
        _id
        firstName
        lastName
        email
        phone
        category
        subscription {
          _id
          userId
          packageId {
            _id
            name
            description
            stripeProductId
            stripePriceId
            numberOfSeats
            price
            billingInterval
            features
            permissions
            currency
            createdAt
            updatedAt
          }
          stripeSubscriptionId
          stripeCustomerId
          status
          clientSecret
          currentPeriodStart
          currentPeriodEnd
          canceledAtPeriodEnd
          canceledAt
          createdAt
          updatedAt
        }
        isPremiumUser
        isSubscribed
        progress
        profilePicture
        userMeta {
          authType
          isFirstTimeUser
          invitedBy
          acceptedTermsAndConditions
          receivePromotionalNotifications
        }
        loginMeta {
          lastLoginAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SIGN_IN_USER = gql`
  mutation LoginUser($input: loginUserInput) {
    loginUser(input: $input) {
      token
      user {
        _id
        firstName
        lastName
        email
        phone
        category
        subscription {
          _id
          userId
          packageId {
            _id
            name
            description
            stripeProductId
            stripePriceId
            numberOfSeats
            price
            billingInterval
            features
            permissions
            currency
            createdAt
            updatedAt
          }
          stripeSubscriptionId
          stripeCustomerId
          status
          clientSecret
          currentPeriodStart
          currentPeriodEnd
          canceledAtPeriodEnd
          canceledAt
          createdAt
          updatedAt
        }
        isPremiumUser
        isSubscribed
        progress
        profilePicture
        userMeta {
          authType
          isFirstTimeUser
          invitedBy
          acceptedTermsAndConditions
          receivePromotionalNotifications
        }
        loginMeta {
          lastLoginAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SOCIAL_LOGIN = gql`
  mutation SocialAuthLogin($input: SocialAuthInput!) {
    socialAuthLogin(input: $input) {
      token
      user {
        _id
        firstName
        lastName
        email
        phone
        category
        subscription {
          _id
          userId
          packageId {
            _id
            name
            description
            stripeProductId
            stripePriceId
            numberOfSeats
            price
            billingInterval
            features
            permissions
            currency
            createdAt
            updatedAt
          }
          stripeSubscriptionId
          stripeCustomerId
          status
          clientSecret
          currentPeriodStart
          currentPeriodEnd
          canceledAtPeriodEnd
          canceledAt
          createdAt
          updatedAt
        }
        isPremiumUser
        isSubscribed
        progress
        profilePicture
        userMeta {
          authType
          isFirstTimeUser
          invitedBy
          acceptedTermsAndConditions
          receivePromotionalNotifications
        }
        loginMeta {
          lastLoginAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ResetPasswordAuthenticated(
    $input: AuthenticatedResetPasswordInput!
  ) {
    resetPasswordAuthenticated(input: $input)
  }
`;

export const VERIFY_CODE = gql`
  mutation VerifyCode($code: String!, $user: ID!) {
    verifyCode(code: $code, user: $user) {
      status
      message
      token
    }
  }
`;

export const FIND_USER_BY_EMAIL = gql`
  mutation FindUserByEmail($email: String!) {
    findUserByEmail(email: $email) {
      status
      message
      user {
        _id
        email
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPasswordAfterVerification(
    $password: String!
    $confirmedPassword: String!
  ) {
    resetPasswordAfterVerification(
      password: $password
      confirmedPassword: $confirmedPassword
    )
  }
`;
