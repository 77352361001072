/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  //   ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Screenshots from "./screenshots";
import { useState } from "react";
import { CONTACT_SUPPORT } from "@/apollo/graphql/mutations/admin";
import { useMutation } from "@apollo/client";
import {
  ContactTechnicalSupportMutation,
  ContactTechnicalSupportMutationVariables,
} from "@/apollo/graphql/generated/types";
import ButtonLoader from "@/components/loaders/button";

interface ModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function SupportModal({ open, setOpen }: ModalProps) {
  const [shots, setShots] = useState<string[]>([]);
  interface FormValues {
    name: string;
    email: string;
    comment: string;
  }

  const [contactSupport, { loading }] = useMutation<
    ContactTechnicalSupportMutation,
    ContactTechnicalSupportMutationVariables
  >(CONTACT_SUPPORT);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      comment: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      comment: Yup.string()
        .required("Required")
        .min(5, "Minimum 10 characters"),
    }),
    onSubmit: async (values: FormValues) => {
      // console.log(values, shots);
      await contactSupport({
        variables: {
          input: {
            fullName: values.name,
            email: values.email,
            message: values.comment,
            attachments: shots,
          },
        },
      })
        .then(({ data }) => {
          if (data?.contactTechnicalSupport) {
            toast(
              JSON.stringify({
                type: "success",
                title: "Message sent successfully",
              })
            );
            formik.resetForm();
            setShots([]);
            setOpen(false);
          }
        })
        .catch((err: any) => {
          console.error("Error:", err);
          toast(
            JSON.stringify({
              type: "error",
              title: "An error occurred",
            })
          );
        });
    },
  });

  return (
    <Dialog
      open={open}
      onClose={setOpen}
      className="font-poppins relative z-50"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-[#111111D9] bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-x-hidden rounded-2xl bg-white px-4 pb-6 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg max-h-[90vh] sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="">
              <div className="mt-3 text-center  sm:mt-0 sm:text-left">
                <DialogTitle
                  as="h3"
                  className="text-2xl font-medium text-black"
                >
                  Contact Tech Support
                </DialogTitle>
              </div>

              <form className="w-full">
                <div className=" mt-5">
                  <label htmlFor="name" className="text-base text-[#666666]">
                    Full name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="w-full mt-1 h-14 px-4 rounded-xl border border-[#66666659] focus:outline-none
           focus:border-[#FFD700] focus:ring-1 focus:ring-[#FFD700] focus:ring-opacity-50
           transition-all duration-150 ease-in-out focus:shadow-sm placeholder:text-[#272727]"
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-red-600 text-xs mt-1">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
                <div className="mt-5">
                  <label htmlFor="email" className="text-base text-[#666666]">
                    Email address
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="w-full mt-1 h-14 px-4 rounded-xl border border-[#66666659] focus:outline-none
           focus:border-[#FFD700] focus:ring-1 focus:ring-[#FFD700] focus:ring-opacity-50
           transition-all duration-150 ease-in-out focus:shadow-sm placeholder:text-[#272727]"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-600 text-xs mt-1">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
                <div className="mt-5">
                  <label htmlFor="name" className="text-base text-[#666666]">
                    Attach Images(Optional)
                  </label>
                  <Screenshots shots={shots} setShots={setShots} />
                </div>
                <div className="mt-5">
                  <label htmlFor="email" className="text-base text-[#666666]">
                    Message
                  </label>
                  <textarea
                    id="comment"
                    className="w-full mt-1 h-48 px-4 py-2 rounded-xl border border-[#66666659] focus:outline-none
           focus:border-[#FFD700] focus:ring-1 focus:ring-[#FFD700] focus:ring-opacity-50
           transition-all duration-150 ease-in-out focus:shadow-sm placeholder:text-[#272727] resize-none"
                    {...formik.getFieldProps("comment")}
                  ></textarea>
                  {formik.touched.comment && formik.errors.comment ? (
                    <div className="text-red-600 text-xs mt-1">
                      {formik.errors.comment}
                    </div>
                  ) : null}
                </div>
                <div className="w-full flex justify-end mt-6">
                  <button
                    type="button"
                    onClick={() => formik.handleSubmit()}
                    className="w-full h-12 flex justify-center items-center bg-[#EE1D52] rounded-lg text-base text-white font-medium
        transition-all duration-150 ease-in-out hover:shadow-xl"
                  >
                    {loading ? <ButtonLoader title="Sending..." /> : "Send Message"}
                    
                  </button>
                </div>
              </form>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
