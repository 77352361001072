import { Link, useLocation } from "react-location";
import { HiOutlineHome } from "react-icons/hi2";

export default function Breadcrumbs() {
  const { current } = useLocation();

  // console.log(current.pathname);

  let currentLink = "";

  const crumbs = current.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb, index) => {
      currentLink += `/${crumb}`;
      return (
        <div key={index} className="font-poppins flex items-center gap-4">
          <Link
            to={currentLink}
            className="font-normal text-base text-[#1567EE] flex items-center"
          >
            {crumb === "vision" ? "Our Vision & Mission" : crumb}
          </Link>
          {index < current.pathname.split("/").length - 2 && (
            <span className="text-2xl text-[#292D32]">&gt;</span>
          )}
        </div>
      );
    });

  return (
    <div className="flex items-center gap-4">
      <Link to={"/"} className="text-base text-[#1567EE] flex items-center">
        <HiOutlineHome className="w-5 h-5" aria-hidden="true" />
      </Link>
      <span className="text-2xl text-[#292D32]">&gt;</span>
      {crumbs}
    </div>
  );
}
