export default function SpotTheDiff1() {
  return (
    <section className="pt-20 pb-10 text-white">
      <div
        className="interacty_padding"
        style={{ position: "relative", padding: 0, paddingTop: "99.5%" }}
      >
        <div
          className="interacty_wrapper"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <iframe
            style={{ border: "none", width: "100%", height: "100%" }}
            src="https://p.interacty.me/dd05299e028b7706/iframe.html"
          ></iframe>
        </div>
      </div>
    </section>
  );
}
