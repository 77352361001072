import { gql } from "@apollo/client";

export const GET_USER = gql`
  query Me {
    me {
      _id
      firstName
      lastName
      email
      phone
      category
      subscription {
        _id
        userId
        packageId {
          _id
          name
          description
          stripeProductId
          stripePriceId
          numberOfSeats
          price
          billingInterval
          features
          permissions
          currency
          createdAt
          updatedAt
        }
        stripeSubscriptionId
        stripeCustomerId
        status
        clientSecret
        currentPeriodStart
        currentPeriodEnd
        canceledAtPeriodEnd
        canceledAt
        createdAt
        updatedAt
      }
      isPremiumUser
      isSubscribed
      progress
      profilePicture
      userMeta {
        authType
        isFirstTimeUser
        invitedBy
        acceptedTermsAndConditions
        receivePromotionalNotifications
      }
      loginMeta {
        lastLoginAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_USERS_BY_ADMIN = gql`
  query GetUsersAddedByAdmin($adminId: ID!) {
    getUsersAddedByAdmin(adminId: $adminId) {
      _id
      firstName
      lastName
      email
      phone
    }
  }
`;
