import "./App.css";
import ApolloProvider from "./apollo";
import LayoutProvider from "./layout";
import NotificationProvider from "./notifications";
import RoutesProvider from "./router";

function App() {
  return (
    <>
      <NotificationProvider>
        <ApolloProvider>
          <RoutesProvider>
            <LayoutProvider />
          </RoutesProvider>
        </ApolloProvider>
      </NotificationProvider>
    </>
  );
}

export default App;
