/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { RichTextEditor } from "./components/richtext-editor";
import { NoteList } from "./components/note-list";
import { NoteModal } from "./components/note-modal";
import { FiPlus } from "react-icons/fi";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_NOTE, UPDATE_NOTE } from "@/apollo/graphql/mutations/notes";
import {
  CreateNoteMutation,
  CreateNoteMutationVariables,
  UpdateNoteMutation,
  UpdateNoteMutationVariables,
} from "@/apollo/graphql/generated/types";
import { useCurrentUser } from "@/apollo/cache/auth";
import { GET_NOTES } from "@/apollo/graphql/queries/notes";

interface Note {
  _id: string;
  title: string;
  content: string;
  date: string;
}

const Note: React.FC = () => {
  const user = useCurrentUser();
  const [notes, setNotes] = useState<Note[]>([]);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);

  console.log("user", user);

  const {
    data,
    loading: loadingNotes,
    refetch,
  } = useQuery(GET_NOTES, {
    variables: {
      filter: {
        user: {
          eq:
            user?.userMeta.invitedBy === null
              ? user._id
              : user?.userMeta.invitedBy,
        },
      },
      search: {
        fields: ["title"],
        options: ["i", "m"],
        query: "",
      },
      populate: ["user", "createdBy"],
      pagination: {
        limit: 100,
        skip: 0,
      },
      sort: {},
    },
  });

  useEffect(() => {
    if (data?.getNotes) {
      setNotes(data.getNotes);
    }
  }, [data]);

  const [createNote, { loading: creatingNote }] = useMutation<
    CreateNoteMutation,
    CreateNoteMutationVariables
  >(CREATE_NOTE);

  const [updateNote] = useMutation<
    UpdateNoteMutation,
    UpdateNoteMutationVariables
  >(UPDATE_NOTE);

  const handleCreateNewNote = async (title: string) => {
    try {
      const { data } = await createNote({
        variables: {
          input: {
            content: "",
            title: title,
            user:
              user?.userMeta.invitedBy === null
                ? user._id
                : user?.userMeta.invitedBy,
          },
        },
      });

      if (data?.createNote) {
        refetch();
        setSelectedNote(notes[notes.length - 1]);
        setModalOpen(false);
      }
    } catch (error) {
      console.error("Error creating note:", error);
    }
  };

  const handleNoteContentChange = async (content: string) => {
    if (selectedNote) {
      const updatedNote = { ...selectedNote, content };
      setSelectedNote(updatedNote);

      try {
        await updateNote({
          variables: {
            input: {
              _id: selectedNote._id,
              title: selectedNote.title,
              content,
              user: user._id,
            },
          },
        });
        // Delay refetching until the update is confirmed to prevent race conditions
        setTimeout(() => {
          refetch();
        }, 500); // Optional: Adjust the delay as needed
      } catch (error) {
        console.error("Error updating note:", error);
      }
    }
  };

  const handleSelectNote = (note: Note) => {
    // Save current note content before switching
    if (selectedNote && selectedNote.content !== note.content) {
      handleNoteContentChange(selectedNote.content);
    }
    setSelectedNote(note);
  };

  return (
    <main className="font-poppins p-5">
      <div className="w-full flex justify-between items-center">
        <h1 className="font-medium text-2xl text-black">Notes</h1>
        <button
          onClick={() => setModalOpen(true)}
          className="w-fit flex justify-center items-center gap-2 bg-black text-white px-4 py-3 rounded-lg"
        >
          <FiPlus className="w-4 h-4" aria-hidden="true" />
          <span className="text-xs">Create New Note</span>
        </button>
      </div>

      <div className="flex bg-white items-start gap-10 mt-5">
        <div className="w-1/3">
          {loadingNotes ? (
            <p className="mt-5">Loading notes...</p>
          ) : (
            <NoteList notes={notes} onSelectNote={handleSelectNote} />
          )}
        </div>
        <div className="w-full flex-1">
          {selectedNote ? (
            <RichTextEditor
              note={selectedNote}
              onContentChange={handleNoteContentChange}
            />
          ) : (
            <p className="text-center text-gray-500">
              Select or create a note to get started.
            </p>
          )}
        </div>

        {isModalOpen && (
          <NoteModal
            open={isModalOpen}
            setOpen={setModalOpen}
            onSave={handleCreateNewNote}
            loading={creatingNote}
          />
        )}
      </div>
    </main>
  );
};

export default Note;
