import { gql } from "@apollo/client";

export const CREATE_NOTE = gql`
  mutation CreateNote($input: createNoteInput) {
    createNote(input: $input) {
      _id
      user {
        _id
        firstName
        lastName
      }
      createdBy {
        _id
        firstName
        lastName
      }
      title
      content
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation UpdateNote($input: updateNoteInput) {
    updateNote(input: $input) {
      _id
      user {
        _id
        firstName
        lastName
      }
      createdBy {
        _id
        firstName
        lastName
      }
      title
      content
      createdAt
      updatedAt
    }
  }
`;
