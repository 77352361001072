/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import { useCurrentUser } from "@/apollo/cache/auth";
import {
  GetPackagesQuery,
  GetPackagesQueryVariables,
  SearchOperatorOption,
} from "@/apollo/graphql/generated/types";

import { GET_PACKAGES } from "@/apollo/graphql/queries/packages";
import { scrollToTop } from "@/utils/scroll-to-top";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { HiArrowRight } from "react-icons/hi";
import { LuCheck } from "react-icons/lu";
import { useNavigate } from "react-location";

export default function Membership() {
  const [billing, setBilling] = useState("monthly");
  const [plans, setPlans] = useState<any>([]);
  // const user = useCurrentUser();

  // const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop();
  }, []);

  const { data, loading, error } = useQuery<
    GetPackagesQuery,
    GetPackagesQueryVariables
  >(GET_PACKAGES, {
    variables: {
      filter: {},
      sort: {},
      pagination: {
        limit: 10,
        skip: 0,
      },
      search: {
        fields: ["name"],
        options: [SearchOperatorOption.I, SearchOperatorOption.M],
        query: "",
      },
    },
  });

  useEffect(() => {
    if (data) {
      setPlans(data?.getPackages?.rows);
    }
  }, [data]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <main className="pt-36 pb-16 bg-[#242424]">
      <section className="w-full max-w-7xl mx-auto flex flex-col items-center">
        <h1 className="font-poppins text-white text-4xl mb-4">
          Membership Based Access
        </h1>
        <p className="font-sans text-2xl text-center text-[#C4C4C4] mb-4">
          Choose a plan that is best for you
        </p>
        <div className="w-full max-w-[35.25rem] flex gap-2 items-center mx-auto border-2 border-[#606060] rounded-xl p-2 relative overflow-hidden">
          <div
            className={`absolute top-[5px] border border-[#606060] left-1 h-[80%] w-[49%] bg-[#2C2C2C] rounded-lg transition-transform duration-500 ease-in-out ${
              billing === "monthly"
                ? "transform translate-x-0"
                : "transform translate-x-full"
            }`}
          ></div>
          <div className="flex-1 z-10">
            <button
              onClick={() => setBilling("monthly")}
              className={`w-full py-2 rounded-lg font-poppins transition-colors duration-300 ${
                billing === "monthly" ? "text-white" : "text-[#888888]"
              }`}
            >
              Monthly billing
            </button>
          </div>
          <div className="flex-1 z-10">
            <button
              onClick={() => setBilling("yearly")}
              className={`w-full py-2 rounded-lg font-poppins transition-colors duration-300 ${
                billing === "yearly" ? "text-white" : "text-[#888888]"
              }`}
            >
              Yearly (save up to 40%)
            </button>
          </div>
        </div>

        {billing === "monthly" ? (
          <div className="mt-10 w-full border border-[#606060] flex">
            {plans
              .filter((plan: any) => plan.billingInterval !== "year")
              .map((plan: any, index: number) => (
                <div
                  key={index}
                  className="flex flex-1 flex-col gap-4 border-r border-[#606060] p-6"
                >
                  <h2 className="font-poppins font-medium text-2xl text-white">
                    {plan.name}
                  </h2>
                  <h1 className="font-poppins font-semibold text-4xl text-white">
                    ${plan.price}{" "}
                    <sub className="font-normal text-lg">seat/mo.</sub>{" "}
                  </h1>

                  <p className="font-poppins text-[#606060] text-sm">
                    {plan.description}
                  </p>

                  <button
                    // disabled={subscribing}
                    // onClick={() => handleSubscription(plan._id)}
                    onClick={() =>
                      navigate({ to: `/membership-detail/?id=${plan._id}` })
                    }
                    className="w-full bg-gradient-5 font-poppins font-semibold text-lg text-center text-white flex
                justify-between items-center gap-4 px-5 py-3 rounded-lg my-8 "
                  >
                    <span>Choose this plan</span>
                    <HiArrowRight />
                  </button>

                  <div className="w-full h-[1px] bg-[#D4D4D4] mb-8" />

                  <div className="">
                    {plan?.features?.map((feature: any, index: number) => (
                      <div key={index} className="flex items-center gap-2 mb-5">
                        <div className="w-6 h-6 flex justify-center items-center bg-[#606060] rounded-full">
                          <LuCheck className="text-white h-4 w-4" />
                        </div>
                        <div className="flex-1 font-poppins text-lg text-white">
                          {feature}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="mt-10 w-full border border-[#606060] flex">
            {plans
              .filter((plan: any) => plan.billingInterval !== "month")
              .map((plan: any, index: number) => (
                <div
                  key={index}
                  className="flex flex-1 flex-col gap-4 border-r border-[#606060] p-6"
                >
                  <h2 className="font-poppins font-medium text-2xl text-white">
                    {plan.name}
                  </h2>
                  <h1 className="font-poppins font-semibold text-4xl text-white">
                    ${plan.price}{" "}
                    <sub className="font-normal text-lg">seat/mo.</sub>{" "}
                  </h1>

                  <p className="font-poppins text-[#606060] text-sm">
                    {plan.description}
                  </p>

                  <button
                    // disabled={subscribing}
                    onClick={() =>
                      navigate({ to: `/membership-detail/?id=${plan._id}` })
                    }
                    className="w-full bg-gradient-5 font-poppins font-semibold text-lg text-center text-white flex
                justify-between items-center gap-4 px-5 py-3 rounded-lg my-8"
                  >
                    <span>Choose this plan</span>
                    <HiArrowRight />
                  </button>

                  <div className="w-full h-[1px] bg-[#D4D4D4] mb-8" />

                  <div className="">
                    {plan?.features?.map((feature: any, index: number) => (
                      <div key={index} className="flex items-center gap-2 mb-5">
                        <div className="w-6 h-6 flex justify-center items-center bg-[#606060] rounded-full">
                          <LuCheck className="text-white h-4 w-4" />
                        </div>
                        <div className="flex-1 font-poppins text-lg text-white">
                          {feature}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        )}
      </section>
    </main>
  );
}
