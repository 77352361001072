/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface RichTextEditorProps {
  note: {
    title: string;
    content: string;
    date: string;
  };
  onContentChange: (content: string) => void;
}

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
  note,
  onContentChange,
}) => {
  return (
    <div>
      <div className="font-poppins w-full border border-[#DEE0E2] rounded-xl p-4 mb-5">
        <h2 className="font-semibold text-sm text-black mb-1">{note.title}</h2>
        <p className="text-sm text-gray-400">{moment(note.date).format("L")}</p>
      </div>

      <ReactQuill
        value={note.content}
        onChange={onContentChange}
        className="h-96 rounded-xl"
      />
    </div>
  );
};

