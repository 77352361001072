import { FC, useEffect } from "react";
import Navbar from "../unauth/components/nav";
import { Outlet } from "react-location";
import { scrollToTop } from "@/utils/scroll-to-top";

const MainLayout: FC = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="">
      <Navbar />
      <div className="w-full min-h-screen bg-white pt-16 mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-8">
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
