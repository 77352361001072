import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

export default function Form() {
  interface FormValues {
    name: string;
    email: string;
    comment: string;
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      comment: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      comment: Yup.string()
        .required("Required")
        .min(5, "Minimum 10 characters"),
    }),
    onSubmit: async (values: FormValues) => {
      console.log(values);
      toast(
        JSON.stringify({
          type: "success",
          title: "comment submitted",
        })
      );
      formik.resetForm();
    },
  });

  return (
    <div className="w-full max-w-[54rem] mx-auto mobile:px-5">
      <form className="w-full">
        <div className="">
          <input
            type="text"
            id="name"
            className="w-full mt-10 h-16 px-4 rounded-xl border border-[#E9EBF0] focus:outline-none
           focus:border-[#FFD700] focus:ring-2 focus:ring-[#FFD700] focus:ring-opacity-50 text-[#272727]
           transition-all duration-150 ease-in-out focus:shadow-xl placeholder:text-[#272727]"
            {...formik.getFieldProps("name")}
            placeholder="Enter your name"
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-red-600 text-xs mt-1">
              {formik.errors.name}
            </div>
          ) : null}
        </div>
        <div className="">
          <input
            type="email"
            id="email"
            className="w-full mt-6 h-16 px-4 rounded-xl border border-[#E9EBF0] focus:outline-none
           focus:border-[#FFD700] focus:ring-2 focus:ring-[#FFD700] focus:ring-opacity-50 text-[#272727]
           transition-all duration-150 ease-in-out focus:shadow-xl placeholder:text-[#272727]"
            {...formik.getFieldProps("email")}
            placeholder="Enter your email"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-600 text-xs mt-1">
              {formik.errors.email}
            </div>
          ) : null}
        </div>
        <div className="">
          <textarea
            id="comment"
            className="w-full mt-6 h-48 px-4 py-2 rounded-xl border border-[#E9EBF0] focus:outline-none
           focus:border-[#FFD700] focus:ring-2 focus:ring-[#FFD700] focus:ring-opacity-50 text-[#272727]
           transition-all duration-150 ease-in-out focus:shadow-xl placeholder:text-[#272727] resize-none"
            {...formik.getFieldProps("comment")}
            placeholder="Enter your comment"
          ></textarea>
          {formik.touched.comment && formik.errors.comment ? (
            <div className="text-red-600 text-xs mt-1">
              {formik.errors.comment}
            </div>
          ) : null}
        </div>
        <div className="w-full flex justify-end mt-6">
          <button
            type="button"
            onClick={() => formik.handleSubmit()}
            className="mobile:w-full w-48 h-14 flex justify-center items-center bg-gradient-5 rounded-lg text-base text-white font-medium
        transition-all duration-150 ease-in-out hover:shadow-xl"
          >
            SUBMIT
          </button>
        </div>
      </form>
    </div>
  );
}
