// import pc1 from "@/assets/images/12.png";

import { useCurrentUser } from "@/apollo/cache/auth";
import {
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from "@/apollo/graphql/generated/types";
import { UPDATE_USER } from "@/apollo/graphql/mutations/user";
import { LocationGenerics } from "@/router/location";
import { scrollToTop } from "@/utils/scroll-to-top";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useSearch } from "react-location";

export default function Focus() {
  const searchParams = useSearch<LocationGenerics>();
  const user = useCurrentUser();

  const school_type = searchParams.search;
  useEffect(() => {
    scrollToTop();
  }, []);

  const [updateUser] = useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UPDATE_USER);

  useEffect(() => {
    const updateUserProgress = async () => {
      await updateUser({
        variables: {
          input: {
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
            progress: 10,
          },
        },
      }).then(({ data }) => {
        if (data?.updateUser) {
          return;
        }
      });
    };

    if (user?.progress == 0) updateUserProgress();
  }, [updateUser, user]);

  return (
    <div className="font-poppins max-w-5xl px-4 sm:px-6 lg:px-8">
      <h1 className="font-semibold text-2xl/9 text-white mb-2">
        Focus & Series Summary
      </h1>
      <p className="font-medium text-lg text-white mb-5">
        {" "}
        Focus: <span className="">Jealousy & Hatred</span>{" "}
      </p>
      <p className="font-medium text-lg text-white mb-5">
        {" "}
        Episode Title: <span className="">Dreams</span>{" "}
      </p>

      <div className="flex justify-between gap-10">
        {/* <img src={pc1} alt="" className="w-80 h-auto object-contain " /> */}
        <div className="flex-1 flex flex-col gap-10 mt-8">
          <div
            className={`p-10 rounded-3xl border ${
              school_type === "pre" ? "border-pre" : "border-grade"
            } bg-blue-50 border-dashed`}
          >
            <h2 className="font-bold text-3xl ">Summary</h2>
            <p className="comic-sans mt-4 text-lg/8">
              Jacob and his family live in Canaan. He has 12 sons but Joseph is
              his favorite! Jacob loves Joseph so much that he gives him a robe
              of many colors as a sign that he is favored. Because of this, his
              10 older brothers are very jealous of him. Joseph has dreams that
              explain that he is set apart from his brothers for a greater
              purpose. When he decides to share his dreams with them, their
              jealousy grows into great hatred for Joseph. Even his father
              scolds him, after Joseph tells him about one of the dreams he had.
            </p>

            <p className="font-bold mt-4 text-lg">
              Relevant Scripture: Genesis 37: 3-11
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
