import { Outlet, Route, SearchPredicate } from "react-location";

import { LocationGenerics } from "./location";
import Home from "@/pages/home";
import SignIn from "@/pages/sign-in";
import SignUp from "@/pages/sign-up";
import Membership from "@/pages/membership";
// import Series from "@/pages/series";
import Introduction from "@/pages/series/introduction";
import Vision from "@/pages/series/vision";
import MainIntro from "@/pages/main/introduction";
import PreSchool from "@/pages/main/pre-school";
import GradSchool from "@/pages/main/grade-school";
import Detail from "@/pages/main/detail/detail";
import Who from "@/pages/who-we-are";
import Faqs from "@/pages/Faqs";
import Contact from "@/pages/contact";
import Note from "@/pages/notes";
import PaymentPage from "@/pages/membership/subscription";
import SuccessPayment from "@/pages/membership/subscription/success";
import MembershipDetail from "@/pages/membership/detail";
import ForgotPassword from "@/pages/forgot-password";
import GamesPage from "@/pages/games";
import SpotTheDiff1 from "@/pages/games/spot-diff1";
import SpotTheDiff2 from "@/pages/games/spot-diff2";

export type RouteProps = Omit<Route, "children"> & {
  navigation?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sidebar?: { label: string; icon: any };
  children?: RouteProps[];
  search?: SearchPredicate<LocationGenerics>;
};

const routes: RouteProps[] = [
  {
    path: "/",
    element: <Home />,
    meta: {
      layout: "UnAuth",
    },
  },
  {
    path: "/who-we-are",
    element: <Who />,
    meta: {
      layout: "UnAuth",
    },
  },
  {
    path: "/faqs",
    element: <Faqs />,
    meta: {
      layout: "UnAuth",
    },
  },
  {
    path: "/contact",
    element: <Contact />,
    meta: {
      layout: "UnAuth",
    },
  },
  {
    path: "/games",
    element: <GamesPage />,
    meta: {
      layout: "UnAuth",
    },
  },
  {
    path: "/spot-the-difference1",
    element: <SpotTheDiff1 />,
    meta: {
      layout: "UnAuth",
    },
  },
  {
    path: "/spot-the-difference2",
    element: <SpotTheDiff2 />,
    meta: {
      layout: "UnAuth",
    },
  },
  {
    path: "/sign-up",
    element: <SignUp />,
    meta: {
      layout: "Auth",
    },
  },
  {
    path: "/login",
    element: <SignIn />,
    meta: {
      layout: "Auth",
    },
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    meta: {
      layout: "Auth",
    },
  },
  {
    path: "/membership",
    element: <Membership />,
    meta: {
      layout: "UnAuth",
    },
  },
  {
    path: "/membership-detail",
    element: <MembershipDetail />,
    meta: {
      layout: "UnAuth",
    },
  },
  {
    path: "/success",
    element: <SuccessPayment />,
    meta: {
      layout: "UnAuth",
    },
  },
  {
    path: "/membership-payment/:id",
    element: <PaymentPage />,
    meta: {
      layout: "UnAuth",
    },
  },
  {
    path: "/academy",
    element: <Outlet />,
    meta: {
      layout: "App",
    },
    children: [
      {
        path: "/",
        element: <Introduction />,
        meta: {
          layout: "App",
        },
      },
      {
        path: "vision",
        element: <Vision />,
        meta: {
          layout: "App",
        },
      },
    ],
  },
  {
    path: "/main-introduction",
    element: <Outlet />,
    meta: {
      layout: "Main",
    },
    children: [
      {
        path: "/",
        element: <MainIntro />,
        meta: {
          layout: "Main",
        },
      },
      {
        path: "pre-school",
        element: <PreSchool />,
        meta: {
          layout: "Main",
        },
      },
      {
        path: "grade-school",
        element: <GradSchool />,
        meta: {
          layout: "Main",
        },
      },
    ],
  },
  {
    path: "/notes",
    element: <Note />,
    meta: {
      layout: "Lesson",
    },
  },
  {
    path: "/:id",
    element: <Outlet />,
    meta: {
      layout: "Lesson",
    },
    children: [
      {
        path: "/",
        element: <Detail />,
        meta: {
          layout: "Lesson",
        },
      },
    ],
  },
];

export default routes;
