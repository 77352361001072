import quizPic from "@/assets/images/disPic.svg";
import quizPic2 from "@/assets/images/quiz2.png";
import quizPic3 from "@/assets/images/quiz3.png";
import quizPic4 from "@/assets/images/quiz4.png";
import quizPic5 from "@/assets/images/quiz5.png";
import quizPic6 from "@/assets/images/quiz6.png";
import quizPic7 from "@/assets/images/quiz7.png";

type QuizOption = {
  label: string;
  value: string;
};

type QuizQuestion = {
  question: string;
  pic?: string;
  options: QuizOption[];
  answer: string;
};

export const preQuiz: QuizQuestion[] = [
  {
    question: "How many sons does Jacob have?",
    pic: quizPic,
    options: [
      { label: "A", value: "Eleven (11) sons" },
      { label: "B", value: "Twelve (12) sons" },
      { label: "C", value: "Thirteen (13) sons" },
      { label: "D", value: "Fourteen (14) sons" },
    ],
    answer: "Twelve (12) sons",
  },
  {
    question: "Who is Jacob's favorite son?",
    pic: quizPic2,
    options: [
      { label: "A", value: "Reuben" },
      { label: "B", value: "Simeon" },
      { label: "C", value: "Zebulun" },
      { label: "D", value: "Joseph" },
    ],
    answer: "Joseph",
  },
  {
    question: "What did Jacob give to Joseph?",
    pic: quizPic3,
    options: [
      { label: "A", value: "A golden crown" },
      { label: "B", value: "A robe (or coat) of many colors" },
      { label: "C", value: "A silver chalice" },
      { label: "D", value: "A ring of authority" },
    ],
    answer: "A robe (or coat) of many colors",
  },
  {
    question: "What happened when Joseph's brothers saw his present?",
    pic: quizPic4,
    options: [
      {
        label: "A",
        value:
          "They became jealous of him and were angry that they received nothing.",
      },
      { label: "B", value: "They celebrated with him." },
      { label: "C", value: "They ignored the gift and went about their day." },
      { label: "D", value: "They asked their father for similar gifts." },
    ],
    answer:
      "They became jealous of him and were angry that they received nothing.",
  },
  {
    question: "Why did Joseph's brothers hate him?",
    pic: quizPic5,
    options: [
      { label: "A", value: "He told them they had to work harder." },
      {
        label: "B",
        value:
          "They hated Joseph because he was loved by his father dearly and was the only son to receive a gift from him. They also did not like that Joseph's dreams meant that he would rule over them.",
      },
      { label: "C", value: "He refused to help them with their chores." },
      { label: "D", value: "He took their share of the inheritance." },
    ],
    answer:
      "They hated Joseph because he was loved by his father dearly and was the only son to receive a gift from him. They also did not like that Joseph's dreams meant that he would rule over them.",
  },
  {
    question: "How many dreams did Joseph have?",
    pic: quizPic6,
    options: [
      { label: "A", value: "One (1) dream" },
      { label: "B", value: "Two (2) dreams" },
      { label: "C", value: "Three (3) dreams" },
      { label: "D", value: "Four (4) dreams" },
    ],
    answer: "Two (2) dreams",
  },
  {
    question: "Why did Jacob scold Joseph?",
    pic: quizPic7,
    options: [
      { label: "A", value: "He failed to complete his chores on time." },
      { label: "B", value: "He disobeyed his father's instructions." },
      {
        label: "C",
        value:
          "Joseph was scolded because of the dream he had! He dreamt that the sun, the moon and 11 stars were bowing down to him.",
      },
      { label: "D", value: "He lost an important family heirloom." },
    ],
    answer:
      "Joseph was scolded because of the dream he had! He dreamt that the sun, the moon and 11 stars were bowing down to him.",
  },
];

export const gradeQuiz: QuizQuestion[] = [
  {
    question: "What special gift did Joseph receive from his father?",
    pic: quizPic,
    options: [
      { label: "A", value: "Shirt of the same color" },
      { label: "B", value: "The coat of many colors" },
      { label: "C", value: "Uniform of red color" },
      { label: "D", value: "Jacket of green color" },
    ],
    answer: "The coat of many colors",
  },
  {
    question: "What are the names of Jacob's sons?",
    pic: quizPic2,
    options: [
      {
        label: "A",
        value:
          "Reuben, Simeon, Levi, Judah, Dan, Naphtali, Gad, Asher, Issachar, Zebulun,Joseph, Benjamin",
      },
      {
        label: "B",
        value:
          "Abel, Seth, Enosh, Kenan, Mahalalel, Jared, Enoch, Methuselah, Lamech, Noah, Shem, and Japheth",
      },
      {
        label: "C",
        value:
          "Ishmael, Isaac, Esau, Jacob, Reuben, Simeon, Levi, Judah, Dan, and Naphtali",
      },
      {
        label: "D",
        value:
          "Manasseh, Ephraim, Jesse, David, Solomon, Nathan, Abner, Asahel, Joab, and Absalom",
      },
    ],
    answer:
      "Reuben, Simeon, Levi, Judah, Dan, Naphtali, Gad, Asher, Issachar, Zebulun,Joseph, Benjamin",
  },
  {
    question: "What do all of Jacob's sons make up (what are they called)?",
    pic: quizPic3,
    options: [
      { label: "A", value: "The Twelve(12) Disciples" },
      { label: "B", value: "The Ten(10) Commandments" },
      { label: "C", value: "The Twelve(12) Tribes of Israel" },
      { label: "D", value: "The Twelve(12) Apostles" },
    ],
    answer: "The Twelve(12) Tribes of Israel",
  },
  {
    question: "Describe the dreams that Joseph had.",
    pic: quizPic4,
    options: [
      {
        label: "A",
        value:
          "In his first dream, Joseph and his brothers were tying up sheaves of wheat, when his sheaf got up, and his brothers' sheaves bowed down to his. In another dream, Joseph saw the sun, the mood, and 11 stars bowing down to him.",
      },
      {
        label: "B",
        value:
          "In his first dream, Joseph saw twelve olive trees bearing fruit, and only his tree bore golden fruit. In his second dream, a great river flowed from his feet, nourishing the land.",
      },
      {
        label: "C",
        value:
          "In his first dream, Joseph saw himself building a great palace, and all his brothers came to live in it. In his second dream, he rode a chariot to the stars and met the angel Gabriel",
      },
      {
        label: "D",
        value:
          "In his first dream, Joseph saw a rainbow above his head, with each color representing one of his brothers. In his second dream, he was crowned king by a lion.",
      },
    ],
    answer:
      "In his first dream, Joseph and his brothers were tying up sheaves of wheat, when his sheaf got up, and his brothers' sheaves bowed down to his. In another dream, Joseph saw the sun, the mood, and 11 stars bowing down to him.",
  },
  {
    question:
      "Can you identify some parts in the episode when jealousy was being demonstrated?",
    pic: quizPic5,
    options: [
      {
        label: "A",
        value:
          "Joseph's brothers were envious of his relationship with their father and his special treatment, leading them to speak harshly to him.",
      },
      {
        label: "B",
        value:
          "The brothers' jealousy turned to hatred when they saw Joseph approaching them in the field, leading them to plot against him.",
      },
      {
        label: "C",
        value:
          "Their jealousy grew when Jacob showed more affection toward Joseph than toward them.",
      },
      {
        label: "D",
        value:
          "The brothers were angry when they didn't receive a gift from their father like Joseph did instead of being happy for him. They spoke rudely to him when he told them about his dreams.",
      },
    ],
    answer:
      "The brothers were angry when they didn't receive a gift from their father like Joseph did instead of being happy for him. They spoke rudely to him when he told them about his dreams.",
  },
];
