/* eslint-disable @typescript-eslint/no-explicit-any */
//pre school
import c1 from "@/assets/images/vidCard1.png";
import { IoChevronBackSharp } from "react-icons/io5";
import { IoPlay } from "react-icons/io5";
import { useNavigate } from "react-location";
import l2 from "@/assets/images/lessonsThumb/l2.png";
import l3 from "@/assets/images/lessonsThumb/l3.png";
import l4 from "@/assets/images/lessonsThumb/l4.png";
import l5 from "@/assets/images/lessonsThumb/l5.png";
import l6 from "@/assets/images/lessonsThumb/l6.png";
import l7 from "@/assets/images/lessonsThumb/l7.png";
import l8 from "@/assets/images/lessonsThumb/l8.png";
import l9 from "@/assets/images/lessonsThumb/l9.png";
import l10 from "@/assets/images/lessonsThumb/l10.png";
import l11 from "@/assets/images/lessonsThumb/l11.png";
import l12 from "@/assets/images/lessonsThumb/l12.png";

import { useQuery } from "@apollo/client";
import { MeQuery, MeQueryVariables } from "@/apollo/graphql/generated/types";
import { GET_USER } from "@/apollo/graphql/queries/user";
import { useEffect } from "react";
import { setMe } from "@/apollo/cache/auth";

const vids = [
  {
    id: "1",
    title: "Joseph Dreamer to Governor",
    description:
      "Description of lesson also goes here and can be long to occupy 2-3 lines Description of lesson also goes here and can be long to occupy 2-3 lines",
    thumbnail: c1,
  },
  {
    id: "2",
    title: "The Colorful Coat",
    description:
      "Joseph receives a special coat from his father, Jacob, which causes jealousy...",
    thumbnail: l2,
  },
  {
    id: "3",
    title: "Dreams and Betrayal",
    description:
      "oseph's dreams foretell his future greatness, leading his brothers to...",
    thumbnail: l3,
  },
  {
    id: "4",
    title: "From Slave to Overseer",
    description:
      "In Egypt, Joseph works as a slave in Potiphar's house but quickly rises to a...",
    thumbnail: l4,
  },
  {
    id: "5",
    title: "The Cupbearer and the Baker",
    description:
      "Joseph interprets the dreams of the cupbearer and the baker, predicting...",
    thumbnail: l5,
  },
  {
    id: "6",
    title: "The Pharaoh's Dreams",
    description:
      "Joseph interprets Pharaoh's dreams, predicting seven years of plenty...",
    thumbnail: l6,
  },
  {
    id: "7",
    title: "The Famine Begins",
    description:
      "The famine begins, and Joseph's brothers come to Egypt to buy food...",
    thumbnail: l7,
  },
  {
    id: "8",
    title: "Joseph's Brothers Return",
    description:
      "Joseph's brothers return to Egypt, where Joseph tests their character...",
    thumbnail: l8,
  },
  {
    id: "9",
    title: "Joseph Reveals Himself",
    description:
      "Joseph reveals his identity to his brothers, who return to Canaan...",
    thumbnail: l9,
  },
  {
    id: "10",
    title: "Jacob's Family Moves to Egypt",
    description:
      "Jacob's family moves to Egypt, where Joseph is reunited with his father...",
    thumbnail: l10,
  },
  {
    id: "11",
    title: "Jacob Blesses His Sons",
    description:
      "Jacob blesses his sons, foretelling their future and the future",
    thumbnail: l11,
  },
  {
    id: "12",
    title: "Joseph's Final Days",
    description:
      "Joseph forgives his brothers and promises to care for them and their...",
    thumbnail: l12,
  },
];

const VidCard = ({ data, type }: { data: any; type: string }) => {
  // const user = useCurrentUser();
  const navigate = useNavigate();

  const { data: userData, refetch } = useQuery<MeQuery, MeQueryVariables>(
    GET_USER
  );

  useEffect(() => {
    if (data?.me) {
      refetch();
      setMe(data.me);
    }
  }, [data, refetch]);

  const user = userData?.me;

  return (
    <button
      disabled={data.id !== "1"}
      onClick={() => navigate({ to: `/lesson/?id=${data.id}/&search=${type}` })}
      //  to={`/lesson/?id=${data.id}/&search=${type}`}
      className="font-poppins w-60 text-left disabled:cursor-not-allowed disabled:text-gray-300 text-black"
    >
      <div className="relative w-full h-36 rounded-xl overflow-hidden mb-3">
        <img
          src={data.thumbnail}
          alt="image panel"
          className="object-contain"
        />
        <div className="absolute inset-0  flex justify-center items-center">
          <div className="w-12 h-12 flex justify-center items-center rounded-full bg-[#d3c8f3] bg-opacity-75 shadow-lg">
            <IoPlay
              className={`w-6 h-6 ${
                data.id === "1" ? "text-black" : "text-gray-400"
              }`}
              aria-hidden="true"
            />
          </div>
        </div>
      </div>

      <p className="text-xs/5 text-[#242565] mb-3">Lesson {data.id}</p>

      <h4 className="font-semibold text-base  mb-2 max-w-full truncate">
        {data.title}
      </h4>

      <p className="text-xs/5 h-[2.3rem] overflow-hidden ">
        {data.description}
      </p>

      <div className="w-full flex items-center justify-between space-x-0.5 mt-2">
        <div className="flex-1 w-full h-2 bg-[#EAECF0] rounded-full overflow-hidden ">
          <div
            //  style={{ width: `${user?.progress}%` }}
            className={`${
              data.id === "1" ? `w-[${user?.progress}%]` : "w-0"
            }  h-full bg-[#1567EE] rounded-full`}
          ></div>
        </div>
        <div className="w-9 flex justify-end">
          <p
            className={`font-medium text-sm ${
              data.id === "1" ? "text-[#344054]" : "text-gray-300"
            } `}
          >
            {data.id === "1" ? user?.progress ?? "0" : "0"} %
          </p>
        </div>
      </div>
    </button>
  );
};

interface SchoolLayoutProps {
  name: string;
  type: string;
}

export default function SchoolLayout({ name, type }: SchoolLayoutProps) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate({ to: "..", replace: true }); // This navigates to the previous location
  };

  return (
    <main className="font-poppins px-5 ">
      <section className="pt-8 pb-20">
        <div className="border-b border-[#D4D4D4] pb-4">
          <h1 className="font-bold text-3xl text-black">{name}</h1>
        </div>
        <div className=" mt-10 flex flex-wrap gap-x-8 gap-y-6">
          {vids.map((vid, index) => (
            <VidCard data={vid} type={type} key={index} />
          ))}
        </div>
      </section>

      {/* footer */}
      <div className="fixed bottom-0 inset-x-0 w-full h-16 shadow-lg bg-white">
        <div className="w-full h-full max-w-screen-2xl mx-auto flex justify-between items-center px-10">
          <button
            onClick={goBack}
            className="w-32 h-12 flex justify-center space-x-2 items-center bg-white border border-[#EE1D52] rounded-lg
          text-[#EE1D52]"
          >
            <IoChevronBackSharp className="" aria-hidden="true" />
            <span>Previous</span>
          </button>
          <button
            // onClick={() => setOpen(true)}
            className="w-32 h-12 flex justify-center items-center bg-[#EE1D52] border border-[#EE1D52] rounded-lg
          text-white font-poppins"
          >
            Next
          </button>
        </div>
      </div>
    </main>
  );
}
