import { useEffect } from "react";
import PrintCard from "./components/print-card";
import { scrollToTop } from "@/utils/scroll-to-top";
import gFile1 from "@/assets/Grade-school files/Maze Page.pdf";
import gFile2 from "@/assets/Grade-school files/Word Search Page.pdf";
import pFile1 from "@/assets/Pre-school files/Jacob_s Beard.pdf";
import pFile2 from "@/assets/Pre-school files/Joseph and Benjamin.pdf";
import pFile3 from "@/assets/Pre-school files/Jacob and Joseph.pdf";

import { useSearch } from "react-location";
import { LocationGenerics } from "@/router/location";

const gradeData = [
  {
    path: gFile1,
    title: "Maze Page.pdf",
  },
  {
    path: gFile2,
    title: "Word Search Page.pdf",
  },
];
const preData = [
  {
    path: pFile1,
    title: "Jacob_s Beard.pdf",
  },
  {
    path: pFile2,
    title: "Joseph and Benjamin.pdf",
  },
  {
    path: pFile3,
    title: "Jacob and Joseph.pdf",
  },
];
//
export default function Activity() {
  const searchParams = useSearch<LocationGenerics>();

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <main className="font-poppins px-4 sm:px-6 lg:px-8 pt-5">
      <h1 className="font-medium text-2xl text-white border-b border-gray-200 pb-2.5">
        Printable Activity
      </h1>

      <div className="flex items-center gap-5 mt-6">
        {searchParams.search === "pre"
          ? preData.map((data, index) => (
              <PrintCard key={index} path={data.path} title={data.title} />
            ))
          : gradeData.map((data, index) => (
              <PrintCard key={index} path={data.path} title={data.title} />
            ))}
      </div>
    </main>
  );
}
