import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useQuery } from "@apollo/client";
import { GET_USER } from "@/apollo/graphql/queries/user";
import { MeQuery, MeQueryVariables } from "@/apollo/graphql/generated/types";
import { setMe, useCurrentUser } from "@/apollo/cache/auth";

const SuccessPayment: React.FC = () => {
  const { data } = useQuery<MeQuery, MeQueryVariables>(GET_USER);
  const user = useCurrentUser();
  const [isSubscribed, setIsSubscribed] = React.useState<boolean>(false);
  
  useEffect(() => {
    if (data?.me) {
      setMe(data?.me);
      setIsSubscribed(user?.isSubscribed || false);
    }
  }, [user, data]);

  return (
    <div className="flex justify-center items-center h-screen bg-black">
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
        className="bg-[#111] p-10 rounded-lg shadow-2xl text-center"
      >
        <motion.div
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 0.8 }}
          className="flex justify-center mb-4"
        >
          <svg
            className="w-16 h-16 text-green-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <motion.path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        </motion.div>

        <h2 className="text-4xl font-semibold text-white mb-2">
          Payment Successful
        </h2>
        <p className="text-gray-300 mb-4 text-lg text-center">
          Your payment has successfully <br /> been made. Thank you!
        </p>

        <motion.button
          disabled={!isSubscribed}
          onClick={() =>
            user.isSubscribed && window.location.replace("/academy")
          }
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-blue-700 text-white px-6 py-3 rounded-lg shadow hover:bg-blue-600 focus:outline-none"
        >
          Watch series now
        </motion.button>
      </motion.div>
    </div>
  );
};

export default SuccessPayment;
