/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import ButtonLoader from "@/components/loaders/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IoMdArrowBack } from "react-icons/io";
import { Link, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "@/router/location";
import { useState } from "react";
import { BiSolidHide, BiSolidShow } from "react-icons/bi";
import { useMutation } from "@apollo/client";
import {
  FIND_USER_BY_EMAIL,
  RESET_PASSWORD,
  VERIFY_CODE,
} from "@/apollo/graphql/mutations/auth";
import {
  FindUserByEmailMutation,
  FindUserByEmailMutationVariables,
  ResetPasswordAfterVerificationMutation,
  ResetPasswordAfterVerificationMutationVariables,
  VerifyCodeMutation,
  VerifyCodeMutationVariables,
} from "@/apollo/graphql/generated/types";
import toast from "react-hot-toast";

export default function ForgotPassword() {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [hideNewPassword, setHideNewPassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);

  const [getEmail, { loading: getEmailLoading }] = useMutation<
    FindUserByEmailMutation,
    FindUserByEmailMutationVariables
  >(FIND_USER_BY_EMAIL);

  const [verifyCode, { loading: verifyCodeLoading }] = useMutation<
    VerifyCodeMutation,
    VerifyCodeMutationVariables
  >(VERIFY_CODE);

  const [resetPassword, { loading: resetPasswordLoading }] = useMutation<
    ResetPasswordAfterVerificationMutation,
    ResetPasswordAfterVerificationMutationVariables
  >(RESET_PASSWORD);

  // handle email
  const { handleSubmit, ...signupForm } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Email is invalid")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      await getEmail({
        variables: {
          email: values.email,
        },
      })
        .then(({ data }) => {
          if (data?.findUserByEmail) {
            setUserId(data?.findUserByEmail.user?._id ?? "");
            toast(
              JSON.stringify({
                type: "success",
                title:
                  data?.findUserByEmail.message ?? "Email sent successfully",
              })
            );
            navigate({ to: "/forgot-password?sort=verify+code" });
          } else {
            toast(
              JSON.stringify({
                type: "error",
                title: "Failed to get email",
              })
            );
          }
        })
        .catch((error: any) => {
          toast(
            JSON.stringify({
              type: "error",
              title: error?.data?.message ?? "Failed to send email",
            })
          );
        });
    },
  });

  // handle verify code
  const form = useFormik({
    initialValues: {
      verifyCode: "",
    },
    validationSchema: Yup.object({
      verifyCode: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      await verifyCode({
        variables: {
          code: values.verifyCode,
          user: userId,
        },
      })
        .then(({ data }) => {
          if (data?.verifyCode) {
            toast(
              JSON.stringify({
                type: "success",
                title:
                  data?.verifyCode?.message ?? "code retrieved successfully",
              })
            );
            navigate({ to: "/forgot-password?sort=new+password" });
          } else {
            toast(
              JSON.stringify({
                type: "error",
                title: "Failed to get email",
              })
            );
          }
        })
        .catch((error: any) => {
          toast(
            JSON.stringify({
              type: "error",
              title: error?.data?.message ?? "Failed to send email",
            })
          );
        });
    },
  });

  // handle new password
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8, "Must be 8 characters or more")
        .required("Required"),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), undefined], "Passwords must match")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      await resetPassword({
        variables: {
          password: values.newPassword,
          confirmedPassword: values.confirmNewPassword,
        },
      })
        .then(({ data }) => {
          if (data?.resetPasswordAfterVerification) {
            toast(
              JSON.stringify({
                type: "success",
                title: "Password reset successfully",
              })
            );
            navigate({ to: "/forgot-password?sort=done" });
          } else {
            toast(
              JSON.stringify({
                type: "error",
                title: "Failed to get email",
              })
            );
          }
        })
        .catch((error: any) => {
          toast(
            JSON.stringify({
              type: "error",
              title: error?.data?.message ?? "Failed to send email",
            })
          );
        });
    },
  });

  return (
    <section className="font-poppins bg-white border border-[#66666680] rounded-3xl py-20 mobile:px-5 px-10 w-full max-w-[43.25rem] ">
      {searchParams.sort && searchParams.sort === "verify code" ? (
        <>
          <h1 className="font-medium text-3xl text-[#333333] text-center mb-2">
            Check your email
          </h1>
          <p className="text-center text-[#808099] mb-8">
            A verification code has been be sent to <br />
            <span className="font-medium">
              {signupForm.values.email ?? "your email"}
            </span>
          </p>

          <div className="w-full">
            <input
              id="verifyCode"
              type="text"
              placeholder="Code"
              className="w-full h-12 border border-[#66666680] rounded-xl px-5"
              {...form.getFieldProps("verifyCode")}
            />

            {form.touched.verifyCode && form.errors.verifyCode ? (
              <div className="text-red-600 text-xs mt-1">
                {form.errors.verifyCode}
              </div>
            ) : null}
          </div>
          <button
            className="w-full h-12 bg-[#1567EE] text-white rounded-xl mt-10"
            onClick={() => form.handleSubmit()}
          >
            {verifyCodeLoading ? (
              <ButtonLoader title="verifying..." />
            ) : (
              "Verify"
            )}
          </button>
        </>
      ) : searchParams.sort && searchParams.sort === "new password" ? (
        <>
          <h1 className="font-medium text-3xl text-[#333333] text-center mb-2">
            Set new password
          </h1>
          <p className="text-center text-[#808099] mb-8">
            Your new password must be different from <br />
            previously used passwords.
          </p>

          <div className="w-full max-w-xl mt-4">
            <div className="flex justify-between items-center border border-[#66666659] rounded-lg p-3 mt-1 focus:outline-none focus:border-[#666666]">
              <input
                type={hideNewPassword ? "password" : "text"}
                placeholder="Enter your new password"
                id="newPassword"
                name="newPassword"
                onChange={(e) =>
                  formik.setFieldValue("newPassword", e.target.value)
                }
                value={formik.values.newPassword}
                className="w-full outline-none focus:outline-none border-none focus:border-none"
              />
              <div className="flex gap-2">
                {!hideNewPassword ? (
                  <button
                    type="button"
                    className="flex gap-1"
                    onClick={() => setHideNewPassword(true)}
                  >
                    <BiSolidHide
                      className="h-6 w-6 text-[#666666CC]"
                      aria-hidden="true"
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="flex gap-1"
                    onClick={() => setHideNewPassword(false)}
                  >
                    <BiSolidShow
                      className="h-6 w-6 text-[#666666CC]"
                      aria-hidden="true"
                    />
                  </button>
                )}
              </div>
            </div>
            {formik.touched.newPassword && formik.errors.newPassword && (
              <p className="text-xs mt-2 text-red-500">
                {formik.errors.newPassword}
              </p>
            )}
          </div>
          <div className="w-full max-w-xl my-4">
            <div className="flex justify-between items-center border border-[#66666659] rounded-lg p-3 mt-1 focus:outline-none focus:border-[#666666]">
              <input
                type={hideConfirmPassword ? "password" : "text"}
                placeholder="Confirm your new password"
                id="confirmPassword"
                name="confirmPassword"
                onChange={(e) =>
                  formik.setFieldValue("confirmNewPassword", e.target.value)
                }
                value={formik.values.confirmNewPassword}
                className="w-full outline-none focus:outline-none border-none focus:border-none"
              />
              <div className="flex gap-2">
                {!hideConfirmPassword ? (
                  <button
                    type="button"
                    className="flex gap-1"
                    onClick={() => setHideConfirmPassword(true)}
                  >
                    <BiSolidHide
                      className="h-6 w-6 text-[#666666CC]"
                      aria-hidden="true"
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="flex gap-1"
                    onClick={() => setHideConfirmPassword(false)}
                  >
                    <BiSolidShow
                      className="h-6 w-6 text-[#666666CC]"
                      aria-hidden="true"
                    />
                  </button>
                )}
              </div>
            </div>
            {formik.touched.confirmNewPassword &&
              formik.errors.confirmNewPassword && (
                <p className="text-xs mt-2 text-red-500">
                  {formik.errors.confirmNewPassword}
                </p>
              )}
          </div>

          <button
            onClick={() => formik.handleSubmit()}
            className="w-full max-w-xl h-12 bg-[#1567EE] rounded-xl flex justify-center items-center text-lg text-white"
          >
            {resetPasswordLoading ? (
              <ButtonLoader title="" />
            ) : (
              "Reset password"
            )}
          </button>
        </>
      ) : searchParams.sort && searchParams.sort === "done" ? (
        <>
          <h1 className="font-medium text-3xl text-[#333333] text-center mb-2">
            Password reset
          </h1>
          <p className="text-center text-[#808099] mb-16">
            Your password has been successfully reset. <br />
            Click below to login.
          </p>

          <button
            onClick={() => navigate({ to: "/login" })}
            className="w-full h-12 bg-[#1567EE] text-white rounded-xl mt-10"
          >
            Log in
          </button>
        </>
      ) : (
        <>
          <h1 className="font-medium text-3xl text-[#333333] text-center mb-2">
            Forgot password ?
          </h1>
          <p className="text-center text-[#333333] mb-8">
            Don't worry, we are here to guide you through
          </p>

          <div className="w-full max-w-xl mx-auto">
            <input
              name="email"
              id="email"
              type="email"
              placeholder="Email"
              className="w-full h-12 border border-[#66666680] rounded-xl px-5 mt-5 "
              value={signupForm.values.email}
              onChange={(e: { target: { value: any } }) =>
                signupForm.setFieldValue("email", e.target.value)
              }
            />
            {signupForm.errors.email && (
              <p className="font-normal text-xs mt-2 text-red-500">
                {signupForm.errors.email}
              </p>
            )}
          </div>

          <button
            className="w-full h-12 bg-[#1567EE] text-white rounded-xl mt-10"
            onClick={() => handleSubmit()}
          >
            {getEmailLoading ? (
              <ButtonLoader title="retrieving..." />
            ) : (
              "Continue"
            )}
          </button>
        </>
      )}

      <div className="w-full flex justify-center mt-8">
        {searchParams.sort !== "done" && (
          <Link
            to={"/login"}
            className="flex items-center gap-2 text-[#808099]"
          >
            <IoMdArrowBack />
            <p className="">Back to log in</p>
          </Link>
        )}
      </div>
    </section>
  );
}
