import { FC } from "react";
import logo from "@/assets/images/logo.png";
import { Link, Outlet } from "react-location";

const AuthLayout: FC = () => {
  return (
    <div className="font-poppins h-screen overflow-y-hidden w-full bg-[#272727] flex mobile:flex-col mobile:justify-start mobile:gap-10 justify-between items-center py-1">
      <Link to={"/"} className="md:flex-1 flex justify-center items-center">
        <div className="flex flex-col items-center">
          <img
            src={logo}
            alt="logo"
            className="h-16 w-auto object-contain"
          />

          <h1 className="text-white text-2xl">Academy</h1>
        </div>
      </Link>

      <div className="md:flex-1 flex items-center justify-center overflow-y-scroll h-full py-10 px-10 lg:px-36 mobile:px-5 mobile:w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
