/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { LuImagePlus } from "react-icons/lu";
import { useMutation as useMutationTanstack } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { ClipLoader } from "react-spinners";
import { MdDelete } from "react-icons/md"; // Import delete icon
import { UPLOAD_FILE } from "@/helpers/upload-file";

interface ScreenshotsProps {
  shots: string[];
  setShots: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function Screenshots({ shots, setShots }: ScreenshotsProps) {
  const { mutateAsync: uploadFile, isPending } = useMutationTanstack({
    mutationKey: ["uploadFile"],
    mutationFn: UPLOAD_FILE,
  });

  const [hoveredShot, setHoveredShot] = useState<number | null>(null); // Track hovered image

  // Upload a single file
  const handleUpload = async (file: any) => {
    const data = new FormData();
    data.append("file", file);

    try {
      const response = await uploadFile(data); // Ensure the response from the mutation
      return response.url; // Return the URL for further processing
    } catch (err) {
      console.error("Upload failed:", err);
      toast(
        JSON.stringify({
          type: "error",
          title: "Upload failed",
        })
      );
      return null;
    }
  };

  // Handle file change for multiple files
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = event.target.files;

    if (selectedFiles) {
      const uploadedUrls: string[] = []; // Collect uploaded file URLs

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const uploadedUrl = await handleUpload(file);

        if (uploadedUrl) {
          uploadedUrls.push(uploadedUrl); // Add each successfully uploaded URL to the array
        }
      }

      // Update state once after all uploads are complete
      setShots((prevShots) => [...prevShots, ...uploadedUrls]);
    }
  };

  // Delete a screenshot from the array
  const handleDelete = (index: number) => {
    setShots((prevShots) => prevShots.filter((_, i) => i !== index)); // Remove the shot by index
  };

  return (
    <div className="flex flex-col">
      {/* Grid to display uploaded images */}
      <div className="flex justify-start items-center flex-nowrap overflow-scroll gap-2 mb-4">
        {shots.map((url, index) => (
          <div
            key={index}
            className="relative w-20 h-20 object-cover rounded-md"
            onMouseEnter={() => setHoveredShot(index)}
            onMouseLeave={() => setHoveredShot(null)}
          >
            <img
              src={url}
              alt={`screenshot-${index}`}
              className="w-full h-full object-cover rounded-md"
            />

            {hoveredShot === index && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 rounded-md transition-all duration-100 ease-in-out">
                <button
                  onClick={() => handleDelete(index)}
                  className="text-white text-lg"
                >
                  <MdDelete size={25} />
                </button>
              </div>
            )}
          </div>
        ))}
        {isPending && (
          <div
            className={`flex justify-center items-center w-20 h-20 md:h-[58px] rounded-full overflow-hidden  mb-4 md:mb-0`}
          >
            <ClipLoader
              color={"#6F48F9"}
              loading={true}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>

      <div className="w-full flex gap-4 items-center">
        <div className="flex flex-1 w-full py-5 flex-col justify-center items-center border border-dashed border-gray-400 rounded-sm">
          <label
            htmlFor="file"
            className="w-full flex flex-col items-center gap-2 cursor-pointer py-5"
          >
            <div className=" w-5 h-5 ">
              <LuImagePlus className="text-gray-500 " size={27} />
            </div>

            <input
              type="file"
              name="file"
              id="file"
              accept="image/*"
              multiple
              className="hidden"
              onChange={handleFileChange}
            />

            <div className="font-sans text-center">
              <p className="text-sm text-[#2A558D] font-medium leading-5 text-center">
                Upload file(s)
              </p>
            </div>
          </label>
        </div>
        <div className="flex flex-1 w-full py-5 flex-col justify-center items-center border border-dashed border-gray-400 rounded-sm">
          <label
            htmlFor="file"
            className="w-full flex flex-col items-center gap-2 cursor-pointer py-5"
          >
            <div className=" w-5 h-5 ">
              <LuImagePlus className="text-gray-500 " size={27} />
            </div>

            <input
              type="file"
              name="file"
              id="file"
              accept="image/*"
              multiple
              className="hidden"
              onChange={handleFileChange}
            />

            <div className="font-sans text-center">
              <p className="text-sm text-[#2A558D] font-medium leading-5 text-center">
                Upload file(s)
              </p>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
}
