/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useState } from 'react'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import logo from "@/assets/images/logo.png";
// import { Link } from "react-location";
import { UPDATE_USER } from "@/apollo/graphql/mutations/user";
import { setMe, useCurrentUser } from "@/apollo/cache/auth";
import { useMutation, useQuery } from "@apollo/client";
import { MeQuery, MeQueryVariables } from "@/apollo/graphql/generated/types";
import { GET_USER } from "@/apollo/graphql/queries/user";
import { useEffect } from "react";
import { useNavigate } from "react-location";

interface ModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function Modal({ open, setOpen }: ModalProps) {
  const user = useCurrentUser();

  const navigate = useNavigate();

  const { data, refetch } = useQuery<MeQuery, MeQueryVariables>(GET_USER);

  const [updateUser, { loading }] = useMutation(UPDATE_USER);

  useEffect(() => {
    if (data?.me) {
      setMe(data?.me);
    }
  }, [data]);

  const handlePreSchoolClick = async () => {
    await updateUser({
      variables: {
        input: {
          firstName: user?.firstName,
          lastName: user?.lastName,
          phone: user?.phone,
          category: "PRE-SCHOOL",
        },
      },
    })
      .then((res) => {
        if (res?.data?.updateUser) {
          refetch();
          navigate({ to: "pre-school" });
          setOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
      });
  };
  const handleGradeSchoolClick = async () => {
    await updateUser({
      variables: {
        input: {
          firstName: user?.firstName,
          lastName: user?.lastName,
          phone: user?.phone,
          category: "GRADE-SCHOOL",
        },
      },
    })
      .then((res) => {
        if (res?.data?.updateUser) {
          refetch();
          navigate({ to: "grade-school" });
          setOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
      });
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-[#111111D9] bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-start sm:pt-20">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-se-xl rounded-ss-xl bg-white px-4 pb-4 pt-5 text-left shadow-xl
                   transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 
                  data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mx-auto flex items-center justify-center rounded-full">
                <img src={logo} alt="logo" className="h-16 w-auto" />
              </div>
              <div className="font-poppins mt-3 text-center sm:mt-6">
                <DialogTitle
                  as="h3"
                  className="font-semibold text-3xl/10 text-[#181818]"
                >
                  Select your preferred category
                </DialogTitle>
                <p className="text-2xl/10 text-center text-[#555555] max-w-xl mx-auto my-12">
                  Choose the category you like best and start exploring fun
                  activities and lessons tailored just for you!
                </p>

                <button
                  // to={"pre-school"}
                  disabled={loading}
                  onClick={handlePreSchoolClick}
                  className="w-full max-w-xl mx-auto mb-4 h-20 bg-[#2B64E3] flex justify-center items-center
                font-medium text-3xl text-white rounded-[20px]"
                >
                  Pre - School
                </button>
                <button
                  // to={"grade-school"}
                  onClick={handleGradeSchoolClick}
                  disabled={loading}
                  className="w-full max-w-xl mx-auto mb-4 h-20 bg-[#EE1D52] flex justify-center items-center
                font-medium text-3xl text-white rounded-[20px]"
                >
                  Grade School
                </button>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
