import { gql } from "@apollo/client";

export const CREATE_SUB = gql`
  mutation CreateSubscription($packageId: String!, $quantity: Int!) {
    createSubscription(packageId: $packageId, quantity: $quantity) {
      _id
      userId
      packageId {
        _id
        name
        description
        stripeProductId
        stripePriceId
        numberOfSeats
        price
        billingInterval
        features
        permissions
        currency
        createdAt
        updatedAt
      }
      stripeSubscriptionId
      stripeCustomerId
      status
      clientSecret
      currentPeriodStart
      currentPeriodEnd
      canceledAtPeriodEnd
      canceledAt
      createdAt
      updatedAt
    }
  }
`;
