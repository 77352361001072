import principlePic from "@/assets/images/priciplePic.png";
import presentPic from "@/assets/images/presentPic.png";
import applicationPic from "@/assets/images/applicationPic.png";
import { useEffect } from "react";
import { scrollToTop } from "@/utils/scroll-to-top";

export default function Application() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <main className="font-poppins w-full max-w-5xl pb-20 px-4 sm:px-6 lg:px-8 pt-5">
      <h2 className="font-semibold text-2xl/9 text-white ">
        Application & Daily Life Principles
      </h2>
      <p className="text-base text-white mt-2">
        Discuss what we learned from this episode and scripture and how can we
        apply it in our lives.
      </p>

      <p className="font-semibold text-lg text-white mt-5">
        Scripture: <br />
        <span className="text-base">
          (Luke 12:15){" "}
          <span className="font-normal">
            {" "}
            The bible says it's a sin to covet (or be jealous/envious of) what
            someone else has because it can lead us to sinning in other ways
            like hurting someone or treating them poorly.
          </span>
        </span>
      </p>

      <div className="comic-sans w-full mt-5 bg-white flex justify-between items-center rounded-xl overflow-hidden">
        <div className="flex-1 px-6 flex flex-col gap-3 max-w-2xl">
          <h4 className="font-bold text-lg ">Principle: Jealousy</h4>
          <p className="text-base ">
            Jealousy is a feeling of sadness or anger over someone else's
            blessing, favor or ability.
          </p>
          <p className="text-base ">
            Being jealous of others is bad because it means we don't think they
            deserve their blessing or the good things that happen in their
            lives.
          </p>
        </div>
        <div className="">
          <img
            src={principlePic}
            alt="principle picture"
            className="object-contain w-36 h-auto"
          />
        </div>
      </div>

      <div className="comic-sans w-full mt-5 bg-[#FDE8F1] p-6 border-2  rounded-xl overflow-hidden">
        <h4 className="font-bold text-lg ">Principle: Jealousy</h4>
        <p className="text-base mt-2">
          Jealousy Jealousy is bad because it takes the focus off of someone
          being celebrated and makes it all about how you feel. It can cause you
          to feel mad or sad and even do hurtful things to others when you only
          think about yourself.
          <br />
          As children of God, we should always treat people how we want to be
          treated, with love and kindness.
        </p>
      </div>

      <div className="comic-sans w-full flex justify-between items-center gap-10">
        <div className="h-40 flex-1 w-full mt-5 bg-[#F9E8FD] p-6 rounded-xl overflow-hidden">
          <h4 className="font-bold text-lg ">Example 1</h4>
          <p className="text-base mt-2">
            When someone gets a present on their birthday or on Christmas that
            we want but didn't get, we may try to steal it from them.
          </p>
        </div>
        <div className="h-40 flex-1 w-full mt-5 bg-[#E8F9FD] p-6 rounded-xl overflow-hidden">
          <h4 className="font-bold text-lg ">Example 2</h4>
          <p className="text-base mt-2">
            When your sibling or friend gets special attention, you may start
            being mean to them because of jealousy.
          </p>
        </div>
      </div>

      <div className="comic-sans w-full mt-5 bg-[#E8FDEA] pt-5 pr-5 flex justify-between items-center rounded-xl overflow-hidden">
        <div className="">
          <img
            src={presentPic}
            alt="principle picture"
            className="object-contain w-36 h-auto"
          />
        </div>
        <div className="flex-1 px-6 flex flex-col gap-3 pb-8">
          <h4 className="font-bold text-lg ">
            Presents on Birthdays or Christmas
          </h4>
          <p className="text-base ">
            <span className="font-bold underline">Story:</span> Imagine it's
            your friend's birthday, and they get a big, shiny toy that you
            really like. You might feel a little sad or wish it was yours. This
            feeling is called jealousy.
          </p>
          <p className="text-base ">
            <span className="font-bold underline">What to Do:</span> Instead of
            feeling jealous, you can be happy for your friend and tell them,
            "Wow, what a cool toy!" You can also remember all the nice toys and
            gifts you have received and be thankful for them
          </p>
          <h4 className="font-bold text-lg ">Special Attention:</h4>
          <p className="text-base ">
            <span className="font-bold underline">Story:</span> Imagine your
            little brother or sister is getting a lot of attention from your
            parents because they are learning to walk. You might feel left out
            and jealous.
          </p>
          <p className="text-base ">
            <span className="font-bold underline">What to Do:</span> Instead of
            feeling sad, you can help and cheer for your sibling. Remember,
            there are many times when your parents give you special attention
            too!
          </p>
        </div>
      </div>

      <h3 className="font-semibold text-2xl/9 text-white mt-7">Application</h3>
      <p className="text-base text-white ">
        Discuss what we learned from this episode and scripture and how can we
        apply it in our lives.
      </p>
      <div className="comic-sans w-full mt-5 bg-[#F1F5F8] pt-5 pr-5 flex justify-between items-end rounded-xl overflow-hidden">
        <div className="flex-1 px-6 flex flex-col gap-3 pb-8">
          <h4 className="font-bold text-lg  mb-5">
            When we see someone who has received gifts and blessings, what
            should we do instead?
          </h4>

          <ol className="text-base list-decimal pl-4">
            <li className="mb-2">
              Congratulate them and rejoice in their blessings.
            </li>
            <li className="">
              Remember our own blessings and be happy and thank God for giving
              them to us. We can embrace other people's differences and
              strengths, and learn from them if that's an area that we fall
              short in.
            </li>
          </ol>

          <h4 className="font-bold text-lg my-5">
            If we find ourselves feeling jealous, what can we do about it?
          </h4>

          <ol className="text-base list-decimal pl-4">
            <li className="mb-2">
              <span className="font-bold underline">
                Pray and Ask for Help:
              </span>{" "}
              You can talk to God and say, "Dear God, I feel jealous. Please
              help me to feel happy for my friend and remind me of all the good
              things I have."
            </li>
            <li className="">
              <span className="font-bold underline">Think of Good Things:</span>{" "}
              Whenever you feel jealous, try to think about three things that
              make you happy. It could be your favorite toy, a fun game, or a
              hug from your mom or dad.
            </li>
          </ol>

          <h4 className="font-bold text-lg my-5">
            What do we do if we notice someone is jealous of us?
          </h4>

          <ol className="text-base list-decimal pl-4">
            <li className="mb-2">
              <span className="font-bold underline">
                Be Kind and Understanding:
              </span>{" "}
              If you notice someone feels jealous of you, be nice to them. Share
              a toy or say something nice like, "I really like playing with
              you!" This can make them feel better.
            </li>
            <li className="">
              <span className="font-bold underline">Compliment Them:</span> Find
              something you like about them and tell them. For example, "You
              draw really nice pictures!" This helps them feel special too.
            </li>
          </ol>
          <h4 className="font-bold text-lg  my-5">
            What shouldn't you do when someone is jealous of you?
          </h4>

          <ol className="text-base list-decimal pl-4">
            <li className="mb-2">
              <span className="font-bold underline m">Don't Be Mean Back:</span>{" "}
              If someone is jealous and says something unkind, don't say unkind
              things back. Try to be nice and show them how to be a good friend.
            </li>
            <li className="">
              <span className="font-bold underline">Don't Hate Them:</span>{" "}
              Remember, feeling jealous is something everyone goes through.
              Instead of being upset, try to understand and help them feel
              better.
            </li>
          </ol>
        </div>
        <div className="">
          <img
            src={applicationPic}
            alt="principle picture"
            className="object-contain w-36 h-auto"
          />
        </div>
      </div>
    </main>
  );
}
