import { Link } from "react-location";
import Faq from "./components/faq";
import { CONTACT } from "@/constants/path";
import { useEffect } from "react";
import { scrollToTop } from "@/utils/scroll-to-top";

export default function Faqs() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="bg-black">
      <main className=" max-w-4xl mx-auto min-h-screen py-36 text-white flex flex-col items-center">
        <h1 className="text-4xl mb-2 text-center">
          Frequently <br />
          Ask Questions
        </h1>
        <div className="w-28 h-1 bg-gradient-5 rounded-full " />

        <Faq />

        <h1 className="text-4xl text-center mb-2">Have any questions ?</h1>
        <p className="text-2xl text-center mb-6">
          Our team is always ready to assist you.
        </p>

        <Link
          to={CONTACT}
          className="relative rounded-lg bg-gradient-5 text-white h-12 w-32 flex justify-center items-center text-base"
        >
          Contact Us
        </Link>
      </main>
    </div>
  );
}
