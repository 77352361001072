import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import Account from "./account";
import Subscription from "./subscription";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation, useNavigate } from "react-location";
import { useCurrentUser } from "@/apollo/cache/auth";

const tabs = [
  {
    label: "Account",
    content: <Account />,
  },
  {
    label: "Subscription",
    content: <Subscription />,
  },
];

export default function SettingsModal() {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const navigate = useNavigate();

  const location = useLocation();
  const modalState = location.current.search.modal;
  const modalStateN = location.current.search.state;

  const user = useCurrentUser();

  const AccountOwner = user?.userMeta?.invitedBy === null;

  return (
    <>
      {modalStateN !== 1 && modalState && (
        <Dialog
          open={true}
          onClose={() => {}}
          className="font-poppins relative z-50"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-[#111111D9] bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-2xl bg-white pb-6 pt-4 text-left shadow-xl transition-all data-[closed]:translate-y-4 
            data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-[50.875rem] sm:h-[38rem]
             data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div className="flex justify-between items-center border-b border-[#79747E] px-8 pb-2">
                  <div className="">
                    <nav className="">
                      <ul className="w-full flex list-none gap-8">
                        {tabs.map((item) => (
                          <li
                            key={item.label}
                            className={`${
                              item == selectedTab
                                ? "text-black"
                                : "text-[#989595]"
                            } text-sm relative cursor-pointer min-w-0 select-none 
                            ${
                              item.label === "Subscription"
                                ? AccountOwner
                                  ? ""
                                  : "hidden"
                                : ""
                            }`}
                            onClick={() => setSelectedTab(item)}
                          >
                            {`${item.label}`}
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                  <button
                    type="button"
                    onClick={() =>
                      navigate({
                        to: location.current.href.replace("/?modal=true", ""),
                      })
                    }
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                  </button>
                </div>
                <div className="px-8 h-full ">
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={selectedTab ? selectedTab.label : "empty"}
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: -10, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="h-full"
                    >
                      {selectedTab ? selectedTab.content : ""}
                    </motion.div>
                  </AnimatePresence>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}
