/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from "react";
import { Outlet, useLocation } from "react-location";
import Navbar from "./components/nav";
import Footer from "./components/footer";
import { MEMBERSHIP } from "@/constants/path";
// import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";

const UnAuthLayout: FC = () => {
  // const user = useCurrentUser();
  // const token = useCurrentToken();

  // const isLoggedIn = user && token;
  const { current } = useLocation();

  return (
    <>
      <div className="relative flex flex-col justify-between min-h-screen">
        <Navbar showOptions={current.pathname !== MEMBERSHIP} />
        <div className="flex-1 bg-[#242424] h-full">
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default UnAuthLayout;
