import group1 from "@/assets/images/group-img1.png";
import WalkThroughModal from "../introduction/components/walk-through-modal";

import { Link, useLocation } from "react-location";
import { IoChevronBackSharp } from "react-icons/io5";
import { useEffect } from "react";
import { scrollToTop } from "@/utils/scroll-to-top";

export default function Vision() {
  const location = useLocation();

  const { pathname } = location.current;

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <section className="max-w-5xl font-poppins mt-5">
      <h1 className=" font-semibold text-3xl text-[#E66305]">Our Vision</h1>
      <p className="text-2xl text-black mt-5">
        We aim to transform the lives of all children, ministering to their
        young hearts and minds through Christian-based cartoons that are
        inspiring and educational within and outside of the church, to help them
        develop a strong foundation of faith and guide them towards a virtuous
        life.
      </p>
      <h1 className=" font-semibold text-3xl text-[#E66305] mt-14">
        Our Mission
      </h1>
      <p className="text-2xl text-black mt-5 mb-10">
        We are dedicated to creating relatable, modern, high-quality cartoons
        rooted in Godly principles that captivates viewers, shares the Gospel,
        and instills Christian values in younger generations.
      </p>

      <img
        src={group1}
        alt="group image"
        className="object-contain h-80 w-auto"
      />

      {/* footer */}
      {/*  */}
      <div className="fixed bottom-0 inset-x-0 w-full h-16 shadow-lg bg-white">
        <div className="w-full h-full max-w-[75%] ml-auto flex justify-between items-center px-10">
          <Link
            to={"/academy"}
            className="w-32 h-12 flex justify-center space-x-2 items-center bg-white border border-white rounded-lg
          text-[#111111]"
          >
            <IoChevronBackSharp className="" aria-hidden="true" />
            <span>Previous</span>
          </Link>
          <Link
            to={`${pathname}/?modal=true&state=1`}
            className="w-32 h-12 flex justify-center items-center bg-[#EE1D52] border border-[#EE1D52] rounded-lg
          text-white font-poppins"
          >
            Next
          </Link>
        </div>
      </div>
      <WalkThroughModal />
    </section>
  );
}
