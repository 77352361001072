import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { preQuiz, gradeQuiz } from "./data";
import classNames from "@/utils/classnames";
import { Link, useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "@/router/location";
import successPicGrade from "@/assets/images/success.png";
import successPicPre from "@/assets/images/successPre.png";
import tryPicGrade from "@/assets/images/tryAgain.png";
import tryPicPre from "@/assets/images/tryAgainPre.png";
import trySoundPre from "@/assets/audios/Naphtali - Oh no let's try again.mp3";
import successSoundPre from "@/assets/audios/Reuben - Yay you got it right.mp3";
import successSoundGrade from "@/assets/audios/Benjamin - Great you got it right.mp3";
import trySoundGrade from "@/assets/audios/Asher - Try again you've got this.mp3";
import { scrollToTop } from "@/utils/scroll-to-top";
import { IoChevronBackSharp } from "react-icons/io5";

const Quiz: React.FC = () => {
  //   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [correctAnswer, setCorrectAnswer] = useState(false);
  const [showSuccessCard, setShowSuccessCard] = useState(false);
  const [showTryAgainCard, setShowTryAgainCard] = useState(false);

  const searchParams = useSearch<LocationGenerics>();
  const school_type = searchParams.search;

  const successPic = school_type === "grade" ? successPicGrade : successPicPre;
  const tryPic = school_type === "grade" ? tryPicGrade : tryPicPre;

  const successSound =
    school_type === "grade" ? successSoundGrade : successSoundPre;
  const trySound = school_type === "grade" ? trySoundGrade : trySoundPre;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
    searchParams.page || 0
  );

  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop();
  }, []);

  //   console.log(currentQuestionIndex);

  const handlePrevClick = () => {
    if (currentQuestionIndex > 1) {
      setSelectedOption(null);
      setCorrectAnswer(false);
      setShowSuccessCard(false);
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
      navigate({
        to: `/lesson/?id=${
          searchParams.id
        }&search=${school_type}&sort=discussions&page=${
          (searchParams.page || 0) - 1
        }`,
      });
    }
  };

  const quiz = school_type === "grade" ? gradeQuiz : preQuiz;

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    if (
      option === quiz[(searchParams.page || currentQuestionIndex) - 1].answer
    ) {
      //   setFeedbackMessage("Congratulations! You got it right! 🎉");
      const sound = new Audio(successSound);

      setShowSuccessCard(true);
      sound.volume = 0.8;
      sound.play();
      setCorrectAnswer(true);
    } else {
      //   setFeedbackMessage("Oops! That's not correct. Try the next one! 😊");
      const sound = new Audio(trySound);
      setShowTryAgainCard(true);
      sound.volume = 0.8;
      sound.play();
      setTimeout(() => {
        setSelectedOption(null);
      }, 3000);
    }

    setTimeout(() => {
      //   setShowSuccessCard(false);
      setShowTryAgainCard(false);
    }, 3000);
  };

  const handleNextClick = () => {
    if ((searchParams.page || 0) >= quiz.length - 1) {
      const intervalId = setInterval(() => {
        navigate({
          to: `/lesson/?id=${searchParams.id}&search=${school_type}&sort=application`,
        });
        clearInterval(intervalId); // Clear the interval after executing the navigation
      }, 3000);
    }

    setSelectedOption(null);
    setCorrectAnswer(false);
    setShowSuccessCard(false);
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    navigate({
      to: `/lesson/?id=${
        searchParams.id
      }&search=${school_type}&sort=discussions&page=${
        (searchParams.page || 0) + 1
      }`,
    });
  };

  if ((searchParams.page || 0) >= quiz.length) {
    return (
      <div className="flex items-center justify-center mt-10">
        <motion.div
          className="text-center bg-white p-8 rounded-lg shadow-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-3xl font-bold">Quiz Completed!</h1>
          <p className="mt-4 text-lg">
            Well done, you've finished the quiz! 🎉
          </p>
        </motion.div>
      </div>
    );
  }

  const { question, options, pic } = quiz[(searchParams.page || 1) - 1 || 0];

  return (
    <div className="w-full max-w-4xl pb-24">
      {/* sticky top-[8.8rem] w-full py-4 */}
      <div className="flex flex-center w-full py-4">
        <nav
          className="w-full max-w-xl mx-auto flex flex-center"
          aria-label="Progress"
        >
          <ol role="list" className="flex items-center">
            {quiz.map((step, stepIdx) => (
              <li
                key={stepIdx}
                className={classNames(
                  stepIdx !== quiz.length - 1 ? "pr-8 sm:pr-14" : "",
                  "relative"
                )}
              >
                {stepIdx < (searchParams.page || currentQuestionIndex) ? (
                  <>
                    <div
                      aria-hidden="true"
                      className="absolute inset-0 flex items-center"
                    >
                      <div className="h-0.5 w-full bg-black" />
                    </div>
                    <Link
                      to={`/lesson/?id=${
                        searchParams.id
                      }&search=${school_type}&sort=discussions&page=${
                        stepIdx + 1
                      }`}
                      className="group relative flex h-8 w-8 items-center justify-center border-2 border-black rounded-full bg-white hover:bg-black transition-all duration-200 ease-in-out"
                    >
                      {/* <CheckIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-white"
                    /> */}
                      <span className="font-poppins font-medium text-sm text-black group-hover:text-white transition-all duration-200 ease-in-out">
                        {stepIdx + 1}
                      </span>
                      <span className="sr-only">{step.question}</span>
                    </Link>
                  </>
                ) : stepIdx === ((searchParams.page || 1) - 1 || 0) ? (
                  <>
                    <div
                      aria-hidden="true"
                      className="absolute inset-0 flex items-center"
                    >
                      <div className="h-0.5 w-full bg-[#989595]" />
                    </div>
                    <Link
                      to={`/lesson/?id=${
                        searchParams.id
                      }&search=${school_type}&sort=discussions&page=${
                        stepIdx + 1
                      }`}
                      aria-current="step"
                      className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-[#1567EE] bg-white"
                    >
                      <span
                        aria-hidden="true"
                        className="h-2.5 w-2.5 rounded-full bg-[#1567EE]"
                      />
                      <span className="sr-only">{step.question}</span>
                    </Link>
                  </>
                ) : (
                  <>
                    <div
                      aria-hidden="true"
                      className="absolute inset-0 flex items-center"
                    >
                      <div className="h-0.5 w-full bg-[#989595]" />
                    </div>
                    <Link
                      to={`/lesson/?id=${
                        searchParams.id
                      }&search=${school_type}&sort=discussions&page=${
                        stepIdx + 1
                      }`}
                      className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-[#989595] bg-white hover:border-gray-400"
                    >
                      <span className="font-poppins font-medium text-sm text-[#989595]">
                        {stepIdx + 1}
                      </span>
                      <span className="sr-only">{step.question}</span>
                    </Link>
                  </>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>

      <motion.div
        className="pt-5"
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <div
          className={`flex justify-between gap-5 h-72 overflow-hidden  bg-black rounded-xl pt-8 px-6`}
        >
          <div className="">
            <img
              src={pic}
              alt="question picture"
              className="object-contain w-52 h-auto"
            />
          </div>
          <div className="flex-1">
            <h4 className="comic-sans text-base text-white mb-4">
              Question {searchParams.page}:
            </h4>
            <h2 className="comic-sans text-4xl text-white">{question}</h2>
          </div>
        </div>
        <div className="mt-8 space-y-4">
          {options.map((option, index) => (
            <div
              key={index}
              className="w-full flex items-start justify-between gap-3"
            >
              <p className=" text-black text-base ">{option.label}.</p>
              <motion.button
                className={`comic-sans flex-1 flex flex-center w-full text-xl text-black text-left p-4 rounded-lg transition-colors duration-300 ${
                  selectedOption
                    ? option.value === selectedOption
                      ? option.value ===
                        quiz[(searchParams.page || 1) - 1].answer
                        ? "bg-green-500 text-white"
                        : "bg-[#EE1D52] text-white"
                      : "bg-[#F1F5F8]"
                    : "bg-gray-100 hover:bg-gray-200 border  border-dashed "
                }`}
                onClick={() => handleOptionClick(option.value)}
                disabled={!!selectedOption}
                whileTap={{ scale: 0.95 }}
              >
                {option.value}
              </motion.button>
            </div>
          ))}
        </div>
        {/* {showFeedback && ( */}
        {showSuccessCard ? (
          <motion.div
            className="fixed right-0 bottom-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <img
              src={successPic}
              alt="feedback picture"
              className="object-contain w-72 h-auto"
            />
          </motion.div>
        ) : showTryAgainCard ? (
          <motion.div
            className="fixed right-0 bottom-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <img
              src={tryPic}
              alt="feedback picture"
              className="object-contain w-72 h-auto"
            />
          </motion.div>
        ) : null}
      </motion.div>

      {/* {correctAnswer && (
        <button
          onClick={handleNextClick}
          className="block w-52 bg-[#1567EE] text-white py-3 rounded-lg mt-6"
        >
          Next Question
        </button>
      )} */}

      {/* footer  */}
      <div className="fixed bottom-0 inset-x-0 w-full h-16 shadow-lg bg-black">
        <div className="w-full h-full max-w-[75%] ml-auto flex justify-between items-center px-10">
          <button
            onClick={handlePrevClick}
            className="w-32 h-12 flex justify-center space-x-2 items-center bg-black border border-white rounded-lg
          text-white"
          >
            <IoChevronBackSharp className="" aria-hidden="true" />
            <span>Previous</span>
          </button>
          <button
            disabled={!correctAnswer}
            onClick={handleNextClick}
            className="w-32 h-12 flex justify-center items-center bg-white border border-white rounded-lg
          text-black font-poppins"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
