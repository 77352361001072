// import { Link, Navigate, Outlet, useLocation } from "react-location";
import { Link, Navigate, Outlet, useLocation } from "react-location";

import { useState } from "react";
import {
  Dialog,
  // DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import Navbar from "../unauth/components/nav";
import { LuCheck } from "react-icons/lu";
import { BiMoviePlay } from "react-icons/bi";
import { LuFileSpreadsheet } from "react-icons/lu";
import Breadcrumbs from "@/utils/breadcrumbs";
import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";
import { MEMBERSHIP } from "@/constants/path";
//
export default function AppLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { current } = useLocation();

  const user = useCurrentUser();
  const token = useCurrentToken();

  const isLoggedIn = user && token;

  if (!isLoggedIn) return <Navigate to="/login" replace />;
  if (user?.isPremiumUser === false)
    return <Navigate to={MEMBERSHIP} replace />;

  return (
    <>
      <div className="w-full max-w-screen-2xl mx-auto">
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-50 lg:hidden"
        >
          <div
            // transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              // transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                    />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  <img
                    alt="Your Company"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    className="h-8 w-auto"
                  />
                </div>
                {/* <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-gray-50 text-indigo-600"
                                  : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                              )}
                            >
                              <item.icon
                                aria-hidden="true"
                                className={classNames(
                                  item.current
                                    ? "text-indigo-600"
                                    : "text-gray-400 group-hover:text-indigo-600",
                                  "h-6 w-6 shrink-0"
                                )}
                              />
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <div className="text-xs font-semibold leading-6 text-gray-400">
                        Your teams
                      </div>
                      <ul role="list" className="-mx-2 mt-2 space-y-1">
                        {teams.map((team) => (
                          <li key={team.name}>
                            <a
                              href={team.href}
                              className={classNames(
                                team.current
                                  ? "bg-gray-50 text-indigo-600"
                                  : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                              )}
                            >
                              <span
                                className={classNames(
                                  team.current
                                    ? "border-indigo-600 text-indigo-600"
                                    : "border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600",
                                  "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium"
                                )}
                              >
                                {team.initial}
                              </span>
                              <span className="truncate">{team.name}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li className="mt-auto">
                      <a
                        href="#"
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                      >
                        <Cog6ToothIcon
                          aria-hidden="true"
                          className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                        />
                        Settings
                      </a>
                    </li>
                  </ul>
                </nav> */}
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}

        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-80 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <Navbar />
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white pl-14 pr-6 pb-4">
            <nav className="flex flex-1 flex-col mt-28">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 ">
                    <h2 className="font-poppins font-semibold text-lg text-black mb-2">
                      Series Overview
                    </h2>
                    <Link
                      to={"/academy"}
                      className=" flex gap-3 items-start cursor-pointer "
                    >
                      <div className="w-6 h-6 flex justify-center items-center bg-[#1567EE] rounded-full">
                        <LuCheck className="text-white h-4 w-4" />
                      </div>
                      <div className="">
                        <div
                          className={`flex items-center gap-1.5 text-[#1567EE] mb-1`}
                        >
                          <BiMoviePlay className="w-5 h-6" />
                          <p className="">Introduction</p>
                        </div>
                        <div className="flex items-center gap-2 text-base text-[#989595]">
                          <span>Video</span>
                          <span className="w-2 h-2 rounded-full bg-[#989595] "></span>
                          <span>1 min</span>
                        </div>
                      </div>
                    </Link>
                    <Link
                      to={"/academy/vision"}
                      className="group flex gap-3 items-start cursor-pointer mt-6"
                    >
                      <div
                        className={`w-6 h-6 flex justify-center items-center ${
                          current.pathname === "/academy/vision"
                            ? "bg-[#1567EE]"
                            : "bg-[#33363F]"
                        }  rounded-full`}
                      >
                        <LuCheck className="text-white h-4 w-4" />
                      </div>
                      <div className="">
                        <div
                          className={`flex items-center gap-1.5 ${
                            current.pathname === "/academy/vision"
                              ? "text-[#1567EE]"
                              : "text-[#111111]"
                          }   mb-1`}
                        >
                          <LuFileSpreadsheet className="w-5 h-6" />
                          <p className="">Our Vision & Mission</p>
                        </div>
                        <div className="flex items-center gap-2 text-base text-[#989595]">
                          <span>Reading</span>
                          <span className="w-2 h-2 rounded-full bg-[#989595] "></span>
                          <span>4 mins</span>
                        </div>
                      </div>
                    </Link>
                  </ul>
                </li>

                {/* <li className="mt-auto">
                  <a
                    href="#"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                  >
                    <Cog6ToothIcon
                      aria-hidden="true"
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                    />
                    Back
                  </a>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-80">
          <div className="sticky top-[4.8rem] z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <div className="flex items-center flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <Breadcrumbs />
            </div>
          </div>

          <main className="py-24 bg-white min-h-screen">
            <div className="px-4 sm:px-6 lg:px-8">
              {/* Your content */}
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
