/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { IoMdArrowDropdown } from "react-icons/io";

import logo from "@/assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-location";
import {
  clearAuth,
  setMe,
  useCurrentToken,
  useCurrentUser,
} from "@/apollo/cache/auth";
import { useCallback, useEffect, useState } from "react";
import { CONTACT, FAQS, HOME, MEMBERSHIP, WHO } from "@/constants/path";
import SupportModal from "./support-modal";
import SettingsModal from "./settings-modal";
import Avatar from "@/components/core/avatar";
import { useQuery } from "@apollo/client";
import { MeQuery, MeQueryVariables } from "@/apollo/graphql/generated/types";
import { GET_USER } from "@/apollo/graphql/queries/user";

const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
];

export default function Navbar({ showOptions }: { showOptions?: boolean }) {
  const user = useCurrentUser();
  const token = useCurrentToken();
  const [openModal, setOpenModal] = useState(false);

  const showNote =
    user?.subscription?.packageId?.permissions?.includes("note-generation");

  const { data } = useQuery<MeQuery, MeQueryVariables>(GET_USER);
  useEffect(() => {
    if (data?.me) {
      setMe(data?.me);
    }
  }, [data]);

  const navigate = useNavigate();
  const { current } = useLocation();

  const handleSignOut = useCallback(() => {
    clearAuth();
    navigate({ to: "/" });
  }, [navigate]);

  const isHWFC =
    current.pathname === "/" ||
    current.pathname === "/who-we-are" ||
    current.pathname === "/faqs" ||
    current.pathname === "/contact";

  const isLoggedIn = user && token;

  return (
    <Disclosure
      as="nav"
      className="font-poppins bg-black fixed top-0 inset-x-0 w-full  z-30 bg-opacity-100 backdrop-blur-md backdrop-filter shadow"
    >
      {({ open }) => (
        <>
          <div className="mx-auto w-full max-w-screen-2xl px-4 sm:px-6 lg:px-8">
            <div className="flex py-4 justify-between mobile:block">
              <div className="flex mobile:justify-between gap-3">
                <div className="flex items-center">
                  <Link to={HOME} className="flex flex-shrink-0 items-center">
                    <img
                      className="mobile:h-8 h-10 w-auto"
                      src={logo}
                      alt="logo"
                    />
                  </Link>
                  {showOptions ? (
                    <div className="flex gap-x-4 flex-shrink-0 items-center ">
                      <Link
                        to={HOME}
                        className={`${
                          current.pathname === "/"
                            ? "text-white"
                            : "text-gray-300 hover:text-white"
                        } text-base`}
                      >
                        Home
                      </Link>
                      <Link
                        to={WHO}
                        className={`${
                          current.pathname === "/who-we-are"
                            ? "text-white"
                            : "text-gray-300 hover:text-white"
                        } text-base`}
                      >
                        Who are we
                      </Link>
                      <Link
                        to={FAQS}
                        className={`${
                          current.pathname === "/faqs"
                            ? "text-white"
                            : "text-gray-300 hover:text-white"
                        } text-base`}
                      >
                        FAQs
                      </Link>
                      <Link
                        to={CONTACT}
                        className={`${
                          current.pathname === "/contact"
                            ? "text-white"
                            : "text-gray-300 hover:text-white"
                        } text-base`}
                      >
                        Contact Us
                      </Link>
                    </div>
                  ) : (
                    <div className="border-l border-white -ml-2 pl-2 select-none">
                      <h2 className="font-poppins font-bold text-white text-2xl">
                        ACADEMY
                      </h2>
                    </div>
                  )}
                </div>
                <div className="-ml-2 mr-2 flex items-center  md:hidden">
                  {/* Mobile menu button */}
                  <DisclosureButton className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </DisclosureButton>
                </div>
              </div>
              <div className="flex items-center">
                {!isLoggedIn ||
                  (current.pathname === "/membership" && (
                    <div className="flex-shrink-0 mobile:hidden">
                      <button
                        type="button"
                        className="relative inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                        English (united States)
                        <IoMdArrowDropdown
                          className="-ml-0.5 h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  ))}

                {isLoggedIn && !current.pathname.includes("membership") ? (
                  <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                    <div className="flex gap-6 items-center">
                      <Link to={"/games"} className="relative text-white py-2 text-base  text-nowrap">Games</Link>
                      <button
                        onClick={() => setOpenModal(true)}
                        type="button"
                        className="relative text-white py-2  text-base"
                      >
                        Tech Support
                      </button>
                      <div className="rounded-lg py-2  ">
                        {isHWFC ? (
                          <Link
                            className="relative text-white py-2 text-base  text-nowrap"
                            to={
                              user?.isPremiumUser
                                ? `/main-introduction/${
                                    user?.category === "PRE-SCHOOL"
                                      ? "pre-school"
                                      : "grade-school"
                                  }`
                                : MEMBERSHIP
                            }
                          >
                            Academy
                          </Link>
                        ) : (
                          showNote && (
                            <Link
                              to={"/notes"}
                              className="relative text-white py-2 text-base  text-nowrap"
                            >
                              Teachers & Notes
                            </Link>
                          )
                        )}
                      </div>
                      <Link
                        // onClick={() => setOpenSettings(true)}
                        to={
                          current.href.split("?")[0] === "/lesson/"
                            ? `/?modal=true${current.href}`
                            : `${current.href}/?modal=true`
                        }
                        // type="button"
                        className="relative text-white py-2  text-base"
                      >
                        Settings
                      </Link>
                      {/* <button
                        onClick={() => setOpenSettings(true)}
                        type="button"
                        className="relative text-white py-2  text-base"
                      >
                        Settings
                      </button> */}
                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3 select-none">
                        <div>
                          <MenuButton className="relative h-12 w-12 overflow-hidden flex flex-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open user menu</span>
                            <Avatar
                              src={""}
                              alt={`${user?.firstName} ${user?.lastName}`}
                              size="md"
                            />
                          </MenuButton>
                        </div>
                        <MenuItems
                          // transition
                          className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                          <MenuItem>
                            <p className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 max-w-full truncate">
                              {user?.firstName} {user?.lastName}
                            </p>
                          </MenuItem>
                          <MenuItem>
                            <p className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 max-w-full truncate">
                              {user?.email}
                            </p>
                          </MenuItem>
                          <MenuItem>
                            <div
                              onClick={handleSignOut}
                              className="block px-4 py-2 text-sm text-red-600 cursor-pointer data-[focus]:bg-gray-100"
                            >
                              Sign Out
                            </div>
                          </MenuItem>
                        </MenuItems>
                      </Menu>
                    </div>
                  </div>
                ) : (
                  <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                    <div className="flex gap-4 items-center">
                      <Link
                        to={"/login"}
                        className="relative rounded-lg bg-gradient-5 text-white h-12 w-32 flex justify-center items-center text-base"
                      >
                        Log in
                      </Link>
                      <Link
                        to={"/sign-up"}
                        className="relative bg-black rounded-lg p-[1px] h-12 w-32 overflow-hidden flex justify-center items-center"
                      >
                        <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#ff02dc_0%,#6f48f9_50%,#ff02dc_100%)] " />
                        <span
                          // to={"/sign-up"}
                          className=" w-full h-full inline-flex justify-center items-center px-7  !bg-slate-950 text-base rounded-lg backdrop-blur-3xl gap-2"
                        >
                          <span className="flex justify-center items-center">
                            <span className="text-[#6f48f9] ">Sign </span>
                            <span className="text-[#ff02dc] ml-1"> Up</span>
                          </span>
                        </span>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <DisclosurePanel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              <Link
                to={HOME}
                className={`${
                  current.pathname === "/"
                    ? "bg-gray-900 text-white"
                    : "text-gray-300 hover:bg-gray-700 hover:text-white"
                }
              block rounded-md px-3 py-2 text-base font-medium`}
              >
                Home
              </Link>
              <Link
                to={"#"}
                className={`${
                  current.pathname === "/academy"
                    ? "bg-gray-900 text-white"
                    : "text-gray-300 hover:bg-gray-700 hover:text-white"
                }
              block rounded-md px-3 py-2 text-base font-medium`}
              >
                Watch Series
              </Link>
            </div>
            {isLoggedIn ? (
              <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="flex items-center px-5 sm:px-6">
                  <div className="flex-shrink-0">
                    {/* <img
                      className="h-10 w-10 rounded-full"
                      src={userImg}
                      alt="profile picture"
                    /> */}
                    <Avatar
                      src={""}
                      alt={`${user?.firstName} ${user?.lastName}`}
                      size="md"
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">
                      {user.firstName} {user.lastName}
                    </div>
                    <div className="text-sm font-medium text-gray-400">
                      {user.email}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 space-y-1 px-2 sm:px-3">
                  {userNavigation.map((item) => (
                    <DisclosureButton
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      {item.name}
                    </DisclosureButton>
                  ))}
                  <div
                    onClick={handleSignOut}
                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                  >
                    Sign Out
                  </div>
                </div>
              </div>
            ) : (
              <div className="border-t border-gray-700 py-4">
                <div className="ml-3">
                  <Link
                    to={"/login"}
                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                  >
                    Sign in
                  </Link>

                  <Link
                    to={"/sign-up"}
                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                  >
                    Sign up
                  </Link>
                </div>
              </div>
            )}
          </DisclosurePanel>
          <SupportModal open={openModal} setOpen={setOpenModal} />
          <SettingsModal />
        </>
      )}
    </Disclosure>
  );
}
