import Marquee from "react-fast-marquee";
import VideoBackground from "./components/video";
import vid from "../../assets/videos/headerVid.mp4";
import jess1 from "@/assets/images/jesus1.png";

import meta from "@/assets/images/meta.png";
import group1 from "@/assets/images/group-img1.png";
import group2 from "@/assets/images/group-img2.png";
import joseph from "@/assets/images/joseph.png";
import bible from "@/assets/images/bibleVector.png";
import mountine from "@/assets/images/mountinVector.png";
import p1 from "@/assets/images/p1.png";
import p2 from "@/assets/images/p2.png";
import p3 from "@/assets/images/p3.png";
import p4 from "@/assets/images/p4.png";
import p5 from "@/assets/images/p5.png";
import p6 from "@/assets/images/p6.png";
import Form from "./components/form";
import { SIGNUP } from "@/constants/path";
import { Link } from "react-location";
import { useEffect } from "react";
import { scrollToTop } from "@/utils/scroll-to-top";
import { useCurrentToken, useCurrentUser } from "@/apollo/cache/auth";
//
const slides = [
  {
    text: "Noah's Ark",
    color: "bg-gradient-1",
    textColor: "gradient-text-1",
    image: p1,
  },
  {
    text: "David and Goliath",
    color: "bg-gradient-2",
    textColor: "gradient-text-2",
    image: p2,
  },
  {
    text: "Daniel in the Lion's Den",
    color: "bg-gradient-3",
    textColor: "gradient-text-3",
    image: p3,
  },
  {
    text: "Jonah and the Whale",
    color: "bg-gradient-4",
    textColor: "gradient-text-4",
    image: p4,
  },
  {
    text: "Jonah and the Whale",
    color: "bg-gradient-4",
    textColor: "gradient-text-4",
    image: p5,
  },
  {
    text: "Jonah and the Whale",
    color: "bg-gradient-4",
    textColor: "gradient-text-4",
    image: p6,
  },
];

export default function Home() {
  const user = useCurrentUser();
  const token = useCurrentToken();

  const isLoggedIn = user && token;

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <main className="py-16 bg-black">
      <header className="">
        <VideoBackground videoSrc={vid} />

        {/* Book shape */}

        <div className="bg-black p-10">
          <h1 className="font-bold mobile:text-sm mobile:text-center text-3xl text-white">
            Epic Bible Tales: Faith, Courage, and Miracles
          </h1>
        </div>
      </header>
      <section className="bg-black p-10 mobile:px-5">
        <Marquee pauseOnHover={true} pauseOnClick={true} speed={20} delay={5}>
          {slides.map((slide, index) => (
            <img
              key={index}
              src={slide.image}
              alt="image"
              className="object-contain mobile:h-44 h-80 w-auto mr-8 mobile:rounded-sm rounded-[18px]"
            />
          ))}
        </Marquee>
      </section>

      <section className="mt-10 w-full ">
        <div className="w-full mobile:pt-36 pt-20 pb-20 max-w-screen-xl mx-auto mobile:px-5 flex mobile:flex-col justify-between items-center gap-14">
          <div className="flex-1">
            <h2 className="font-bold text-4xl mobile:text-center text-white mb-10">
              Upcoming Series
            </h2>
            <img
              src={joseph}
              alt="image for story"
              className="mobile:h-16 h-36 w-auto object-contain "
            />
            <p className="mobile:text-sm mobile:text-center text-base text-[#D4D4D4] mt-2 pb-5 border-b border-[#666666]">
              Joseph - Dreamer to Governor is a rendition of the famous bible
              story of Joseph. It is an adventure-filled 12 episode cartoon
              series, about a young teen named Joseph, who has dreams as big as
              the sky.
            </p>
            <Link
              to={isLoggedIn ? "/academy" : "/login"}
              className="mobile:w-full w-64 h-14 flex-center rounded-lg bg-gradient-5
               transition-all duration-150 ease-in-out hover:shadow-xl mt-16
            text-white font-medium text-base"
            >
              Watch series now
            </Link>
          </div>
          <div className="flex-1">
            <img src={group2} alt="" className=" object-contain" />
          </div>
        </div>

        {/* <div className="py-16 w-full max-w-screen-xl mx-auto mobile:px-5">
          <h2 className="font-bold text-2xl mobile:text-center text-[#F9F9F9] ">
            Companies we work with
          </h2>
          <div className="flex items-center mobile:justify-center justify-between flex-wrap mt-10 mobile:gap-6 gap-5">
            <img
              src={dribble}
              alt="company logo"
              className="mobile:h-[2.768rem] h-[3.313rem] w-auto object-contain"
            />
            <img
              src={deviant}
              alt="company logo"
              className="mobile:h-[2.768rem] h-[3.313rem] w-auto object-contain"
            />
            <img
              src={artstation}
              alt="company logo"
              className="mobile:h-[2.768rem] h-[3.313rem] w-auto object-contain"
            />
            <img
              src={sket}
              alt="company logo"
              className="mobile:h-[2.768rem] h-[3.313rem] w-auto object-contain"
            />
            <img
              src={freepik}
              alt="company logo"
              className="h-[3.313rem] w-auto object-contain"
            />
            <img
              src={society}
              alt="company logo"
              className="mobile:h-[2.768rem] h-[3.313rem] w-auto object-contain"
            />
            <img
              src={behance}
              alt="company logo"
              className="mobile:h-[2.768rem] h-[3.313rem] w-auto object-contain"
            />
            <img
              src={ant}
              alt="company logo"
              className="mobile:h-[2.768rem] h-[3.313rem] w-auto object-contain"
            />
            <img
              src={spread}
              alt="company logo"
              className="mobile:h-[2.768rem] h-[3.313rem] w-auto object-contain"
            />
            <img
              src={adobe}
              alt="company logo"
              className="mobile:h-[2.768rem] h-[3.313rem] w-auto object-contain"
            />
          </div>
        </div> */}

        <div className="w-full bg-white">
          {/* Book shape */}
          <div className="relative bg-black">
            <img
              src={bible}
              alt="bible shape"
              className="absolute mobile:-top-0.5 -top-1 w-full object-contain transform rotate-180"
            />
          </div>
          <div className="w-full max-w-screen-xl mx-auto py-36  mobile:py-20 mobile:px-5">
            <img
              src={group1}
              alt="group image"
              className="mobile:h-[7.79rem] h-[18rem] w-auto object-contain "
            />
            <div className="bg-black flex flex-col items-center gap-8 p-10 rounded-3xl">
              <div className="flex mobile:flex-col mobile:items-start items-end gap-5 max-w-[60.625rem]">
                <img
                  src={meta}
                  alt="meta"
                  className="mobile:h-14 h-28 w-auto object-contain"
                />
                <h2 className="font-bold mobile:text-2xl text-5xl text-white">
                  Inspiring Cartoons for Kids: Faith and Fun Combined
                </h2>
              </div>
              <div className="max-w-[60.625rem]">
                <h4 className="mobile:text-sm mobile:text-left text-2xl text-white text-center">
                  We are dedicated to creating relatable, modern, high-quality
                  cartoons rooted in Godly principles that captivates viewers,
                  shares the Gospel, and instills Christian values in younger
                  generations.
                </h4>
              </div>
              <Link
                to={SIGNUP}
                className="w-72 h-14 flex justify-center items-center bg-gradient-5  rounded-lg
          font-medium mobile:text-sm text-lg text-white transition-all duration-150 ease-in-out hover:shadow-xl"
              >
                Become a member today!!{" "}
              </Link>
            </div>
          </div>
        </div>

        <div className="w-full ">
          {/* Book shape */}
          <div className="relative">
            <img
              src={mountine}
              alt="bible shape"
              className="absolute mobile:-top-0.5 -top-1 w-full object-contain "
            />
          </div>

          <div className="w-full max-w-screen-xl mx-auto mobile:px-5 pt-64 flex mobile:flex-col justify-between items-center gap-16">
            <div className="flex-1  mobile:rounded-xl rounded-3xl  overflow-hidden">
              <img src={jess1} alt="jesus image" className="object-contain " />
            </div>
            <div className="flex-1 flex flex-col justify-between text-white">
              <h1 className="font-bold mobile:text-center mobile:text-3xl text-4xl">
                WHY WE NEED YOUR HELP
              </h1>
              <p className="mt-8 mobile:text-sm text-base">
                Being a newcomer in the world of christian animation, we aim to
                bring versatility into the way Christian stories are portrayed.
                The bible says in Proverbs 22:6, "Train up a child in a way he
                should go, and when he is old he will not depart from it.”
                (NKJV) <br />
                We live in uncertain times, and our children need messages of
                hope, love and relevance to guide them on their journey to
                becoming great future leaders. <br /> With your donations and
                partnerships, Dreamland Animation will be able to complete our
                first of many animated series, Joseph-Dreamer to Governor. Our
                plan is to offer the cartoon series to churches as an
                educational resource for Sunday School use, which will include
                lesson plans and printable activities for kids to learn about
                God's word. <br /> We are also doing our research for the best
                platforms to host the cartoon series with the best quality so
                that it's accessible to everyone while keeping true to the
                integrity of our core values.
              </p>

              <button
                className="w-52 mobile:w-full h-12 flex justify-center items-center bg-gradient-5 rounded-lg 
             font-medium text-lg text-white mt-6 transition-all duration-150 ease-in-out hover:shadow-xl"
              >
                Donate
              </button>
            </div>
          </div>
        </div>

        <div className="mt-36 w-full max-w-screen-xl mx-auto">
          <div className="w-full max-w-[54rem] mx-auto mobile:px-5">
            <h1 className="font-bold mobile:text-3xl mobile:text-center text-5xl text-white ">
              Leave a comment
            </h1>
          </div>

          <Form />
        </div>
      </section>
    </main>
  );
}
