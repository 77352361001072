import React, { useState, useRef } from "react";
import logo from "@/assets/images/logo-anim.png";
import bible from "@/assets/images/bibleVector.png";

interface VideoBackgroundProps {
  videoSrc: string;
}

const VideoBackground: React.FC<VideoBackgroundProps> = ({ videoSrc }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="relative w-full mobile:h-80 h-[43.6rem] overflow-hidden">
      <video
        ref={videoRef}
        className="absolute top-0 left-0 w-full h-full object-cover"
        src={videoSrc}
        loop
        muted
        autoPlay
      />
      <div
        className="absolute inset-0 flex items-center justify-center bg-[#111111] bg-opacity-50 cursor-pointer"
        onClick={handlePlayPause}
      >
        <img
          src={logo}
          alt="dream land logo"
          className="mobile:h-16 h-52 w-auto"
        />
        {/* <div className="absolute mobile:w-11 mobile:h-11 w-24 h-24 flex justify-center items-center text-[#222222] mobile:text-base text-4xl bg-white bg-opacity-80 rounded-full">
          {isPlaying ? "❚❚" : "►"}
        </div> */}
      </div>
      <img
            src={bible}
            alt="bible shape"
            className="absolute -bottom-1 w-full object-contain"
          />
    </div>
  );
};

export default VideoBackground;
