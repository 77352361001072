// import React, { useState } from 'react';
import React, { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

interface NoteModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSave: (title: string) => void;
  loading: boolean;
}

export const NoteModal: React.FC<NoteModalProps> = ({
  open,
  setOpen,
  onSave,
  loading,
}) => {
  const [title, setTitle] = useState("");

  const handleSave = () => {
    if (title.trim()) {
      onSave(title);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="font-poppins relative z-50"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-[#111111D9] bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg flex flex-col gap-5 justify-center items-center bg-white h-96 px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="w-full px-5">
              <div className="mb-5">
                <DialogTitle
                  as="h3"
                  className="text-2xl font-medium leading-6 text-gray-900"
                >
                  Create new note
                </DialogTitle>
              </div>
              <div className="">
                <label className="text-base text-[#666666]">Title</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Note Title"
                  className="border border-gray-400 px-4 py-4 w-full rounded-xl mt-1 focus:outline-none"
                />
              </div>
            </div>
            <div className="w-full px-5">
              <button
                disabled={loading}
                type="button"
                onClick={handleSave}
                className="w-full h-12 flex items-center justify-center rounded-xl bg-[#111111] text-base font-medium text-white shadow-sm hover:shadow-xl"
              >
                {loading ? "Creating..." : "Create"}
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
