import m1 from "@/assets/images/wImg1.png";
import m2 from "@/assets/images/wImg2.png";
import m3 from "@/assets/images/wImg3.png";
import vision from "@/assets/images/visionImg.png";
import mission from "@/assets/images/missionImg.png";
import { useEffect } from "react";
import { scrollToTop } from "@/utils/scroll-to-top";

export default function Who() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="bg-black px-6">
      <main className="font-poppins w-full max-w-[89.25rem] mx-auto min-h-screen text-white py-36 ">
        <div className="w-full max-w-5xl mx-auto ">
          <section className="w-full flex flex-col items-center px-5">
            <h1 className="text-4xl mb-2">Who We Are</h1>
            <div className="w-28 h-1 bg-gradient-5 rounded-full " />

            <p className="text-2xl text-center mt-10">
              Dreamland Animation LLC is a startup animation production company
              focused on creating innovative and engaging cartoons inspired by
              stories from the Bible. Established in 2022, Dreamland Animation
              was started by Samantha Quaatey, who, driven by God's guidance,
              envisioned a platform that would bring the gospel to life,
              catering to young minds and spreading the message of Christianity
              in a fun way. Her team is made up of a passionate individuals who
              wanted to combine their Christian faith with their love for
              animation and storytelling. Today, Dreamland Animation is a small
              but mighty team of incredibly gifted individuals, including
              writers, animators, background artists, illustrators, compositors,
              sound engineers, musicians and voice actors.
            </p>
          </section>

          <section className="w-full flex items-center justify-between py-36">
            <img src={m1} alt="image" className="w-80 h-auto object-contain" />
            <img src={m2} alt="image" className="w-80 h-auto object-contain" />
            <img src={m3} alt="image" className="w-80 h-auto object-contain" />
          </section>
          <section className="w-full flex items-center justify-between pb-36 gap-10">
            <div className="flex-1">
              <h2 className="text-4xl mb-5">Our Vision</h2>
              <p className="text-2xl">
                We aim to transform the lives of all children, ministering to
                their young hearts and minds through Christian-based cartoons
                that are inspiring and educational within and outside of the
                church, to help them develop a strong foundation of faith and
                guide them towards a virtuous life.
              </p>
            </div>
            <div className="flex-1">
              <img src={vision} alt="image" className=" object-contain" />
            </div>
          </section>
          <section className="w-full flex items-center justify-between pb-36 gap-10">
            <div className="flex-1">
              <img src={mission} alt="image" className=" object-contain" />
            </div>
            <div className="flex-1">
              <h2 className="text-4xl mb-5">Our Mission</h2>
              <p className="text-2xl">
                We are dedicated to creating relatable, modern, high-quality
                cartoons rooted in Godly principles that captivates viewers,
                shares the Gospel, and instills Christian values in younger
                generations
              </p>
            </div>
          </section>
        </div>

        <section className="w-full flex items-center justify-center py-20 px-24 bg-white rounded-3xl">
          <p className="text-[#111111] text-2xl text-center ">
            We live in uncertain times, and our children need messages of hope,
            love and relevance to guide them on their journey to becoming great
            future leaders. Dreamland animation is committed to bringing
            versatility into the way biblical stories are portrayed. In addition
            to our cartoon series, we are building a learning hub, Dreamland
            Academy as an educational resource. Parents, Churches and Christian
            schools will be able to take kids deeper into the Word through
            lesson plans and activities that support the series.
          </p>
        </section>
      </main>
    </div>
  );
}
