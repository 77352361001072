import { useEffect } from "react";
import Form from "../home/components/form";
import { scrollToTop } from "@/utils/scroll-to-top";

export default function Contact() {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="bg-black">
      <main className="max-w-4xl mx-auto min-h-screen py-36 text-white flex flex-col items-center">
        <h1 className="text-4xl mb-2 text-center">
          Let's Get in <br />
          Touch
        </h1>
        <div className="w-28 h-1 bg-gradient-5 rounded-full mb-5" />

        <Form />
      </main>
    </div>
  );
}
