import { gql } from "@apollo/client";

export const GET_NOTES = gql`
  query GetNotes(
    $filter: NoteFilter
    $search: SearchOperator
    $populate: [String]
    $pagination: Pagination
    $sort: NoteSort
  ) {
    getNotes(
      filter: $filter
      search: $search
      populate: $populate
      pagination: $pagination
      sort: $sort
    ) {
      _id
      title
      content
      createdAt
      updatedAt
      createdBy {
        _id
        firstName
        lastName
      }
      user {
        _id
        firstName
        lastName
      }
    }
  }
`;

export const GET_NOTE = gql`
  query GetNote($id: ID!, $populate: [String]) {
    getNote(_id: $id, populate: $populate) {
      _id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
