/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DeleteUserAddedByAdminMutation,
  DeleteUserAddedByAdminMutationVariables,
  GetUsersAddedByAdminQuery,
  GetUsersAddedByAdminQueryVariables,
  InviteUserMutation,
  InviteUserMutationVariables,
} from "@/apollo/graphql/generated/types";

import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link } from "react-location";
import { IoIosArrowRoundForward } from "react-icons/io";
import { MdCheck } from "react-icons/md";
import Avatar from "@/components/core/avatar";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useCurrentUser } from "@/apollo/cache/auth";
// import moment from "moment";
import { DELETE_USER, INVITE_USERS } from "@/apollo/graphql/mutations/user";
import ButtonLoader from "@/components/loaders/button";
import toast from "react-hot-toast";
import { GET_USERS_BY_ADMIN } from "@/apollo/graphql/queries/user";

export default function Subscription() {
  const user = useCurrentUser();
  const [showAddMembers, setShowAddMembers] = useState(false);
  const [invitedMembers, setInvitedMembers] = useState<any>([]);

  const [inviteMembers, { loading }] = useMutation<
    InviteUserMutation,
    InviteUserMutationVariables
  >(INVITE_USERS);

  const {
    data,
    loading: loadingUsers,
    refetch,
  } = useQuery<GetUsersAddedByAdminQuery, GetUsersAddedByAdminQueryVariables>(
    GET_USERS_BY_ADMIN,
    {
      variables: {
        adminId: user?._id,
      },
    }
  );

  useEffect(() => {
    if (data?.getUsersAddedByAdmin) {
      refetch();
      setInvitedMembers(data.getUsersAddedByAdmin);
      // console.log(data.getUsersAddedByAdmin);
    }
  }, [data, refetch]);

  const [deleteMember, { loading: deletingMember }] = useMutation<
    DeleteUserAddedByAdminMutation,
    DeleteUserAddedByAdminMutationVariables
  >(DELETE_USER);

  const [members, setMembers] = useState([
    { firstName: "", lastName: "", email: "" },
  ]);
  const [errors, setErrors] = useState<string[]>([]);

  const handleInputChange = (index: number, field: string, value: string) => {
    const values = [...members];
    values[index] = { ...values[index], [field]: value };
    setMembers(values);

    const newErrors = [...errors];
    newErrors[index] = "";
    setErrors(newErrors); // Clear error for this input
  };

  const handleAddInput = () => {
    const lastMember = members[members.length - 1];
    if (
      lastMember.firstName.trim() &&
      lastMember.lastName.trim() &&
      lastMember.email.trim()
    ) {
      setMembers([...members, { firstName: "", lastName: "", email: "" }]);
      setErrors([...errors, ""]); // Add a new error field
    } else {
      const newErrors = [...errors];
      newErrors[members.length - 1] =
        "Please fill all fields before adding a new member.";
      setErrors(newErrors);
    }
  };

  const handleRemoveInput = (index: number) => {
    const values = [...members];
    values.splice(index, 1);
    setMembers(values);

    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors); // Adjust errors array
  };

  const handleSubmit = async () => {
    await inviteMembers({
      variables: {
        input: members,
      },
    })
      .then(({ data }) => {
        if (data?.inviteUser) {
          refetch();
          toast(
            JSON.stringify({
              type: "success",
              title: `Invitation sent successfully`,
            })
          );
          setShowAddMembers(false);
        }
      })
      .catch((error) => {
        console.error("Error inviting users:", error);
        toast(
          JSON.stringify({
            type: "error",
            title: error?.message || "Error while inviting users",
          })
        );
      });
  };

  const handleDeleteMember = async (userId: string) => {
    await deleteMember({
      variables: {
        userId: userId,
      },
    })
      .then(({ data }) => {
        if (data?.deleteUserAddedByAdmin) {
          refetch();
          toast(
            JSON.stringify({
              type: "success",
              title: `User deleted successfully`,
            })
          );
          // refetch();
        }
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
        toast(
          JSON.stringify({
            type: "error",
            title: error?.message || "Error while deleting user",
          })
        );
      });
  };

  const _package = user?.subscription?.packageId;

  return (
    <section className="font-poppins  h-full">
      <div className="flex h-full">
        <div className="w-[40%] border-r border-[#D4D4D4] h-full pt-5 pr-5">
          <h4 className="text-lg text-[#111] mb-2">
            {_package?.name ?? "package name"}
          </h4>

          <h1 className="font-semibold text-4xl text-[#111]">
            ${_package?.price ?? "0"}/
            <span className="font-normal text-lg">
              {_package?.billingInterval === "year" ? "yr." : "mo."}{" "}
            </span>
          </h1>

          <p className="text-sm text-[#EE1D52] mt-1 mb-5">
            Expires in <span>16</span> days <br />
            {/* {user?.subscription?.currentPeriodEnd} */}
          </p>

          <Link
            className="w-full py-2 bg-[#1567EE] rounded-lg px-5 flex justify-between items-center text-white hover:shadow-lg transition-all duration-200 ease-in-out"
            to={"/membership"}
          >
            <span className="font-semibold text-sm ">Upgrade</span>
            <IoIosArrowRoundForward className="w-6 h-6" aria-hidden="true" />
          </Link>

          <div
            className="w-full h-[1px] bg-[#D4D4D4] my-4"
            aria-hidden="true"
          />

          <ul>
            {_package?.features.map((feature: string, index: number) => (
              <li key={index} className="flex items-center gap-3 mb-4">
                <span className="w-5 h-5 flex justify-center items-center bg-[#989595] rounded-full">
                  <MdCheck className="text-white w-3 h-3" aria-hidden="true" />
                </span>
                <span className="text-[#111] text-sm">{feature}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex-1 h-full pt-5 pl-5">
          {showAddMembers ? (
            <>
              <div className="h-full w-full flex flex-col justify-between">
                <div className="">
                  <div className="w-full flex justify-between items-center mb-3">
                    <h4 className="text-lg text-[#111]">Add Members</h4>
                    <p className="text-[#111] ">
                      {members.length}/
                      {user?.subscription?.packageId?.numberOfSeats}
                    </p>
                  </div>
                  <form className="w-full mt-3">
                    {members.map((member, index) => (
                      <div className="mt-3" key={index}>
                        <div className="flex items-start gap-5">
                          <div className="flex-1">
                            <label
                              htmlFor={`firstName-${index}`}
                              className="text-gray-400"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              value={member.firstName}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "firstName",
                                  e.target.value
                                )
                              }
                              className="w-full h-10 px-4 mt-1 rounded-xl border border-[#66666659] focus:outline-none focus:border-[#FFD700] focus:ring-1 focus:ring-[#FFD700] focus:ring-opacity-50 transition-all duration-150 ease-in-out focus:shadow-sm placeholder:text-[#272727]"
                            />
                          </div>

                          <div className="flex-1 ">
                            <label
                              htmlFor={`lastName-${index}`}
                              className="text-gray-400 block"
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              value={member.lastName}
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  "lastName",
                                  e.target.value
                                )
                              }
                              className="w-full h-10 px-4 mt-1 rounded-xl border border-[#66666659] focus:outline-none focus:border-[#FFD700] focus:ring-1 focus:ring-[#FFD700] focus:ring-opacity-50 transition-all duration-150 ease-in-out focus:shadow-sm placeholder:text-[#272727]"
                            />
                          </div>
                        </div>

                        <label
                          htmlFor={`email-${index}`}
                          className="text-gray-400 mt-2 block"
                        >
                          Email Address
                        </label>
                        <div className="flex items-center gap-5 mt-1">
                          <input
                            type="email"
                            value={member.email}
                            onChange={(e) =>
                              handleInputChange(index, "email", e.target.value)
                            }
                            className="w-full h-10 px-4 rounded-xl border border-[#66666659] focus:outline-none focus:border-[#FFD700] focus:ring-1 focus:ring-[#FFD700] focus:ring-opacity-50 transition-all duration-150 ease-in-out focus:shadow-sm placeholder:text-[#272727]"
                          />
                          <button
                            type="button"
                            onClick={() => handleRemoveInput(index)}
                            className="text-red-500 hover:text-red-700"
                          >
                            <RiDeleteBin6Line className="w-5 h-5" />
                          </button>
                        </div>

                        {errors[index] && (
                          <p className="font-medium text-red-600 text-xs mt-1">
                            {errors[index]}
                          </p>
                        )}
                      </div>
                    ))}

                    <div className="w-full flex justify-end mt-4">
                      <div className="flex items-end">
                        <button
                          className="w-[7.688rem] h-[2.5rem] flex justify-center items-center text-sm text-black rounded-lg border border-black"
                          type="button"
                          onClick={handleAddInput}
                        >
                          Add another
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="flex justify-between items-center pb-5">
                  <button
                    onClick={() => setShowAddMembers(false)}
                    type="submit"
                    className=" py-2 px-5 bg-black rounded-lg text-white hover:shadow-md transition-all duration-200 ease-in-out"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={loading}
                    onClick={handleSubmit}
                    type="submit"
                    className="w-32 flex justify-center items-center py-2 px-4 bg-[#1567EE] rounded-lg text-white hover:shadow-md transition-all duration-200 ease-in-out"
                  >
                    {loading ? (
                      <ButtonLoader title="Sending..." />
                    ) : (
                      "Send invites"
                    )}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="w-full flex justify-between items-center mb-4">
                <h4 className="text-lg text-[#111]">Members</h4>
                <p className="text-[#111] ">
                  {invitedMembers.length}/
                  {user?.subscription?.packageId?.numberOfSeats}
                </p>
              </div>

              <div className="w-full flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <Avatar
                    src={""}
                    alt={`${user?.firstName} ${user?.lastName}`}
                    size="sm"
                  />
                  <div className="">
                    <h4 className="text-xs text-[#111]">
                      {user?.firstName ?? "firstName"}{" "}
                      {user?.lastName ?? "lastName"}
                    </h4>
                    <p className="text-[#111] text-xs">
                      {user?.email ?? "example@gmail.co"}
                    </p>
                  </div>
                </div>
                <span className="text-xs text-[#1567EE] select-none">
                  Admin
                </span>
              </div>

              {/* probable members, map them */}

              {loadingUsers ? (
                <p className="text-sm mt-5">Loading users...</p>
              ) : (
                invitedMembers.map((member: any, index: number) => (
                  <div
                    key={index}
                    className="w-full flex justify-between items-center mt-5"
                  >
                    <div className="flex items-center gap-2">
                      <Avatar
                        src={""}
                        alt={`${member.firstName} ${member.lastName}`}
                        size="sm"
                      />
                      <div className="">
                        <h4 className="text-xs text-[#111]">
                          {member.firstName} {member.lastName}
                        </h4>
                        <p className="text-[#111] text-xs">{member.email}</p>
                      </div>
                    </div>
                    <button
                      disabled={deletingMember}
                      onClick={() => handleDeleteMember(member._id)}
                      className="cursor-pointer "
                    >
                      <RiDeleteBin6Line
                        className="w-5 h-5 text-[#EE1D52]"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                ))
              )}

              {/* <div className="w-full flex justify-between items-center mt-5">
                <div className="flex items-center gap-2">
                  <Avatar src={""} alt={`A N`} size="sm" />
                  <div className="">
                    <h4 className="text-xs text-[#111]">Nimako Boateng</h4>
                    <p className="text-[#111] text-xs">example@gmail.com</p>
                  </div>
                </div>
                <span className="cursor-pointer ">
                  <RiDeleteBin6Line
                    className="w-5 h-5 text-[#EE1D52]"
                    aria-hidden="true"
                  />
                </span>
              </div> */}

              <button
                onClick={() => setShowAddMembers(true)}
                className="absolute bottom-5 right-5 border border-black py-2 px-5 bg-white rounded-lg text-black text-sm hover:shadow-md
transition-all duration-200 ease-in-out"
              >
                Add member
              </button>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
