import { gql } from "@apollo/client";

export const GET_PACKAGES = gql`
  query GetPackages(
    $filter: PackageFilter
    $search: SearchOperator
    $pagination: Pagination
    $sort: PackageSort
  ) {
    getPackages(
      filter: $filter
      search: $search
      pagination: $pagination
      sort: $sort
    ) {
      count
      rows {
        _id
        name
        description
        stripeProductId
        stripePriceId
        numberOfSeats
        price
        billingInterval
        features
        permissions
        currency
        createdAt
        updatedAt
      }
    }
  }
`;
//
export const GET_PACKAGE = gql`
  query GetPackage($filter: PackageFilter) {
    getPackage(filter: $filter) {
      _id
      name
      description
      stripeProductId
      stripePriceId
      numberOfSeats
      price
      billingInterval
      features
      permissions
      currency
      createdAt
      updatedAt
    }
  }
`;
