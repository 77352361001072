/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useState } from 'react'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

import { useLocation, useNavigate } from "react-location";
import { useStateWithHistory } from "react-use";
import logo from "@/assets/images/logo.png";
import walkM1 from "@/assets/images/walkM1.png";
import walkM2 from "@/assets/images/walkM2.png";
import walkM3 from "@/assets/images/walkM3.png";

const Content = () => {
  const [state, setState, stateHistory] = useStateWithHistory(1);
  const navigate = useNavigate();

  return (
    <div className="font-poppins w-full flex flex-col space-y-4 items-center">
      <div className="w-full flex flex-col space-y-4 items-center">
        {state === 1 ? (
          <DialogTitle
            as="h3"
            className="font-semibold text-2xl/9 text-[#181818]"
          >
            Our Current Series
          </DialogTitle>
        ) : state === 2 ? (
          <DialogTitle
            as="h3"
            className="font-semibold text-2xl/9 text-[#181818]"
          >
            Select your preferred{" "}
            <span className="text-[#EE1D52]">Category</span>
          </DialogTitle>
        ) : (
          <DialogTitle
            as="h3"
            className="font-semibold text-2xl/9 text-[#181818]"
          >
            Enjoy the <span className="text-[#EE1D52]">Lessons</span>
          </DialogTitle>
        )}

        <div className="w-full h-52 bg-gradient-5 rounded-lg flex justify-center items-end pt-10 px-10 overflow-hidden">
          <div className="h-44 w-96 ">
            <img
              src={state === 1 ? walkM1 : state === 2 ? walkM2 : walkM3}
              alt="image"
              className="w-full h-full object-contain"
            />
          </div>
        </div>

        <p className="text-lg text-[#555555]">
          {state === 1 ? "Access our exciting new series filled with fun lessons and activities just for you!"
           : state === 2 ? "You have the option to select either Pre-School or Grade School, tailored for your level." 
           : "Dive into a fun mix of lessons, specially made just for you in each series!"}
        </p>

        <div className="flex items-center space-x-2">
          <div
            onClick={() => stateHistory.go(0)}
            className={`w-3 h-3 rounded-full cursor-pointer ${
              state === 1 ? "bg-[#EE1D52]" : "bg-[#989595]"
            } `}
            aria-hidden="true"
          />
          <div
            onClick={() => stateHistory.go(1)}
            className={`w-3 h-3 rounded-full cursor-pointer ${
              state === 2 ? "bg-[#EE1D52]" : "bg-[#989595]"
            } `}
            aria-hidden="true"
          />
          <div
            onClick={() => stateHistory.go(2)}
            className={`w-3 h-3 rounded-full cursor-pointer ${
              state === 3 ? "bg-[#EE1D52]" : "bg-[#989595]"
            } `}
            aria-hidden="true"
          />
        </div>
      </div>

      <button
        onClick={
          state === 3
            ? () => navigate({ to: "/main-introduction", replace: true })
            : () => setState(state + 1)
        }
        className="w-64 h-12 bg-[#EE1D52] flex justify-center items-center rounded-xl text-white
        font-medium text-base"
      >
        {state === 3 ? "Done" : "Next"}
      </button>
    </div>
  );
};

export default function WalkThroughModal() {
  const location = useLocation();
  const modalState = location.current.search.modal;
  const modalStateN = location.current.search.state;

  return (
    <>
      {modalStateN === 1 && modalState && (
        <Dialog open={true} onClose={() => {}} className="relative z-50">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-[#111111D9] bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-start sm:pt-20">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl
                 transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 
                data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
              >
                <div>
                  <div className="mx-auto flex items-center justify-center rounded-full">
                    <img src={logo} alt="logo" className="h-16 w-auto" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Content />
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}
