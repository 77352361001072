import { useState, useEffect } from "react";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import "./payment.css";
import CheckoutForm from "./CheckoutForm";
import { useSearch } from "react-location";
import { LocationGenerics } from "@/router/location";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

export default function PaymentPage() {
  const [clientSecret, setClientSecret] = useState("");

  const searchParams = useSearch<LocationGenerics>();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    setClientSecret(searchParams?.sort || "");
  }, [searchParams]);

  const appearance: StripeElementsOptions["appearance"] = {
    theme: "night",
  };

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  return (
    <div className="py-20 bg-black flex justify-center">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}
