import { CONTACT, HOME, INTRODUCTION, WHO } from "@/constants/path";
import { Link } from "react-location";
import {
  RiFacebookFill,
  RiTwitterXLine,
  RiInstagramFill,
  RiLinkedinFill,
  RiYoutubeFill,
} from "react-icons/ri";
import logo from "@/assets/images/logo-anim.png";
import { useState } from "react";
import SupportModal from "./support-modal";

export default function Footer() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <footer className="w-full border-t border-[#EFF0F6] py-10 bg-black mobile:px-5">
      <div className="w-full max-w-screen-xl mx-auto flex mobile:flex-col justify-between items-start border-b border-[#EFF0F6] pb-16">
        <div className="mobile:w-full flex mobile:flex-col mobile:items-center items-start gap-20 ">
          {/* <div className="">
            <h2 className="font-bold text-xl mobile:text-center text-white">
              Product
            </h2>

            <ul className="mt-5 flex flex-col gap-2 text-lg mobile:text-center text-white">
              <li className="">
                <a href="">Membership</a>
              </li>
              <li className="">
                <a href="">Pricing</a>
              </li>
              <li className="">
                <a href="">Animated Series</a>
              </li>
              <li className="">
                <a href="">Reviews</a>
              </li>
              <li className="">
                <a href="">Updates</a>
              </li>
            </ul>
          </div> */}
          <div className="">
            <h2 className="font-bold text-xl mobile:text-center text-white">
              Company
            </h2>

            <ul className="mt-5 flex flex-col gap-2 text-lg mobile:text-center text-white">
              <li className="">
                <Link to={HOME}>Home</Link>
              </li>
              <li className="">
                <Link to={WHO}>Who we are</Link>
              </li>
              <li className="">
                <a href="#">Gallery</a>
              </li>
              <li className="">
                <Link to={CONTACT}>Contact us</Link>
              </li>
            </ul>
          </div>
          <div className="">
            <h2 className="font-bold text-xl mobile:text-center text-white">
              Support
            </h2>

            <ul className="mt-5 flex flex-col gap-2 text-lg mobile:text-center text-white">
              <li className="">
                <Link to={INTRODUCTION}>Getting started</Link>
              </li>
              <li className="">
                <Link to={CONTACT}>Help center</Link>
              </li>
              <li className="">
                <button onClick={() => setOpenModal(true)}>Chat support</button>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full max-w-lg">
          <img
            src={logo}
            alt="logo"
            className="h-20 w-auto object-contain mobile:mx-auto mobile:mt-10"
          />
          <p className="text-lg text-white mt-4">
            Do not miss any of our updates
          </p>
          <div className="w-full flex mobile:flex-col items-center justify-between gap-3 mt-5">
            <input
              type="email"
              placeholder="Enter your email"
              className="md:flex-1 mobile:w-full h-[3rem] bg-[#EFF0F6] text-black text-base px-3 border border-[#D9DBE9] shadow rounded-lg
               focus:outline-none focus:ring-2 focus:ring-[#4A3AFF] transition-all duration-150 ease-in-out
               placeholder:text-[#111111]"
            />
            <button className="mobile:w-full w-fit h-[3rem] bg-gradient-5 text-white px-5 py-2 rounded-lg">
              Subscribe
            </button>
          </div>
        </div>
      </div>
      <div className="w-full max-w-screen-xl mx-auto flex mobile:flex-col-reverse justify-between items-start pt-10 mobile:pb-14">
        <p className="text-white mobile:text-sm mobile:text-center text-xl mobile:mt-6">
          {" "}
          Copyright &copy; {new Date().getFullYear()}{" "}
          <Link to={HOME}>Dreamland Animation </Link> | All Rights Reserved
        </p>
        <div className="mobile:w-full flex mobile:justify-center items-center gap-4">
          <a href="#" className="cursor-pointer text-white">
            <RiFacebookFill
              className="mobile:w-4 mobile:h-4 w-6 h-6"
              aria-hidden="true"
            />
          </a>
          <a href="#" className="cursor-pointer text-white">
            <RiTwitterXLine
              className="mobile:w-4 mobile:h-4 w-6 h-6"
              aria-hidden="true"
            />
          </a>
          <a href="#" className="cursor-pointer text-white">
            <RiInstagramFill
              className="mobile:w-4 mobile:h-4 w-6 h-6"
              aria-hidden="true"
            />
          </a>
          <a href="#" className="cursor-pointer text-white">
            <RiLinkedinFill
              className="mobile:w-4 mobile:h-4 w-6 h-6"
              aria-hidden="true"
            />
          </a>
          <a href="#" className="cursor-pointer text-white">
            <RiYoutubeFill
              className="mobile:w-4 mobile:h-4 w-6 h-6"
              aria-hidden="true"
            />
          </a>
        </div>
      </div>
      <SupportModal open={openModal} setOpen={setOpenModal} />
    </footer>
  );
}
