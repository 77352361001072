import React, { useRef, useState, useEffect } from "react";
import vid from "../../../../assets/videos/intro.mp4";
import { GrBackTen, GrForwardTen } from "react-icons/gr";
import { IoPlayCircle, IoPauseCircleSharp } from "react-icons/io5";
<IoPlayCircle />;

const VideoPlayer: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showControls, setShowControls] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    const video = videoRef.current;
    if (video) {
      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);
    }

    return () => {
      if (video) {
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
      }
    };
  }, []);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  const handleForward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime += 10;
    }
  };

  const handleBackward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime -= 10;
    }
  };

  return (
    <div
      className="relative w-full h-full rounded-3xl overflow-hidden"
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
    >
      <video
        ref={videoRef}
        className="w-full sm:h-[30rem] 2xl:h-[38rem] object-cover"
        src={vid}
        controls
      />

      {showControls && (
        <div className="absolute top-[50%] left-[50%]  transform -translate-x-1/2 -translate-y-1/2 flex gap-8">
          <button className="text-white mx-2" onClick={handleBackward}>
            <GrBackTen className="w-12 h-12" />
          </button>
          <button className="text-white mx-2" onClick={handlePlayPause}>
            {isPlaying ? (
              <IoPauseCircleSharp className="w-16 h-16" />
            ) : (
              <IoPlayCircle className="w-16 h-16" />
            )}
          </button>
          <button className="text-white mx-2" onClick={handleForward}>
            <GrForwardTen className="w-12 h-12" />
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
